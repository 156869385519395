import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import moment from "moment";
import { CordraObject } from "@cnri/cordra-client";
import { handleError } from "../util";
import TitleContainer from "../Title/Container";
import Form from "../common/components/Form";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import { Activity, Participant } from "../../models/Activity";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

interface Props extends DataFetchingState<CordraObject> {
    injected: boolean;
}

interface ViewModel {
    id: string;
    name: string;
    purpose: string;
    purposeFormatted: string;
    location: string;
    heldDate: string;
    organizers: string[];
    onlyParticipants: string[];
    observers: string[];
    context: string;
    contributions: string[];
    outcomes: string[];
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {

    schema = {
        id: Joi.string()
            .allow("")
            .allow(null),
        name: Joi.string()
            .required()
            .min(1)
            .label("Name"),
        purpose: Joi.string()
            .required()
            .min(1)
            .label("rawText"),
        purposeFormatted: Joi.string()
            .required()
            .min(1)
            .label("Purpose"),
        heldDate: Joi.date()
            .required()
            .allow(null)
            .label("Held Date"),
        location: Joi.string()
            .allow("")
            .allow(null)
            .label("Location"),
        organizers: Joi.array()
            .items(Joi.string()
                .required())
            .min(1)
            .unique()
            .required()
            .label("Organizers"),
        onlyParticipants: Joi.array()
            .items(Joi.string()
                .required())
            .min(1)
            .unique()
            .required()
            .label("Participants"),
        observers: Joi.array()
            .items(Joi.string()
                .allow("")
                .allow(null))
            .label("Observers"),
        context: Joi.string()
            .required()
            .min(1)
            .label("Context"),
        contributions: Joi.array()
            .items(Joi.string()
                .allow("")
                .allow(null))
            .label("Contributions"),
        outcomes: Joi.array()
            .items(Joi.string()
                .allow("")
                .allow(null))
            .label("Outcomes")
    };

    state: State = {
        data: {
            id: "",
            name: "",
            purpose: "",
            purposeFormatted: "",
            location: "",
            heldDate: "",
            organizers: [],
            onlyParticipants: [],
            observers: [],
            context: "",
            contributions: [],
            outcomes: []
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };


    mapToViewModel = (data: CordraObject): void => {
        const { content } = data;
        const organizers = content.participants && content.participants.filter((member: Participant) => member.title === "Organizer")
            .map((item: Participant) => {
                return item.member;
            });
        const onlyParticipants = content.participants && content.participants.filter((member: Participant) => member.title === "Participant")
            .map((item: Participant) => {
                return item.member;
            });
        const observers = content.participants && content.participants.filter((member: Participant) => member.title === "Observer")
            .map((item: Participant) => {
                return item.member;
            });

        this.setState({
            data: {
                id: content.id || "",
                name: content.name || "",
                purpose: content.purpose || "",
                purposeFormatted: content.purposeFormatted || "",
                location: content.location,
                heldDate: content.heldDate && new Date(content.heldDate),
                organizers: organizers || [],
                onlyParticipants: onlyParticipants || [],
                observers: observers || [],
                context: content.context,
                contributions: content.contributions,
                outcomes: content.outcomes
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const dataModel: Activity = { ...viewModel, participants: [] };
        const consolidatedParticipants: Participant[] = [];
        viewModel.organizers.map(organizer => {
            return consolidatedParticipants.push({ member: organizer, title: "Organizer" });
        });
        viewModel.onlyParticipants.map(participant => {
            return consolidatedParticipants.push({ member: participant, title: "Participant" });
        });
        viewModel.observers.map(observer => {
            return consolidatedParticipants.push({ member: observer, title: "Observer" });
        });
        dataModel.participants = consolidatedParticipants;
        dataModel.heldDate = moment(new Date(viewModel.heldDate))
            .format("YYYY-MM-DDTHH:mm:ssZ").toString();
        delete dataModel.organizers && delete dataModel.observers && delete dataModel.onlyParticipants;

        const data = {
            id: dataModel.id,
            type: "Activity",
            content: dataModel
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data)
                .then(({ id }) => {
                    this.setState({
                        saved: true,
                        data: {
                            id
                        }
                    });
                })
                .catch(response => {
                    this.setState({
                        saveOngoing: false
                    }, () => {
                        handleError(response);
                        this.mapToViewModel(data);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, data, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;
        const context = data.context;

        return saved ?
            (<Redirect to={`/activities/${data.id}`} />) :
            this.state.mapped || !waitUntilMapped ?
                (<div>
                    <h1>
                        {
                            shouldFetch
                                ? "Edit"
                                : "New"
                        }
                        {" "} Activity for {" "}
                        <TitleContainer fetchProps={{
                            id: context
                        }} />
                    </h1>

                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput("name", "Name")}
                        {this.renderRichTextEditor("purpose", "Purpose")}
                        {this.renderDatePicker("heldDate", "Held Date")}
                        {this.renderSelectWithCustomInput("location", "Location", ["Virtual"])}
                        <div className="m-4" />
                        <div className="border-top">
                            <h5 className="title mb-2 mt-2 ">Participants</h5>
                            <div className="bg-light p-3">
                                {this.renderAutoSuggest("organizers", "Organizers", ["Member"], true)}
                                {this.renderAutoSuggest("onlyParticipants", "Participants", ["Member"], true)}
                                {this.renderAutoSuggest("observers", "Observers", ["Member"], true)}
                            </div>
                        </div>
                        <div className="m-4" />
                        <div className="border-top">
                            <h5 className="title mb-2 mt-2 ">Related Posts</h5>
                            <div className="bg-light p-3">
                                {this.renderAutoSuggest("contributions", "Contributions", ["Post"], true)}
                                {this.renderAutoSuggest("outcomes", "Outcomes", ["Post"], true)}
                            </div>
                        </div>
                        {saveOngoing
                            ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                            : this.renderButton("Save", "fa fa-save")}
                        <span className="m-2" />
                        <ButtonWithBackContext label="Discard Changes" enable={true} />
                        <div className="m-2" />
                    </form>
                </div>) :
                <React.Fragment></React.Fragment>;
    };
}

export default NewOrEdit;
