/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { AuthContextInfo } from "./interfaces";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends RouteComponentProps<{}, any, any> {
    authStore: AuthContextInfo;
}

const Logout = (props: Props): JSX.Element => {
    const [loggedOut, setIsLoggedOut] = useState(false);

    useEffect(() => {
        const { logout } = props.authStore;
        logout && logout().then(() => {
            setIsLoggedOut(true);
        });
    });
    return loggedOut ? <Redirect to="/login" /> : <React.Fragment />;

};
export default Logout;
