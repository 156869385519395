import { ComponentType } from "react";
import { compose } from "redux";
import withDataFetching, { DataFetchingParamsX2, DataFetchingProps } from "./WithDataFetching";
import withErrorHandling, { ErrorProps } from "./WithErrorHandling";
import withDefaulting, { DefaultProps } from "./WithDefaulting";
import withLoading, { LoadingProps } from "./WithLoading";
import { WrappedComponentDataFetchingPropsX2 } from "./interfaces";

interface ContainerParamsX2<P, T, R> {
    dataParams: DataFetchingParamsX2<T, R>;
    defaultLoadingMessage?: string | "Loading ";
    Error: ComponentType<ErrorProps>;
    DefaultComponent: ComponentType<P>;
}

export const withContainerX2 = <P extends object, T, R> ({ dataParams,
    defaultLoadingMessage,
    Error,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    DefaultComponent } : ContainerParamsX2<P & WrappedComponentDataFetchingPropsX2<T, R>, T, R>) => (WrappedComponent: ComponentType<P & WrappedComponentDataFetchingPropsX2<T, R>>): ComponentType<P & DataFetchingProps> => {

    type Q = P & WrappedComponentDataFetchingPropsX2<T, R>;

    // let a: ComponentType<Q & DefaultProps> = withDefaulting(DefaultComponent)(WrappedComponent);
    // let b: ComponentType<Q & DefaultProps & ErrorProps> = withErrorHandling<Q & DefaultProps>(Error)(a);
    // let c: ComponentType<Q & DefaultProps & ErrorProps & LoadingProps> = withLoading<Q & DefaultProps & ErrorProps>(defaultLoadingMessage)(b);
    // let d: ComponentType<P & DataFetchingProps> = withDataFetching<P, T>(dataParams)(c);
    const WithContainer : ComponentType<P & DataFetchingProps> =
        compose<ComponentType<Q & DefaultProps>, ComponentType<Q & DefaultProps & ErrorProps>, ComponentType<Q & DefaultProps & ErrorProps & LoadingProps>, ComponentType<Q>, ComponentType<P & DataFetchingProps>>(
            withDataFetching(dataParams),
            withLoading(defaultLoadingMessage),
            withErrorHandling(Error),
            withDefaulting(DefaultComponent))(WrappedComponent);
    return WithContainer;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withContainer = <P extends object, T> (arg : ContainerParamsX2<P & WrappedComponentDataFetchingPropsX2<T, T>, T, T>) => (WrappedComponent: ComponentType<P & WrappedComponentDataFetchingPropsX2<T, T>>): ComponentType<P & DataFetchingProps> => {
    return withContainerX2<P, T, T>(arg)(WrappedComponent);
};

export default withContainer;
