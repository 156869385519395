import React from 'react';

import {
    addColumn,
    addRow,
    AlignToolbarButton,
    BlockToolbarButton,
    CodeBlockToolbarButton,
    deleteColumn,
    deleteRow,
    deleteTable,
    ELEMENT_BLOCKQUOTE,
    ELEMENT_CODE_BLOCK,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_OL,
    ELEMENT_UL,
    getPlatePluginType,
    getPreventDefaultHandler,
    indent,
    insertTable,
    ListToolbarButton,
    MARK_BOLD,
    MARK_CODE,
    MARK_HIGHLIGHT,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_SUBSCRIPT,
    MARK_SUPERSCRIPT,
    MARK_UNDERLINE,
    MarkToolbarButton,
    TableToolbarButton,
    ToolbarButton,
    usePlateEditorRef,
    ELEMENT_PARAGRAPH
} from '@udecode/plate';

export const BasicElementToolbarButtons = (): JSX.Element => {
    const editor = usePlateEditorRef();

    return (
        <React.Fragment>
            <BlockToolbarButton
                type={getPlatePluginType(editor, ELEMENT_H1)}
                icon={<i className="fa fa-font fa-2x" />}
            />
            <BlockToolbarButton
                type={getPlatePluginType(editor, ELEMENT_H2)}
                icon={<i className="fa fa-font fa-lg" />}
            />
            <BlockToolbarButton
                type={getPlatePluginType(editor, ELEMENT_H3)}
                icon={<i className="fa fa-font fa-1x" />}
            />
            <BlockToolbarButton
                type={getPlatePluginType(editor, ELEMENT_H4)}
                icon={<i className="fa fa-font fa-sm" />}
            />
            <BlockToolbarButton
                type={getPlatePluginType(editor, ELEMENT_PARAGRAPH)}
                icon={<i className="fa fa-font fa-xs" />}
            />
            <BlockToolbarButton
                type={getPlatePluginType(editor, ELEMENT_BLOCKQUOTE)}
                icon={<i className="fa fa-quote-left" />}
            />
            <CodeBlockToolbarButton
                type={getPlatePluginType(editor, ELEMENT_CODE_BLOCK)}
                icon={<i className="fa fa-code" />}
            />
        </React.Fragment>
    );
};

export const IndentToolbarButtons = (): JSX.Element => {
    const editor = usePlateEditorRef();

    return (
        <React.Fragment>
            <ToolbarButton
                onMouseDown={editor && getPreventDefaultHandler(indent, editor)}
                icon={<i className="fa fa-indent" />}
            />
        </React.Fragment>
    );
};

export const ListToolbarButtons = (): JSX.Element => {
    const editor = usePlateEditorRef();

    return (
        <React.Fragment>
            <ListToolbarButton
                type={getPlatePluginType(editor, ELEMENT_UL)}
                icon={<i className="fa fa-list-ul" />}
            />
            <ListToolbarButton
                type={getPlatePluginType(editor, ELEMENT_OL)}
                icon={<i className="fa fa-list" />}
            />
        </React.Fragment>
    );
};

export const AlignToolbarButtons = (): JSX.Element => {
    return (
        <React.Fragment>
            <AlignToolbarButton value="left" icon={<i className="fa fa-align-left" />} />
            <AlignToolbarButton value="center" icon={<i className="fa fa-align-center" />} />
            <AlignToolbarButton value="right" icon={<i className="fa fa-align-right" />} />
            <AlignToolbarButton value="justify" icon={<i className="fa fa-align-justify" />} />
        </React.Fragment>
    );
};

export const BasicMarkToolbarButtons = (): JSX.Element => {
    const editor = usePlateEditorRef();

    return (
        <React.Fragment>
            <MarkToolbarButton
                type={getPlatePluginType(editor, MARK_BOLD)}
                icon={<i className="fa fa-bold" />}
            />
            <MarkToolbarButton
                type={getPlatePluginType(editor, MARK_ITALIC)}
                icon={<i className="fa fa-italic" />}
            />
            <MarkToolbarButton
                type={getPlatePluginType(editor, MARK_UNDERLINE)}
                icon={<i className="fa fa-underline" />}
            />
            <MarkToolbarButton
                type={getPlatePluginType(editor, MARK_STRIKETHROUGH)}
                icon={<i className="fa fa-strikethrough" />}
            />
            <MarkToolbarButton
                type={getPlatePluginType(editor, MARK_CODE)}
                icon={<i className="fa fa-code" />}
            />
            <MarkToolbarButton
                type={getPlatePluginType(editor, MARK_SUPERSCRIPT)}
                clear={getPlatePluginType(editor, MARK_SUBSCRIPT)}
                icon={<i className="fa fa-superscript" />}
            />
            <MarkToolbarButton
                type={getPlatePluginType(editor, MARK_SUBSCRIPT)}
                clear={getPlatePluginType(editor, MARK_SUPERSCRIPT)}
                icon={<i className="fa fa-subscript" />}
            />
        </React.Fragment>
    );
};


export const HighlightToolbarButton = (): JSX.Element => {
    const editor = usePlateEditorRef();

    return (
        <MarkToolbarButton
            type={getPlatePluginType(editor, MARK_HIGHLIGHT)}
            icon={<i className="fa fa-pencil-square" />}
        />
    );
};

export const TableToolbarButtons = (): JSX.Element => (
    <React.Fragment>
        <TableToolbarButton icon={<i className="fa fa-table" />} transform={insertTable} />
        <TableToolbarButton icon={<span className="fa-stack fa-1x">
            <i className="fa fa-table fa-stack-1x"></i>
            <i className="fa fa-ban fa-stack-2x"></i>
        </span>} transform={deleteTable} />
        <TableToolbarButton icon={<i className="fa fa-th" />} transform={addRow} />
        <TableToolbarButton icon={<span className="fa-stack fa-1x">
            <i className="fa fa-th fa-stack-1x"></i>
            <i className="fa fa-ban fa-stack-2x"></i>
        </span>} transform={deleteRow} />
        <TableToolbarButton icon={<i className="fa fa-columns" />} transform={addColumn} />
        <TableToolbarButton icon={<span className="fa-stack fa-1x">
            <i className="fa fa-columns fa-stack-1x"></i>
            <i className="fa fa-ban fa-stack-2x"></i>
        </span>} transform={deleteColumn} />
    </React.Fragment>
);
