/* eslint-disable react/prop-types */
import React, { useCallback, useRef } from "react";
import moment from "moment";
import { Payload } from "@cnri/cordra-client";
import { Link } from "react-router-dom";
import QRCode from 'qrcode.react';
import ShowIfAuthorized from "../ShowIfAuthorized";
import TitleContainer from "../Title/Container";
import PayloadsList from "../Payloads";
import RichTextEditor from "../RichTextEditor/";
import { Post } from "../../models/Post";
import { hdlProxyUrl, handlePrefix } from "../../services/MODSService";

interface Props {
    data: {
        content: Post;
        payloads: Array<Payload>;
    };
}

const Detailed = (props: Props): JSX.Element => {
    const { data: { content: post, payloads } } = props;
    const qrCodeRef = useRef<HTMLDivElement>(null);

    const postIdentifier = handlePrefix === "test" ? window.location.href : `${hdlProxyUrl}/${post.id}`;

    const downloadQRCode = useCallback(() => {
        const content = qrCodeRef && qrCodeRef.current && qrCodeRef.current.children[0].innerHTML;
        const contentWithSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" shape-rendering="crispEdges" height="128" width="128" viewBox="0 0 43 43">
        ${content}
        </svg>`;
        const blob = new Blob([contentWithSvg], { type: "image/svg+xml" });
        saveAs(blob, `qrcode-${post.id}.svg`);
    }, [post.id]);

    return (<div className="detailed">
        <div className="details">
            <h1>{post.subject}</h1>
            <footer className="detailed-footer d-flex align-items-center">
                <div className="title pr-2">
                    <i className="fa fa-envelope" />
                    <span className="text ml-2">
                        From{" "}<TitleContainer fetchProps={{ id: post.from }} />
                    </span>
                </div>
                <div className="title pl-2 pr-2">
                    {
                        post.to.map((to, index) => (<div className="title-last pr-2" key={to}>
                            <i className="fa fa-envelope" />
                            <span className="text ml-2">
                                To{" "}<TitleContainer key={to} fetchProps={{ id: to }} />
                            </span>
                        </div>))
                    }
                </div>
                <div className={`title ${payloads
                    ? ""
                    : "title-last"} pl-2 pr-2`}>
                    <i className="fa fa-calendar" />
                    <span className="text ml-2">
                        Edited on{" "}
                        {moment(new Date(post.modificationDate)).format("MMMM Do, YYYY")}
                    </span>
                </div>
                {
                    payloads && (<div className="title title-last pl-2 pr-2">
                        <i className="fa fa-paperclip" />
                        <span className="text ml-2">
                            {
                                `${payloads.length} ${
                                    payloads.length > 1
                                        ? "Payloads"
                                        : "Payload"}`
                            }
                        </span>
                    </div>)
                }
            </footer>
            {post.tags && post.tags.length > 0 && <footer className="search-result-footer d-flex justify-content-start flex-wrap">
                <div className="title title-last">
                    <span className="d-inline-block float-left"><i className="fa fa-tag" /></span>
                    {post.tags.map((tag, idx) => {
                        const searchTagTarget = `/posts/?query=/tags/_:"${tag}"`;
                        return (
                            <div className="d-inline-block mr-2" key={idx}><Link to={searchTagTarget}>
                                <span className="pl-2 text">{tag}</span>
                            </Link></div>);
                    })}
                </div>
            </footer>}
            <ShowIfAuthorized type="Post" action="fullView" data={post}>
                <footer className="detailed-footer d-flex align-items-center">
                    {
                        post.menu && post.menu.menuName && (<div className="title title-last mr-2">
                            <i className="fa fa-bars" />
                            <span className="text pl-2 pr-2">{post.menu.menuName}</span>
                            {post.menu && post.menu.menuName && post.menu.subMenuName && (<span className="text">{post.menu.subMenuName}</span>)}
                        </div>)
                    }
                </footer>
            </ShowIfAuthorized>
            <ShowIfAuthorized type="Post" action="fullView" data={post}>
                <footer className="detailed-footer d-flex align-items-center cursor-pointer">
                    {(<div className="title title-last mr-2" onClick={downloadQRCode}>
                        <i className="fa fa-qrcode" />
                        <span className="text pl-2 pr-2">Generate QR Code</span>
                    </div>)
                    }
                </footer>
                {qrCodeRef && <div ref={qrCodeRef} className="display-hide">
                    <QRCode value={postIdentifier} renderAs={"svg"} includeMargin={true} />
                </div>}
            </ShowIfAuthorized>
            <div className="border-bottom border-grey pb-3" />
            <div className="body mt-4 mb-4 pb-4">
                {post.bodyFormatted && <RichTextEditor value={post.bodyFormatted} readMode={true} key={post.id} />}
                {!post.bodyFormatted && <p>{post.body}</p>}
            </div>
            <div className="mt-4 mb-4 pb-4">
                {
                    payloads && (<React.Fragment>
                        <h4 className="header mb-3">Payloads</h4>
                        <PayloadsList isViewMode={true} payloads={payloads} id={post.id} />
                    </React.Fragment>)
                }
            </div>
        </div>
    </div>);
};

export default Detailed;
