import { ComponentType } from "react";
import Loading from "../components/Loading";
import branch from "./Branch";
import withInjectedProps from "./WithInjectedProps";

export interface LoadingProps {
    loading: boolean;
    loadingMessage?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withLoading = <P extends object>(defaultLoadingMessage = "loading") => (WrappedComponent: ComponentType<P & LoadingProps & any>): ComponentType<P> => {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const WithLoading = (props: P & LoadingProps & any) => branch(
        props.loading,
        withInjectedProps({
            message: props.loadingMessage ?
                props.loadingMessage : defaultLoadingMessage
        })(Loading),
        WrappedComponent
    )(props);

    return WithLoading;
};

export default withLoading;
