import { compose } from "redux";

import { CordraObject } from "@cnri/cordra-client";
import NewCG from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";

import { getObjectById, create } from "../../services/MODSService";
import { DataFetchingState } from "../common/hoc/WithDataFetching";

export default compose(
    withContainer<DataFetchingState<CordraObject>, CordraObject>({
        dataParams: {
            fetchHandler: getObjectById,
            saveHandler: create
        },
        Error,
        DefaultComponent: Default
    })
)(NewCG);
