import React from "react";
import { Link } from "react-router-dom";
// TODO: Handle this differently, in particular, make use of an error component to handle different types of page-wide error messages

const NotAuthorized = (): JSX.Element => {
    return (
        <React.Fragment>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-1 d-block">Not Authorized</span>
                        <div className="mb-4 lead">
                            You are not authorized to access the page.
                        </div>
                        <Link to="/posts">Back to Posts</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotAuthorized;
