/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { Component } from "react";
import Autosuggest from "react-autosuggest";

class AutoSuggestInput extends Component {
    state = {
        userStrokes: "",
        filteredSuggestions: [],
        userSelectedSuggestions: []
    };

    componentDidMount() {
        // eslint-disable-next-line react/prop-types
        const { value, multiple } = this.props;
        const userSelectedSuggestions = [];

        value &&
            (multiple
                ? value.map((id) => userSelectedSuggestions.push(this.getSuggestionFromId(id)))
                : userSelectedSuggestions.push(this.getSuggestionFromId(value)));
        this.setState({ userSelectedSuggestions });
    }

    getSuggestionFromId = (id) => {
        return this.props.suggestions.find(
            (suggestion) => suggestion.entry.id === id
        );
    };

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        const filteredSuggestions =
            inputLength < 3
                ? []
                : this.props.suggestions.filter((suggestion) => suggestion.entry.name.toLowerCase().includes(inputValue));
        this.setState({ filteredSuggestions });
    };

    getSuggestionValue = (suggestion) => "";

    renderSuggestion = (suggestion) => (
        <div>
            {suggestion.entry.name}({suggestion.type})
        </div>
    );

    onSuggestionsClearRequested = () => {
        this.setState({ filteredSuggestions: [] });
    };

    onSuggestionSelected = (event, { suggestion }) => {
        const { userSelectedSuggestions } = this.state;
        // eslint-disable-next-line react/prop-types
        const { name, multiple, handleChange } = this.props;
        !multiple && (userSelectedSuggestions.length = 0);
        userSelectedSuggestions.push(suggestion);
        this.setState(
            {
                userStrokes: "",
                userSelectedSuggestions
            },
            () => {
                const currentTarget = {
                    name,
                    value: multiple
                        ? userSelectedSuggestions.map((suggestion) => suggestion.entry.id)
                        : userSelectedSuggestions.length > 0 ? userSelectedSuggestions[0].entry.id : ""
                };
                handleChange({ currentTarget });
            }
        );
    };

    onChange = (event, { newValue, method }) => {
        if (method === "type") this.setState({ userStrokes: newValue });
    };

    getUserSelectedSuggestionsValue = () => {
        const { userSelectedSuggestions } = this.state;
        return userSelectedSuggestions && userSelectedSuggestions.length > 0
            ? userSelectedSuggestions.map((suggestion) => suggestion && suggestion.entry && suggestion.entry.id).join(", ")
            : "";
    };

    getUserSelectedSuggestionsTagValue = () => {
        const { userSelectedSuggestions } = this.state;
        return userSelectedSuggestions.map((suggestion, index) => {
            return (
                <span className="badge badge-primary ml-2" key={index}>
                    {suggestion && suggestion.entry && suggestion.entry.name}
                    {suggestion && suggestion.entry && (
                        <i
                            id={suggestion.entry.id}
                            onClick={this.removeSelectedSuggestion}
                            className="fa fa-close ml-2 suggestion-close"
                        />
                    )}
                </span>
            );
        });
    };

    removeSelectedSuggestion = ({ target: { id: entryId } = {} }) => {
        const { userSelectedSuggestions } = this.state;
        const { name, multiple, handleChange } = this.props;
        const userUpdatedSelectedSuggestions = userSelectedSuggestions.filter(
            (suggestion) => suggestion.entry.id !== entryId
        );
        this.setState(
            {
                userSelectedSuggestions: userUpdatedSelectedSuggestions,
                userStrokes: ""
            },
            () => {
                const currentTarget = {
                    name,
                    value: multiple
                        ? userUpdatedSelectedSuggestions.map((suggestion) => suggestion.entry.id)
                        : userUpdatedSelectedSuggestions.length > 0 ? userUpdatedSelectedSuggestions[0].entry.id : ""
                };
                handleChange({ currentTarget });
            }
        );
    };

    render() {
        const { readableTypes, multiple, error } = this.props;
        const { userStrokes, filteredSuggestions } = this.state;

        const placeholderText = multiple ? "one or more entries" : "one entry";

        const inputProps = {
            placeholder: `Type keywords to filter and add ${placeholderText} ${
                readableTypes ? `from ${readableTypes.join(", ")}` : ""
            }`,
            value: userStrokes,
            onChange: this.onChange,
            onKeyPress: this.onKeyPress
        };
        return (
            <div className="col-12 autosuggest nopadding">
                <Autosuggest
                    suggestions={filteredSuggestions}
                    onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                    }
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                />{" "}
                {this.getUserSelectedSuggestionsTagValue()}
                {error && <div className="alert alert-danger">{error}</div>}
            </div>
        );
    }
}

export default AutoSuggestInput;
