import { compose } from "redux";

import SummaryPost from ".";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import asList from "../common/hoc/AsList";
import extractQueryParams from "../ExtractQueryParams";
import extractQueryResults from "../ExtractQueryResults";
import injectLayoutWithQuickLinks from "../InjectLayoutWithQuickLinks";
import injectPaginationFilters from "../InjectPaginationFilters";

import { search } from "../../services/MODSService";

const type = "Post";
export default compose(
    // TODO: find a way to NOT hard code object types
    extractQueryParams(type),
    withContainer({
        dataParams: {
            fetchHandler: search
        },
        defaultLoadingMessage: "Loading Posts",
        Error,
        DefaultComponent: Default
    }),
    extractQueryResults(),
    injectLayoutWithQuickLinks(type),
    injectPaginationFilters(),
    asList()
)(SummaryPost);
