import moment from "moment";
import React, { Component } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    name: string;
    value: Date;
    enableMaxDate?: boolean;
    label?: string;
    error?: string;
    required?: boolean;
    onChange?: ({ currentTarget }: { currentTarget: { name: string; value: Date } }) => void;
    placeholder?: string;
    enable?: boolean;
}

interface State {
    value: Date;
}

class DatePicker extends Component<Props, State> {

    state: State = {
        value: moment(new Date()).toDate()
    };

    componentDidMount = ():void => {
        const { value } = this.props;
        this.setState({ value });
    };

    componentDidUpdate = (prevProps: Props):void => {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.setState({ value });
        }
    };

    handleChange = (date: Date):void => {
        const { name, onChange } = this.props;
        this.setState({ value: date }, () => {
            const currentTarget = {
                name,
                value: date
            };
            onChange && onChange({ currentTarget });
        });
    };

    render = (): JSX.Element => {
        const {
            name,
            label,
            enableMaxDate,
            error,
            required,
            enable
        } = this.props;

        const disabled = enable ? !enable : false;
        const isMaxDateEnabled = enableMaxDate ? enableMaxDate : false;

        const { value } = this.state;

        return (
            <React.Fragment>
                <div className="form-group">
                    {
                        label && (<label htmlFor={name} className="text-small no-padding d-block">
                            {label}
                            {required && <span className="required">*</span>}
                        </label>)
                    }
                    {isMaxDateEnabled ?
                        (<ReactDatePicker name={name} selected={value} maxDate={new Date()} onChange={this.handleChange} disabled={disabled}
                            dateFormat="MMMM d, yyyy" popperClassName="date-picker" />)
                        :
                        (<ReactDatePicker name={name} selected={value} onChange={this.handleChange} disabled={disabled}
                            dateFormat="MMMM d, yyyy" popperClassName="date-picker" />)
                    }
                    {error && <div className="alert alert-danger">{error}</div>}
                </div>
            </React.Fragment>);
    };
}

export default DatePicker;
