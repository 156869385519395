import NewPostContainer from "./Post/NewContainer";
import EditPostContainer from "./Post/EditContainer";
import DetailedPostContainer from "./Post/DetailedContainer";
import QueryPostContainer from "./Post/QueryContainer";

import NewMemberContainer from "./Member/NewContainer";
import EditMemberContainer from "./Member/EditContainer";
import DetailedMemberContainer from "./Member/DetailedContainer";
import QueryMemberContainer from "./Member/QueryContainer";

import NewWorkingGroupContainer from "./WorkingGroup/NewContainer";
import EditWorkingGroupContainer from "./WorkingGroup/EditContainer";
import DetailedWorkingGroupContainer from "./WorkingGroup/DetailedContainer";
import QueryWorkingGroupContainer from "./WorkingGroup/QueryContainer";

import NewGHRProviderContainer from "./GHRProvider/NewContainer";
import EditGHRProviderContainer from "./GHRProvider/EditContainer";
import DetailedGHRProviderContainer from "./GHRProvider/DetailedContainer";
import QueryGHRProviderContainer from "./GHRProvider/QueryContainer";

import NewARSProviderContainer from "./ARSProvider/NewContainer";
import EditARSProviderContainer from "./ARSProvider/EditContainer";
import DetailedARSProviderContainer from "./ARSProvider/DetailedContainer";

import NewOrganizationContainer from "./Organization/NewContainer";
import DetailedOrganizationContainer from "./Organization/DetailedContainer";
import EditOrganizationContainer from "./Organization/EditContainer";
import QueryOrganizationContainer from "./Organization/QueryContainer";

import NewGroupContainer from "./Group/NewContainer";
import DetailedGroupContainer from "./Group/DetailedContainer";
import EditGroupContainer from "./Group/EditContainer";
import QueryGroupContainer from "./Group/QueryContainer";

import NewBoardContainer from "./Board/NewContainer";
import EditBoardContainer from "./Board/EditContainer";
import QueryBoardContainer from "./Board/QueryContainer";

import NewCGContainer from "./CoordinationGroup/NewContainer";
import EditCGContainer from "./CoordinationGroup/EditContainer";
import QueryCGContainer from "./CoordinationGroup/QueryContainer";

import NewActivityContainer from "./Activity/NewContainer";
import DetailedActivityContainer from "./Activity/DetailedContainer";
import EditActivityContainer from "./Activity/EditContainer";

import NewProviderPostContainer from "./ProviderPost/NewContainer";
import EditProviderPostContainer from "./ProviderPost/EditContainer";
import DetailedProviderPostContainer from "./ProviderPost/DetailedContainer";

import DownloadContainer from "./Payloads/DownloadContainer";

interface ProtectedRoute {
    path: string;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
    exact: boolean;
    disableAccessToGuest?: boolean;
}

const protectedRoutesTable: Array<ProtectedRoute> = [
    {
        path: "/posts/new",
        component: NewPostContainer,
        exact: true
    },
    {
        path: "/posts/edit/:prefix/:id",
        component: EditPostContainer,
        exact: false
    },
    {
        path: "/posts/:prefix/:id",
        component: DetailedPostContainer,
        exact: false
    },
    {
        path: "/posts",
        name: "Posts",
        component: QueryPostContainer,
        exact: true
    },
    {
        path: "/participants/new",
        component: NewMemberContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/participants/edit/:prefix/:id",
        component: EditMemberContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/participants/:prefix/:id",
        component: DetailedMemberContainer,
        exact: false
    },
    {
        path: "/participants",
        name: "Members",
        component: QueryMemberContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/working-groups/new",
        component: NewWorkingGroupContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/working-groups/edit/:prefix/:id",
        component: EditWorkingGroupContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/working-groups/:prefix/:id",
        component: DetailedWorkingGroupContainer,
        exact: false
    },
    {
        path: "/working-groups",
        name: "Members",
        component: QueryWorkingGroupContainer,
        exact: true
    },
    {
        path: "/ghr-providers/new",
        component: NewGHRProviderContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/ghr-providers/edit/:prefix/:id",
        component: EditGHRProviderContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/ghr-providers/:prefix/:id",
        component: DetailedGHRProviderContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/ghr-providers",
        name: "GHRProvider",
        component: QueryGHRProviderContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/ars-providers/new",
        component: NewARSProviderContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/ars-providers/edit/:prefix/:id",
        component: EditARSProviderContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/ars-providers/:prefix/:id",
        component: DetailedARSProviderContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/organizations/new",
        component: NewOrganizationContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/organizations/edit/:prefix/:id",
        component: EditOrganizationContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/organizations/:prefix/:id",
        component: DetailedOrganizationContainer,
        exact: false
    },
    {
        path: "/organizations",
        name: "Organization",
        component: QueryOrganizationContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/groups/new",
        component: NewGroupContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/groups/edit/:prefix/:id",
        component: EditGroupContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/groups/:prefix/:id",
        component: DetailedGroupContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/groups",
        name: "Group",
        component: QueryGroupContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/activities/new",
        component: NewActivityContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/activities/edit/:prefix/:id",
        component: EditActivityContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/activities/:prefix/:id",
        component: DetailedActivityContainer,
        exact: false
    },
    {
        path: "/provider-bundles/new",
        component: NewProviderPostContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/provider-bundles/edit/:prefix/:id",
        component: EditProviderPostContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/provider-bundles/:prefix/:id",
        component: DetailedProviderPostContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/boards/new",
        component: NewBoardContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/boards/edit/:prefix/:id",
        component: EditBoardContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/boards",
        name: "Board",
        component: QueryBoardContainer,
        exact: true
    },
    {
        path: "/cg/new",
        component: NewCGContainer,
        exact: true,
        disableAccessToGuest: true
    },
    {
        path: "/cg/edit/:prefix/:id",
        component: EditCGContainer,
        exact: false,
        disableAccessToGuest: true
    },
    {
        path: "/cg",
        name: "CG",
        component: QueryCGContainer,
        exact: true
    },
    {
        path: "/payloads",
        name: "DownloadContainer",
        component: DownloadContainer,
        exact: true
    }
];
export default protectedRoutesTable;
