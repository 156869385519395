import React, { Component, FormEvent } from "react";
import Joi from "@hapi/joi";
import _ from "lodash";
import Input from "./Input";
import Textarea from "./TextArea";
import Checkbox from "./Checkbox";
import Datepicker from "./DatePicker";
import InputWithButton from "./InputWithButton";
import EnrichedSelect from "./EnrichedSelect";
import SelectWithCustomInput from "./SelectWithCustomInput";
import TagSet from "../../Tags/Container";
import AutoSuggestInput from "../../AutoSuggestInput/Container";
import PayloadZone from "../../Payloads/Zone";
import RichTextEditor from "../../RichTextEditor/";
import { getReadableTypes } from "../../util";
import CreatableMultiSelect from "./CreatableMultiSelect";

const MAX_PAYLOAD_SIZE = 50 * 1024 * 1024;

interface State {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { [key: string] : any };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: { [key: string]: string | number | undefined };
    touched: boolean;
}
class Form<T extends {}> extends Component<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: any = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    doSubmit = (): void => {};

    state: State = {
        data: {},
        errors: {},
        touched: false
    };

    validate = (): {} => {
        const joiObjectSchema = Joi.object(this.schema);
        const { error } = joiObjectSchema.validate(this.state.data, { abortEarly: false });
        if (!error) { this.setState({ errors: {} }); return {}; }
        const errors: { [key: string]: string | number | undefined } = { };
        for (const item of error.details) {
            errors[item.path[0]] = item.message;
        }
        this.setState({ errors });
        return errors;
    };

    validateProperty = ({ name, value }: { name: string; value: string | string[] | Date }): string | null => {
        const obj = { [name]: value };
        const localSchema = Joi.object({ [name]: this.schema[name] });
        const { error } = localSchema.validate(obj);
        return error ? error.details[0].message : null;
    };

    handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const errors = this.validate();
        this.setState({
            errors
        }, () => {
            _.isEmpty(errors) && this.doSubmit();
        });
    };

    handleCheckbox = ({ currentTarget }: { currentTarget: { name: string; value: boolean } }): void => {
        const { data } = this.state;
        data[currentTarget.name] = !data[currentTarget.name];
        this.setState({ data, touched: true });
    };

    handleChange = ({ currentTarget }: { currentTarget: { name: string; value: string } }): void => {
        const { errors, data } = this.state;
        const errorMessage = this.validateProperty(currentTarget);
        if (errorMessage) errors[currentTarget.name] = errorMessage;
        else delete errors[currentTarget.name];

        data[currentTarget.name] = currentTarget.value;
        this.setState({ data, errors, touched: true });
    };

    handleMultiSelectChange = ({ currentTarget }: { currentTarget: { name: string; value: string[] } }): void => {
        const { errors, data } = this.state;
        const errorMessage = this.validateProperty(currentTarget);
        if (errorMessage) errors[currentTarget.name] = errorMessage;
        else delete errors[currentTarget.name];

        data[currentTarget.name] = currentTarget.value;
        this.setState({ data, errors, touched: true });
    };

    handleDateChange = ({ currentTarget }: { currentTarget: { name: string; value: Date } }): void => {
        const { errors, data } = this.state;

        const errorMessage = this.validateProperty(currentTarget);
        if (errorMessage) errors[currentTarget.name] = errorMessage;
        else delete errors[currentTarget.name];


        data[currentTarget.name] = currentTarget.value;
        this.setState({ data, errors, touched: true });
    };

    handleArrayChange = ({ currentTarget }: { currentTarget: { name: string; dataset: { id: number }; value: string } }): void => {
        // TODO: add property validation

        // const errors = { ...this.state.errors };
        // const errorMessage = this.validateProperty(input);
        // errors[input.name] || (errors[input.name] = []);
        // if (errorMessage) errors[input.name][input.dataset.id] = errorMessage;
        // else delete errors[input.name][input.dataset.id];

        const { data } = this.state;
        data[currentTarget.name][currentTarget.dataset.id] = currentTarget.value;
        // this.setState({ data, errors });
        this.setState({ data, touched: true });
    };

    handleArrayDelete = ({ currentTarget }: { currentTarget: { name: string; value: string } }): void => {
        const { data } = this.state;
        const inputArray = data[currentTarget.name];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filteredArray = inputArray.filter((item: any) => item !== currentTarget.value);
        data[currentTarget.name] = filteredArray;
        this.setState({ data, touched: true });
    };

    handleArrayAdd = ({ currentTarget }: { currentTarget: { name: string; value: string } }): void => {
        const { data } = this.state;
        data[currentTarget.name].push("");
        this.setState({ data, touched: true });
    };

    handleAutoSuggestChange = ({ currentTarget }: { currentTarget: { name: string; value: string[] } }): void => {
        const { errors, data } = this.state;

        const errorMessage = this.validateProperty(currentTarget);
        if (errorMessage) errors[currentTarget.name] = errorMessage;
        else delete errors[currentTarget.name];

        data[currentTarget.name] = currentTarget.value;
        this.setState({ data, touched: true });
    };

    handleAutoSuggestSelectChange = ({ currentTarget }: { currentTarget: { name: string; value: string } }): void => {
        // TODO: add property validation
        const { data } = this.state;
        const nameIdx = currentTarget.name.split(";;");
        let selectValue = "";
        let suggestValue = "";
        let name = "";

        if (nameIdx.length > 1) {
            if (nameIdx[0].includes("select")) {
                selectValue = currentTarget.value;
                name = nameIdx[0].split("select")[0];
                data[name][nameIdx[1]].selectValue = selectValue;
            } else {
                suggestValue = currentTarget.value;
                name = nameIdx[0];
                data[name][nameIdx[1]].suggestValue = suggestValue;
            }
        } else {
            name = currentTarget.name;
            if (name.includes("select")) {
                selectValue = currentTarget.value;
                name = nameIdx[0].split("select")[0];
                data[name][nameIdx[1]].selectValue = selectValue;
            } else {
                suggestValue = currentTarget.value;
                data[name][nameIdx[1]].suggestValue = suggestValue;
            }
        }

        this.setState({ data, touched: true });
    };

    handleAutoSuggestArrayDelete = (name: string, idx: number): void => {
        const { data } = this.state;
        const inputArray = data[name];
        inputArray.splice(idx, 1);
        data[name] = inputArray;
        this.setState({ data, touched: true });
    };

    handleAutoSuggestArrayAdd = (name: string): void => {
        const { data } = this.state;
        data[name].push({ suggestValue: "", selectValue: "" });
        this.setState({ data, touched: true });
    };

    handlePayloadZoneChange = ({ currentTarget } : { currentTarget: { name: string; value: string; error: string } }): void => {
        const { data, errors } = this.state;
        const errorMessage = currentTarget.error;
        if (errorMessage) errors[currentTarget.name] = errorMessage;
        else delete errors[currentTarget.name];
        data[currentTarget.name] = currentTarget.value;
        this.setState({ data, errors, touched: true });
    };

    handleRawTextEditorChange = ({ currentTarget } : { currentTarget: { name: string; value: string; formatted: string } }): void => {
        const { data } = this.state;
        data[currentTarget.name + "Formatted"] = currentTarget.formatted;
        data[currentTarget.name] = currentTarget.value;
        this.setState({ data, touched: true });
    };

    renderArray = (name: string, arrayLabel: string, elementType: { type: string }): JSX.Element => {
        const { type } = elementType;
        const { data } = this.state;
        switch (type) {
            case "select": {
                return <React.Fragment></React.Fragment>;
            }
            case "button": {
                return <React.Fragment></React.Fragment>;
            }
            default: {
                return (<div>
                    <label htmlFor={name}>{arrayLabel}</label>
                    <button onClick={this.handleArrayAdd} className="btn btn-outline-primary m-3" name={name} type="button">
                        <i className="fa fa-plus" />
                    </button>
                    {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        data[name] && data[name].map((value: any, idx: number): JSX.Element => {
                            return (<InputWithButton key={idx} name={name} type={type} dataId={idx} value={data[name][idx]} onChange={this.handleArrayChange} onButtonClick={this.handleArrayDelete} buttonIcon="fa fa-close" />);
                        })
                    }
                </div>);
            }
        }
    };

    renderInput = (name: string, label: string, type: string = "text"): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;
        return (<Input name={name} label={label} type={type} value={data[name]} error={errors[name] as string} onChange={this.handleChange} required={required} />);
    };

    renderTextarea = (name: string, label: string): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;
        return (<Textarea name={name} label={label} value={data[name]} error={errors[name] as string} onChange={this.handleChange} required={required} />);
    };

    renderCheckBox = (name: string, label: string): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;
        return (<Checkbox name={name} label={label} checked={data[name] as boolean} error={errors[name] as string} onChecked={this.handleCheckbox} required={required} />);
    };

    renderSelect = (name: string, label: string, options: Array<string>, multiple: boolean = false): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;
        const selectOptions: Array<{ value: string; label: string }> = [];
        options.forEach(option => {
            selectOptions.push({ value: option, label: option });
        });
        const selectedOption = { value: data[name], label: data[name] };

        return (<EnrichedSelect name={name} label={label} options={selectOptions} selectedOption={selectedOption} error={errors[name] as string} onChange={this.handleChange} required={required} multiple={multiple} />);
    };

    renderSelectWithCustomInput = (name: string, label: string, options: Array<string>): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;

        return (<SelectWithCustomInput name={name} label={label} options={options} selectedOption={data[name]} error={errors[name]} onChange={this.handleChange} required={required} />);
    };

    renderMultiSelectWithCustomInput = (name: string, label: string, options: Array<string>): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;

        return (<CreatableMultiSelect name={name} label={label} options={options} selectedOptions={data[name] || []} error={errors[name]} onChange={this.handleMultiSelectChange} required={required} />);
    };


    renderTagSet = (name: string, label: string, multiple: boolean = false): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data } = this.state;
        return (<TagSet name={name} label={label} value={data[name]} selectedOptions={data[name] || []} onChange={this.handleMultiSelectChange} required={required} multiple={multiple} />);
    };

    renderDatePicker = (name: string, label: string): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;
        return (<Datepicker name={name} label={label} value={data[name]} error={errors[name] as string} onChange={this.handleDateChange} required={required} />);
    };

    renderButton = (label: string, icon: string): JSX.Element => {
        const { errors, touched } = this.state;
        return (<button disabled={_.isEmpty(errors) && touched ? false : true} className="btn btn-primary active" type="submit">
            {
                icon
                    ? (<span className="btn-label my-3 p-1">
                        <i className={icon} />
                    </span>)
                    : ("")
            }
            {label}
        </button>);
    };

    renderButtonWithClickHandler = (label: string, icon: string, onClickHandler: ()=>void): JSX.Element => {
        return (<button className="btn btn-secondary" onClick={onClickHandler} type="button">
            {
                icon
                    ? (<span className="btn-label my-3 p-1">
                        <i className={icon} />
                    </span>)
                    : ("")
            }
            {label}
        </button>);
    };

    renderAutoSuggest = (name: string, label: string, types: Array<string>, multiple: boolean = false): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;
        return (<div className="form-group">
            {
                label && (<label htmlFor={name}>
                    {label}
                    {required && <span className="required">*</span>}
                </label>)
            }
            <AutoSuggestInput name={name} value={data[name]} types={types} error={errors[name]} readableTypes={getReadableTypes(types)} handleChange={this.handleAutoSuggestChange} multiple={multiple} />
        </div>);
    };

    /** TODO: handle validation errors */
    renderAutoSuggestArray = (name: string, label: string, secondLabel: string, types: Array<string>, secondTypes: Array<string>): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data } = this.state;
        const selectName = name + "select";
        return (<div className="form-group">
            <div className="row">
                {
                    label && (<div className="col-6">
                        <label htmlFor={name}>
                            {label}
                            {required && <span className="required">*</span>}
                        </label>
                        <button onClick={e => this.handleAutoSuggestArrayAdd(name)} className="btn btn-outline-primary btn-sm ml-2" name={name} type="button">
                            <i className="fa fa-plus" />
                        </button>
                    </div>)
                }
            </div>
            {
                data[name] && data[name].map((value: string, idx: number) => {
                    return (<div className="row" key={idx}>
                        <div className="col-5">
                            <AutoSuggestInput name={name + ";;" + idx} readableTypes={getReadableTypes(types)} value={data[name][idx].suggestValue} types={types} handleChange={this.handleAutoSuggestSelectChange} data-id={idx} multiple={false} />
                        </div>
                        <div className="col-6">
                            <AutoSuggestInput name={selectName + ";;" + idx} value={data[name][idx].selectValue} types={secondTypes} readableTypes={getReadableTypes(secondTypes)} handleChange={this.handleAutoSuggestSelectChange} data-id={idx} multiple={false} />
                        </div>
                        <div className="col-1">
                            <button onClick={e => this.handleAutoSuggestArrayDelete(name, idx)} className="btn btn-outline-primary" name={data[name][idx]} type="button">
                                <i className="fa fa-trash" />
                            </button>
                        </div>
                    </div>);
                })
            }
        </div>);
    };

    renderPayloadZone = (name: string, label: string = "Payloads", acceptedFormats: Array<string> = [ "application/octet-stream" ], maxPayloads: number = 1, payloadNamesAllowed: Array<string> = [], payloadTypesAllowed: string[] = [], maxPayloadSize: number = MAX_PAYLOAD_SIZE): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);
        const { data, errors } = this.state;
        return (<div className="form-group">
            {label && <label htmlFor={name}>{label}</label>}
            {required && <span className="required">*</span>}
            <PayloadZone name={name} value={data[name]
                ? data[name].previouslyAcceptedPayloads
                : []} acceptedFormats={acceptedFormats} error={errors[name]} maxPayloadsAllowed={maxPayloads} maxPayloadSize={maxPayloadSize} payloadNamesAllowed={payloadNamesAllowed} payloadTypesAllowed={payloadTypesAllowed} handleChange={this.handlePayloadZoneChange} /> {errors[name] && (<div className="alert alert-danger">{errors[name]}</div>)}
        </div>);
    };

    renderRichTextEditor = (name: string, value: string, label: string = "TextArea", handleRawTextEditorChange = this.handleRawTextEditorChange): JSX.Element => {
        const required = Boolean(this.schema[name]._flags.presence);

        //TODO: Type checking; if formatted property is missing, things would break.
        return (<div className="form-group">
            {label && <label htmlFor={name}>{label}</label>}
            {required && <span className="required">*</span>}
            <RichTextEditor name={name} value={this.state.data[name + "Formatted"]} handleChange={handleRawTextEditorChange} readMode={false} />
        </div>);
    };

}

export default Form;
