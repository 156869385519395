import React, { KeyboardEvent, ChangeEvent, Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import { isSearchUnsupported } from "../util";

interface State {
    query: string;
    entryFromUser: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class SearchBar extends Component<RouteComponentProps<{}, any, any>, State> {
    state = {
        query: "",
        entryFromUser: false
    };

    handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Enter") {
            event.preventDefault();
            const queryText = this.state.entryFromUser ?
                this.state.query :
                this.getQueryFromUrl();
            const queryTarget = this.getQueryTarget();
            this.props.history.push(`${queryTarget}?query=${queryText}`);
        }
    };

    handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({ query: event.target.value, entryFromUser: true });
    };

    getQueryFromUrl = (): string => {
        if (this.props && this.props.location && this.props.location.search) {
            const params = queryString.parse(this.props.location.search);
            if (params && params.query) {
                return params.query as string;
            }
        }
        return "";
    };

    getQueryTarget = (): string => {
        if (this.props && this.props.location && this.props.location.pathname) {
            const pathname = this.props.location.pathname;
            const slashMatcher = pathname.match(/\//gi);
            const slashCharacterCount = slashMatcher && slashMatcher.length || 0;
            if (slashCharacterCount > 1) {
                const secondSlashCharacter = pathname.indexOf("/", 1);
                return pathname.substr(0, secondSlashCharacter);
            }
            return pathname;
        } else {
            return "/posts";
        }
    };

    render = (): JSX.Element => {
        const queryText = this.state.entryFromUser ?
            this.state.query :
            this.getQueryFromUrl();
        const queryTarget = this.getQueryTarget();
        return (<form className="input-group nav-item">
            <input type="text" name="query" className="form-control" placeholder={isSearchUnsupported(queryTarget)
                ? "Click other tabs below to search"
                : "Search..."
            } onChange={this.handleChange} onKeyDown={this.handleKeyDown} value={queryText} disabled={isSearchUnsupported(queryTarget)
                ? true
                : false} />
            <span className="input-group-append">
                <Link className={isSearchUnsupported(queryTarget)
                    ? "btn btn-primary disabled"
                    : "btn btn-primary active"
                } to={`${queryTarget}?query=${queryText}`}>
                    Search
                </Link>
            </span>
        </form>);
    };
}

export default withRouter(SearchBar);
