import React from "react";
import { Link } from "react-router-dom";

const TermsAndConditions: React.ComponentType = (): JSX.Element => {
    return (<div className="container">
        <div className="row justify-content-center">
            <div className="col-md-12">
                <header className="header mb-4">
                    <h4>
                        Terms and Conditions for Posting and other activities at mods.dona.net
                    </h4>
                </header>
                <div className="mb-4 lead">
                    By posting information at the{" "}
                    <Link to="/"> mods.dona.net</Link>{" "}
                    website, you hereby authorize the DONA Foundation (DONA) to make this information available to persons or organizations authorized by DONA to access this site in accordance with the{" "}
                    <Link to="/">mods.dona.net</Link>{" "}
                    <Link to="/privacy-statement">Privacy Statement</Link>. You acknowledge that you are authorized to make this submission and that your post is relevant to the mission and work of DONA. To the best of your knowledge and belief, you specifically agree that your posted information is free of any claims under copyright, patent or other rights or interests of yourself or other parties. You hereby acknowledge that, in the unlikely event it becomes necessary, DONA, in its sole discretion, will inform you of legal or other issues that have arisen with respect to your post, and, where appropriate, it may remove the post from this site, but retain it for reference purposes.
                </div>
                <div className="mb-4 lead">
                    The{" "}
                    <Link to="/">mods.dona.net</Link>{" "}
                    site is not intended for posting of bug fixes, updates or other software contributions relevant to DONA software releases at this time. You agree not to upload or otherwise provide such software contributions at the mods.dona.net site. Plans for evaluating DONA software are under development and will be made available in due course.
                </div>

                <div className="mb-4 lead">
                    You acknowledge that communications using the{" "}
                    <Link to="/">mods.dona.net</Link>{" "}
                    capability should be carried out in a collegial fashion and any derogatory remarks or similar postings are strictly prohibited. Please enjoy the site and use it to foster and develop relationships that will serve to enhance cooperation across various information systems in the public interest.
                </div>
            </div>
        </div>
    </div>);
};

export default TermsAndConditions;
