import React, { ComponentType } from "react";
import { ListProps, ItemProps } from "./interfaces";
import "./css/hoc.css";

const asList = <P extends object, T extends { id: string }>() => (WrappedComponent: ComponentType<ItemProps<T>>): ComponentType<P & ListProps<T>> => {
    const AsList = (props: P & ListProps <T>): JSX.Element => {
        return (<React.Fragment>
            <ul className="as-list-ul">
                {
                    props && props.listData && props.listData.map((item, idx) => (
                        <li className="as-list-li" key={item.id || idx}>
                            <WrappedComponent {...props} data={item} pageNum={props.pageNum} pageSize={props.pageSize} totalCount={props.totalCount} />
                        </li>
                    ))
                }
            </ul>
        </React.Fragment>);
    };
    return AsList;
};
export default asList;
