import { compose } from "redux";

import EditMember from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import extractId from "../ExtractId";

import { getObjectById, updateMember } from "../../services/MODSService";

export default compose(
    extractId(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById,
            saveHandler: updateMember
        },
        defaultLoadingMessage: "Retrieving Participant",
        Error,
        DefaultComponent: Default
    })
)(EditMember);
