/* eslint-disable react/prop-types */
import React from "react";
import { useHistory } from "react-router-dom";
import Pagination, { PaginationProps } from "./Pagination";

const PaginationFilters = (props: PaginationProps): JSX.Element => {
    const { totalCount, pageNum, pageSize } = props;
    const history = useHistory();
    return (<React.Fragment>
        {
            totalCount > 0 && (<div className="row">
                <div className="col-md-9 no-gutters">
                    <div className="w-100 d-flex row">
                        <div className="pb-4 d-flex">
                            {getHeader(pageNum, pageSize, totalCount)}
                        </div>
                        <div className="pb-2 ml-auto d-flex mr-n4">
                            <Pagination {...props} />
                        </div>
                    </div>
                </div>
            </div>)
        }
        {
            totalCount === 0 && (<div className="row">
                <div className="col-md-9 no-gutters">
                    <div className="text-center">
                        <div className="pb-4">
                            <div className="mb-4 lead">No information to show.</div>
                            <div className="pb-2 ml-auto">
                                <header className="back">
                                    <h3 className="btn btn-primary" onClick={history.goBack}>
                                        Go Back
                                    </h3>
                                </header>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        }
    </React.Fragment>);
};

export default PaginationFilters;

const getHeaderRanges = (pageNum: number, pageSize: number, totalCount: number) => {
    const headerRanges = {
        rangeStart: 0,
        rangeEnd: 0
    };
    headerRanges.rangeStart = (pageNum - 1) * pageSize + 1;
    const end = pageNum * pageSize < totalCount ?
        pageNum * pageSize :
        totalCount;
    headerRanges.rangeEnd = end;
    return headerRanges;
};

const getHeader = (pageNum: number, pageSize: number, totalCount: number) => {
    const header = getHeaderRanges(pageNum, pageSize, totalCount);
    return (<h5>
        Showing {header.rangeStart} - {header.rangeEnd} of {totalCount} results
    </h5>);
};
