import { ComponentType } from "react";
import branch from "./Branch";

export interface DefaultProps {
    useDefault: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withDefaulting = <P extends object>(Default: ComponentType<P>) => (WrappedComponent: ComponentType<P>) => {
    const WithDefaulting = (props: DefaultProps & P) => branch(props.useDefault, Default, WrappedComponent)(props);
    return WithDefaulting;
};

export default withDefaulting;
