import { compose } from "redux";

import React from "react";
import SummaryProviderPost from ".";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import asList from "../common/hoc/AsList";
import extractQueryParams from "./ExtractQueryParams";
import injectPayloadFilters from "./InjectPayloadFilters";
import extractQueryResults from "../ExtractQueryResults";

import { search } from "../../services/MODSService";

const type = "ProviderPost";
export default compose(
    // TODO: find a way to NOT hard code object types
    extractQueryParams(type),
    withContainer({
        dataParams: {
            fetchHandler: search
        },
        defaultLoadingMessage: "Loading Provider Bundles",
        Error,
        DefaultComponent: Default
    }),
    extractQueryResults(),
    injectPayloadFilters(type),
    asList()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
)(SummaryProviderPost) as React.FunctionComponent<any>;
