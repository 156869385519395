/* eslint-disable react/prop-types */
import React, { ComponentType } from "react";
import _ from "lodash";
import { nest } from "../util";
import { FetchProps } from "../common/hoc/interfaces";

const injectPayloadFilters = <P extends object>(type: string) => (WrappedComponent: ComponentType<P & FetchProps>): ComponentType<P & FetchProps> => {
    const InjectPayloadFilters = (props: P & FetchProps) => {
        let groupedByEnvironment: { [key: string]: {} } = {};
        if (props && props.listData) {
            const data = props.listData;
            groupedByEnvironment = nest(data, [ "content.environment" ]);
        }
        const ordered: { [key: string]: {} } = {};
        _(groupedByEnvironment)
            .keys()
            .sort()
            .each((key) => {
                ordered[key] = groupedByEnvironment[key];
            });

        groupedByEnvironment = ordered;
        return <div>{createKeyDivs(groupedByEnvironment, WrappedComponent)}</div>;
    };
    return InjectPayloadFilters;
};
export default injectPayloadFilters;


interface Props {
    listData: {};
}

export const createKeyDivs = (groupedByEnvironment: { [key: string]: {} }, WrappedComponent: ComponentType<Props>): Array<JSX.Element> => {
    const resultComponents: Array<JSX.Element> = [];
    _.forEach(groupedByEnvironment, (systemKey, systemValue) => {
        resultComponents.push(<div key={`${systemValue}`} className="mt-4 mb-4 pb-4">
            <h4 className="header mb-3">{systemValue}
                Uploads</h4>
            <div className="media border">
                <div className="media-body row pl-3">
                    <div className="col-3 border-right">
                        <h5 className="header">Uploaded Date</h5>
                    </div>
                    <div className="col-3 border-right">
                        <h5 className="header">Uploader</h5>
                    </div>
                    <div className="col-4 border-right">
                        <h5 className="header">Payloads</h5>
                    </div>
                </div>
            </div>
            <WrappedComponent listData={systemKey} />
        </div>);
    });
    return resultComponents;
};
