/* eslint-disable react/prop-types */
import React from "react";
import { Organization } from "../../models/Organization";
import RenderContent from "../InlineIconText";
import RichTextEditor from "../RichTextEditor/";

interface Props {
    data: {
        content: Organization;
    };
}

const Detailed = (props: Props): JSX.Element => {
    const { data: { content: organization } } = props;
    return (<div className="detailed">
        <div className="details">
            <h1>{organization.name}</h1>
            {
                organization.websiteUrl && (<footer className="detailed-footer d-flex align-items-center">
                    <div className="title title-last">
                        {
                            RenderContent("fa fa-external-link", <a href={organization.websiteUrl} target="_blank" rel="noopener noreferrer">
                                {organization.websiteUrl}
                            </a>)
                        }
                    </div>
                </footer>)
            }
            <div className="border-bottom border-grey pb-3" />
            <div className="body">
                {organization.addressFormatted && <RichTextEditor value={organization.addressFormatted} readMode={true} key={organization.id} />}
                {!organization.addressFormatted && <p>{organization.address}</p>}
            </div>
        </div>
    </div>);
};

export default Detailed;
