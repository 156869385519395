import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import moment from "moment";
import { CordraObject } from "@cnri/cordra-client";
import { handleError } from "../util";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import Form from "../common/components/Form";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";
import { GHRProvider, Member } from "../../models/GHRProvider";

interface Props extends DataFetchingState<CordraObject> {
    injected?: boolean;
}

interface ViewModel {
    id: string;
    name: string;
    organizations: string[];
    credential: string;
    systemAdmin: string;
    staffMembers: string[];
    memberSince: string;
    isDeactivated: boolean;
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;
}

class NewOrEdit extends Form<Props> {
    schema = {
        id: Joi.string().allow("").allow(null),
        name: Joi.string().required().min(1).label("name"),
        organizations: Joi.array()
            .items(Joi.string().required())
            .min(1)
            .unique()
            .required()
            .label("Organizations"),
        credential: Joi.number()
            .integer()
            .required()
            .min(0)
            .label("Credential"),
        systemAdmin: Joi.string().required().min(1).label("Staff Members"),
        staffMembers: Joi.array().items(Joi.string()).label("Staff Members"),
        memberSince: Joi.date().required().allow(null).label("Provider Since"),
        isDeactivated: Joi.boolean().label("Deactivate?")
    };

    state: State = {
        data: {
            id: "",
            name: "",
            organizations: [],
            credential: "",
            systemAdmin: "",
            staffMembers: [],
            memberSince: "",
            isDeactivated: false
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };

    mapToViewModel = (data: CordraObject): void => {
        const { content } = data;
        const systemAdmin = content.members
            .filter((member: Member) => member.title === "System Admin")
            .map((item: Member) => item.member)
            .join("");
        const staffMembers = content.members
            .filter((member: Member) => member.title === "Staff Member")
            .map((item: Member) => item.member);
        this.setState({
            data: {
                id: content.id,
                name: content.name,
                organizations: content.organizations,
                credential: content.credential,
                systemAdmin,
                staffMembers,
                memberSince: new Date(content.memberSince),
                isDeactivated: content.isDeactivated || false
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const dataModel: GHRProvider = {
            ...viewModel,
            credential: 0,
            members: []
        };
        const members: Member[] = [];
        viewModel.systemAdmin &&
            members.push({
                member: viewModel.systemAdmin,
                title: "System Admin"
            });
        viewModel.staffMembers.map((staffMember) => {
            return members.push({ member: staffMember, title: "Staff Member" });
        });
        dataModel.members = members;
        dataModel.credential = parseInt(viewModel.credential);
        dataModel.memberSince = moment(new Date(viewModel.memberSince))
            .format("YYYY-MM-DDTHH:mm:ssZ")
            .toString();
        delete dataModel.systemAdmin && delete dataModel.staffMembers;

        const data = {
            id: dataModel.id,
            type: "GHRProvider",
            content: dataModel
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState(
            {
                saveOngoing: true
            },
            () => {
                const data = this.mapToData(this.state.data);
                const { saveHandler } = this.props;
                saveHandler &&
                    saveHandler(data)
                        .then(({ id }) => {
                            this.setState({
                                saved: true,
                                data: {
                                    id
                                }
                            });
                        })
                        .catch((response) => {
                            this.setState(
                                {
                                    saveOngoing: false
                                },
                                () => {
                                    handleError(response);
                                    this.mapToViewModel(data);
                                }
                            );
                        });
            }
        );
    };

    render = (): JSX.Element => {
        const { saved, data, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;
        return saved ? (
            <Redirect to={`/ghr-providers/${data.id}`} />
        ) : this.state.mapped || !waitUntilMapped ? (
            <div>
                <h1>{shouldFetch ? "Edit" : "New"} GHR Provider</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("name", "Name")}
                    {this.renderInput("credential", "Credential")}
                    {this.renderAutoSuggest(
                        "organizations",
                        "Organizations",
                        ["Organization"],
                        true
                    )}

                    {this.renderAutoSuggest(
                        "systemAdmin",
                        "System Administrator",
                        ["Member"]
                    )}

                    {this.renderAutoSuggest(
                        "staffMembers",
                        "Staff Members",
                        ["Member"],
                        true
                    )}
                    {this.renderDatePicker("memberSince", "Provider Since")}
                    {this.renderCheckBox("isDeactivated", "Deactivate GHR")}
                    <div className="m-2" />
                    {saveOngoing
                        ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                        : this.renderButton("Save", "fa fa-save")}
                    <span className="m-2" />
                    <ButtonWithBackContext
                        label="Discard Changes"
                        enable={true}
                    />
                    <div className="m-2" />
                </form>
            </div>
        ) : (
            <React.Fragment></React.Fragment>
        );
    };
}

export default NewOrEdit;
