/* eslint-disable react/prop-types */
import React from "react";

interface Props {
    error?: {
        title: string;
        message: string;
    };
}

const Error = (props: Props): JSX.Element => {
    const { error } = props;
    return (
        <div className="error">
            <h2>Error: {error && error.title}</h2>
            <p>Message: {error && error.message}</p>
        </div>
    );
};

export default Error;
