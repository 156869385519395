import React, { Component, ChangeEvent, KeyboardEvent, MouseEvent } from "react";

interface Props {
    name: string;
    value: string;
    dataId: number;
    buttonText?: string;
    buttonIcon?: string;
    label?: string;
    error?: string;
    required?: boolean;
    onChange?: ({ currentTarget }: { currentTarget: { name: string; dataset: { id: number }; value: string } }) => void;
    onButtonClick?: ({ currentTarget }: { currentTarget: { name: string; value: string } }) => void;
    onKeyDown?: () => void;
    type?: string;
    placeholder?: string;
    enable?: boolean;
}


interface State {
    value: string;
}


class InputWithButton extends Component<Props, State> {

    state: State = {
        value: ""
    };

    componentDidMount = (): void => {
        const { value } = this.props;
        this.setState({ value });
    };

    componentDidUpdate = (prevProps: Props): void => {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.setState({ value });
        }
    };

    handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, onChange } = this.props;
        const { value } = event.target;
        this.setState({ value }, () => {
            const currentTarget = {
                name,
                value,
                dataset: {
                    id: this.props.dataId
                }
            };
            onChange && onChange({ currentTarget });
        });
    };

    handleButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
        const { name, onButtonClick } = this.props;
        const { value } = this.state;
        this.setState({ value }, () => {
            const currentTarget = {
                name,
                value
            };
            onButtonClick && onButtonClick({ currentTarget });
        });
    };

    handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
        const { onKeyDown } = this.props;
        if (event.keyCode === 13) {
            if (onKeyDown) {
                onKeyDown();
            }
        }
    };

    render = (): JSX.Element => {
        const {
            name,
            label,
            buttonText,
            buttonIcon,
            error,
            required,
            enable,
            value,
            ...rest
        } = this.props;

        const { value: valueFromState } = this.state;
        return (<div className="input-group mb-3">
            <input {...rest} name={name} value={valueFromState} className="form-control input-border-bottom" onChange={this.handleChange} onKeyDown={this.handleKeyDown} autoComplete="on" disabled />
            <div className="input-group-append">
                <button name={name} id={name} onClick={this.handleButtonClick} className="btn btn-outline-primary" type="button">
                    {buttonText && buttonText}
                    {buttonIcon && <i className={buttonIcon} />}
                </button>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>);

    };

}

export default InputWithButton;


// const InputWithButton = ({
//     name,
//     label,
//     buttonText,
//     buttonIcon,
//     onButtonClick,
//     error,
//     ...rest
// }) => {
// };

// export default InputWithButton;
