/* eslint-disable react/prop-types */
import React, { ComponentType } from "react";
import { Link, useHistory } from "react-router-dom";
import { Delegates1 } from "../models/CoordinationGroup";
import { Member } from "../models/WorkingGroup";
import QuickLinks from "./QuickLinks";
import ShowIfAuthorized from "./ShowIfAuthorized";

import { getPathFromType, getReadableNameFromType } from "./util";

const injectLayoutWithQuickLinks = <P extends object>(type: string) => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const InjectLayoutWithQuickLinks = (props: any): JSX.Element => {
        let data = props.data.content && props.data.content;
        const isDeactivated = data?.isDeactivated || false;
        const history = useHistory();
        if (type === "CoordinationGroup" || type === "Board") {
            data = props.data.results && props.data.results.length === 1 ?
                props.data.results[0] && props.data.results[0].content ?
                    props.data.results[0].content :
                    "" :
                "";
        }
        const inDetailedView = Boolean(data && data.id);
        const organizers = [] as Array<string>;
        if (type === "WorkingGroup" || type === "CoordinationGroup") {
            let chairs = [];
            if (type === "WorkingGroup") {
                chairs = data && data.members && data.members.filter((member: Member) => member.title === "Chair");
            } else if (type === "CoordinationGroup") {
                chairs = data && data.delegates && data.delegates.filter((delegate: Delegates1) => delegate.title === "Chair");
            }
            chairs && chairs.map((chair: Delegates1 | Member) => organizers.push(chair.member));
        }
        return (<div className="row">
            <div className="col-md-9">
                <WrappedComponent {...props} />
                { inDetailedView && (<div className="nav">
                    <header className="back">
                        <h3 className="btn btn-primary" onClick={history.goBack}>
                            Go Back
                        </h3>
                    </header>
                </div>)
                }
            </div>
            <div className="col-md-3 pl-2">
                <div className="offset-1 col-md-10">
                    { !inDetailedView && (<ShowIfAuthorized type={type} action="create" data={data}>
                        <QuickLinks>
                            <Link to={`/${getPathFromType(type)}/new`} className="bg-primary text-white">
                                New {getReadableNameFromType(type)}
                            </Link>
                        </QuickLinks>
                    </ShowIfAuthorized>)
                    }
                    { inDetailedView && (<QuickLinks>
                        {
                            type === "Post" && (<ShowIfAuthorized type="Post" action="create" data={data}>
                                <Link to={`/${getPathFromType("Post")}/new`} className="bg-primary text-white">
                                    New Post
                                </Link>
                            </ShowIfAuthorized>)
                        }
                        {
                            type !== "Post" && type !== "Member" && type !== "Organization" && type !== "Activity" && type !== "ProviderPost" &&
                            ((type === "GHRProvider" || type === "ARSProvider") && !isDeactivated) &&
                            (<ShowIfAuthorized type="Post" action="create" data={data}>
                                <Link to={`/${getPathFromType("Post")}/new?to=${data.id}`} className="bg-primary text-white">
                                    New Post
                                </Link>
                            </ShowIfAuthorized>)
                        }
                        {
                            (type === "WorkingGroup" || type === "CoordinationGroup") && (<ShowIfAuthorized type="Activity" action="create" data={data}>
                                <Link to={`/${getPathFromType("Activity")}/new?context=${
                                    data.id}&organizers=${organizers.join(";")}`} className="bg-primary text-white">
                                    New Activity
                                </Link>
                            </ShowIfAuthorized>)
                        }
                        {
                            ((type === "GHRProvider" || type === "ARSProvider") && !isDeactivated) && <React.Fragment>
                                <ShowIfAuthorized type="ProviderPost" action="create" data={data}>
                                    <Link to={`/${getPathFromType("ProviderPost")}/new?context=${
                                        data.id}`} className="bg-primary text-white">
                                        New Provider Post
                                    </Link>
                                </ShowIfAuthorized>
                                {type === "GHRProvider" && <ShowIfAuthorized type="ARSProvider" action="create" data={data}>
                                    <Link to={`/${getPathFromType("ARSProvider")}/new?ghrProviderId=${data.id}`} className="bg-primary text-white">
                                        New ARS Provider
                                    </Link>
                                </ShowIfAuthorized>}
                            </React.Fragment>
                        }
                        <ShowIfAuthorized type={type} action="edit" data={data}>
                            <Link to={`/${getPathFromType(type)}/edit/${data.id}`} className="bg-primary text-white">
                                Edit {getReadableNameFromType(type)}
                            </Link>
                        </ShowIfAuthorized>
                    </QuickLinks>)
                    }
                </div>
            </div>
        </div>);
    };
    return InjectLayoutWithQuickLinks;
};
export default injectLayoutWithQuickLinks;
