/* eslint-disable react/prop-types */
import React, { ComponentType } from "react";
import _ from "lodash";
import { CordraObject } from "@cnri/cordra-client";
import { ConfigurableMenuProps, MenuItem, PostMenu, SubMenuItem } from "./ConfigurableMenu";
import { Menu } from "../../models/Post";

const extractMenuQueryResults = () => (WrappedComponent: ComponentType<ConfigurableMenuProps>):ComponentType<ConfigurableMenuProps> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractMenuQueryResults = (props: any): JSX.Element => {
        const menuList: Array<MenuItem> = [] as Array<MenuItem>;
        if (props.data.size > 0) {
            const posts: Array<PostMenu> = props.data.results.map((result: CordraObject) => {
                const { content: post } = result;
                return _.pick(post, [ "id", "subject", "menu" ]);
            })
                .map((post: { id: string; subject: string; menu: Menu }) => {
                    return { id: post.id, title: post.subject, type: "Post", menuName: post.menu.menuName, subMenuName: post.menu.subMenuName };
                });

            const menus = _.mapKeys(posts, "menuName");

            _.keys(menus)
                .forEach(menuName => {
                    const entries: Array<PostMenu> = posts.filter(post => post.menuName === menuName);
                    const subMenus = _.mapKeys(entries, "subMenuName");

                    const subMenuList: Array<SubMenuItem> = [];

                    _.keys(subMenus)
                        .forEach(subMenuName => {
                            const finalEntries = entries.filter(entry => entry.subMenuName === subMenuName);
                            if (finalEntries && finalEntries.length > 0) {
                                if (subMenuName) {
                                    subMenuList.push({ subMenuName, entryList: finalEntries });
                                } else if (finalEntries.length === 1) {
                                    subMenuList.push({ subMenuName: finalEntries[0].title, entryList: finalEntries });
                                } else {
                                    subMenuList.push({ subMenuName: '...', entryList: finalEntries });
                                    // for (const entry of finalEntries) {
                                    //     subMenuList.push({ subMenuName: entry.title, entryList: [ entry ] });
                                    // }
                                }
                            }
                        });
                    menuList.push({ menuName, subMenuList });
                });
        }
        return <WrappedComponent data={menuList} />;
    };
    return ExtractMenuQueryResults;
};
export default extractMenuQueryResults;
