import { compose } from "redux";

import EditGHRProvider from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import extractId from "../ExtractId";

import { getObjectById, updateProvider } from "../../services/MODSService";

export default compose(
    extractId(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById,
            saveHandler: updateProvider
        },
        defaultLoadingMessage: "Retrieving GHR Operator",
        Error,
        DefaultComponent: Default
    })
)(EditGHRProvider);
