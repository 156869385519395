/* eslint-disable react/prop-types */
import React from "react";
import TitleContainer from "../Title/Container";
import RichTextEditor from "../RichTextEditor/";
import { Board as BoardType } from "../../models/Board";

interface Props {
    data: {
        content: BoardType;
    };
}

const Board = (props: Props): JSX.Element => {
    const { data: { content: board } } = props;
    return (<div className="detailed">
        <div className="details">
            <h1>{board.name}</h1>
            <footer className="detailed-footer d-flex align-items-center">
                { board.directors && (<div className="title pl-2 pr-2">
                    <i className="fa fa-user" />
                    <span className="text ml-2">
                        {`${board.directors.length} Directors`}
                    </span>
                </div>)
                }
                { board.institutionalRepresentatives && (<div className="title pl-2 pr-2">
                    <i className="fa fa-user" />
                    <span className="text ml-2">
                        {
                            `${
                                board.institutionalRepresentatives.length} Institutional Representatives`
                        }
                    </span>
                </div>)
                }
            </footer>
            <div className="border-bottom border-grey pb-3" />
            <div className="body mt-4 mb-4 pb-4">
                {board.descriptionFormatted && <RichTextEditor value={board.descriptionFormatted} readMode={true} key={board.id} />}
                {!board.descriptionFormatted && <p>{board.description}</p>}
            </div>
            { board.directors && (<div className="mt-4 mb-4 pb-4">
                <h4 className="header mb-3">Board of Directors</h4>
                {
                    board.directors.filter(member => member.title === "Chair").map(chair => (<div className="media mt-1" key={chair.member}>
                        <div className="media-body">
                            <div className="main pull-left">
                                <span className="title">
                                    <TitleContainer key={chair.member} fetchProps={{
                                        id: chair.member
                                    }} />
                                    <span className="title ml-2">({chair.title})</span>
                                </span>
                            </div>
                        </div>
                    </div>))
                }

                { board.directors.filter(member => member.title === "Secretary").map(secretary => (<div className="media mt-1" key={secretary.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title">
                                <TitleContainer key={secretary.member} fetchProps={{
                                    id: secretary.member
                                }} />
                                <span className="title ml-2">({secretary.title})</span>
                            </span>
                        </div>
                    </div>
                </div>))
                }

                { board.directors.filter(member => member.title === "Director").map(director => (<div className="media mt-1" key={director.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title">
                                <TitleContainer key={director.member} fetchProps={{
                                    id: director.member
                                }} />
                                <span className="title ml-2">({director.title})</span>
                            </span>
                        </div>
                    </div>
                </div>))
                }
            </div>)
            }
            {
                board.institutionalRepresentatives && (<div className="mt-4 mb-4 pb-4">
                    <h4 className="header mb-3">Institutional Representatives</h4>
                    {
                        board.institutionalRepresentatives.map(institutionalRepresentatives => (<div className="media mt-1" key={institutionalRepresentatives.member}>
                            <div className="media-body">
                                <div className="main pull-left">
                                    <span className="title">
                                        <TitleContainer key={institutionalRepresentatives.member} fetchProps={{
                                            id: institutionalRepresentatives.member
                                        }} />
                                        <span className="title ml-2">
                                            ({institutionalRepresentatives.title})
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>))
                    }
                </div>)
            }
        </div>
    </div>);
};

export default Board;
