import React from "react";
import _ from "lodash";
import { FetchProps, WrappedComponentDataFetchingProps } from "../hoc/interfaces";

export interface PaginationProps {
    pageSize: number;
    totalCount: number;
    pageNum: number;
}

const Pagination = <T extends object>(props: PaginationProps & FetchProps & WrappedComponentDataFetchingProps<T>): JSX.Element => {
    const totalCount = props.totalCount;
    const pageSize = props.pageSize;
    const pageNum = props.pageNum;
    const refetchHandler = props.refetchHandler;
    const fetchProps = props.fetchProps;

    const numberOfPages = Math.ceil(totalCount / pageSize);
    if (numberOfPages === 1) return <React.Fragment />;
    const pages = buildPages(numberOfPages, pageNum);

    return (<nav>
        <ul className="pagination">
            {
                pages.map(page => (<li key={page} className={page === pageNum
                    ? "page-item active"
                    : "page-item"}>
                    <span className="page-link" onClick={page === '...1' || page === '...2' ? undefined : e => onPageClick(e, page, refetchHandler, fetchProps)}>
                        {page === '...1' || page === '...2' ? '...' : page}
                    </span>
                </li>))
            }
        </ul>
    </nav>);
};
export default Pagination;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onPageClick = (e: React.MouseEvent, page: number, refetchHandler: (fetchProps: any) => void, fetchProps: any) => {
    e.preventDefault();
    const updatedProps = fetchProps;
    updatedProps.pageNum = page - 1;
    refetchHandler(updatedProps);
};

function buildPages(numberOfPages: number, pageNum?: number): (number | '...1' | '...2')[] {
    if (!pageNum) pageNum = 1;
    if (numberOfPages < 10) return _.range(1, numberOfPages + 1);
    let low = pageNum - 2;
    let high = pageNum + 2;
    if (low <= 3) {
        high += (3 - low);
        low = 2;
    } else if (high >= numberOfPages - 2) {
        low -= (high - numberOfPages) + 2;
        high = numberOfPages - 1;
    }
    const res: (number | '...1' | '...2')[] = [1];
    if (low > 2) res.push('...1');
    res.push(..._.range(low, high + 1));
    if (high < numberOfPages - 1) res.push('...2');
    res.push(numberOfPages);
    return res;
}
