import { compose } from "redux";

import EditPost from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import extractId from "../ExtractId";

import { getObjectById, updatePost } from "../../services/MODSService";

export default compose(
    extractId(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById,
            saveHandler: updatePost
        },
        defaultLoadingMessage: "Retrieving Post",
        Error,
        DefaultComponent: Default
    })
)(EditPost);
