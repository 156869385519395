import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import { CordraObject } from "@cnri/cordra-client";
import { handleError } from "../util";
import Form from "../common/components/Form";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

interface Props extends DataFetchingState<CordraObject> {
    injected?: boolean;
}

interface ViewModel {
    id: string;
    groupName: string;
    description: string;
    descriptionFormatted: string;
    members: string[];
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {
    schema = {
        id: Joi.string()
            .allow("")
            .allow(null),
        groupName: Joi.string()
            .required()
            .min(1)
            .label("Group Name"),
        members: Joi.array()
            .items(Joi.string()
                .required())
            .min(1)
            .unique()
            .required()
            .label("Members"),
        description: Joi.string()
            .allow("")
            .allow(null)
            .label("rawText"),
        descriptionFormatted: Joi.string()
            .allow("")
            .allow(null)
            .label("Description")
    };

    state: State = {
        data: {
            id: "",
            groupName: "",
            description: "",
            descriptionFormatted: "",
            members: []
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };

    mapToViewModel = (data: CordraObject): void => {
        const { content } = data;
        this.setState({
            data: {
                id: content.id || "",
                groupName: content.groupName,
                description: content.description || "",
                descriptionFormatted: content.descriptionFormatted || "",
                members: content.members || []
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const data = {
            id: viewModel.id,
            type: "Group",
            content: viewModel
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data)
                .then(({ id }) => {
                    this.setState({
                        saved: true,
                        data: {
                            id
                        }
                    });
                })
                .catch(response => {
                    this.setState({
                        saveOngoing: false
                    }, () => {
                        handleError(response);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, data, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;
        return saved ?
            (<Redirect to={`/groups/${data.id}`} />) :
            this.state.mapped || !waitUntilMapped ?
                (<div>
                    <h1>{
                        shouldFetch
                            ? "Edit"
                            : "New"
                    } Group</h1>
                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput("groupName", "Group Name")}
                        {this.renderAutoSuggest(
                            "members",
                            "Participants",
                            [
                                "Member",
                                "GHRProvider",
                                "ARSProvider",
                                "CoordinationGroup",
                                "Board",
                                "WorkingGroup"
                            ],
                            true
                        )}
                        {this.renderRichTextEditor("description", "Description")}
                        <div className="p-3" />
                        {saveOngoing
                            ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                            : this.renderButton("Save", "fa fa-save")}
                        <span className="m-2" />
                        <ButtonWithBackContext label="Discard Changes" enable={true} />
                        <div className="m-2" />
                    </form>
                </div>) :
                <React.Fragment></React.Fragment>;
    };
}

export default NewOrEdit;
