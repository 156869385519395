import { compose } from "redux";

import DetailedCG from ".";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import injectLayoutWithQuickLinks from "../InjectLayoutWithQuickLinks";
import extractTypeParams from "../ExtractTypeParams";
import extractQueryResults from "../ExtractQueryResults";
import extractCGResult from "./ExtractCGResult";

import { search } from "../../services/MODSService";

const type = "CoordinationGroup";
export default compose(
    // TODO: find a way to NOT hard code object types
    extractTypeParams(type),
    withContainer({
        dataParams: {
            fetchHandler: search
        },
        defaultLoadingMessage: "Loading Coordinating Group",
        Error,
        DefaultComponent: Default
    }),
    extractQueryResults(),
    injectLayoutWithQuickLinks(type),
    extractCGResult()
)(DetailedCG);
