/* eslint-disable react/prop-types */
import React, { ComponentType } from "react";

const extractBoardResult = <P extends object>() => (WrappedComponent: ComponentType<P>):ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractBoardResult = (props: any): JSX.Element => {
        const { data, ...rest } = props;
        return (<WrappedComponent data={data.results && data.results.length === 1
            ? data.results[0]
            : ""} {...rest} />);
    };
    return ExtractBoardResult;
};
export default extractBoardResult;
