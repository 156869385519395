import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import { CordraObject } from "@cnri/cordra-client";
import { handleError } from "../util";
import Form from "../common/components/Form";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import { Board, Director, InstitutionalRepresentative } from "../../models/Board";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

interface Props extends DataFetchingState<CordraObject> {
    injected?: boolean;
}

interface ViewModel {
    id: string;
    name: string;
    description: string;
    descriptionFormatted: string;
    chair: string;
    secretary: string;
    directors: string[];
    institutionalRepresentatives: string[];
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {
    schema = {
        id: Joi.string()
            .allow(""),
        name: Joi.string()
            .required()
            .min(1)
            .label("Subject"),
        description: Joi.string()
            .required()
            .min(1)
            .label("rawText"),
        descriptionFormatted: Joi.string()
            .required()
            .min(1)
            .label("Description"),
        chair: Joi.string()
            .required()
            .min(1)
            .label("Chair"),
        secretary: Joi.string()
            .required()
            .min(1)
            .label("Secretary"),
        directors: Joi.array()
            .items(Joi.string()
                .required())
            .min(1)
            .unique()
            .required()
            .label("Directors"),
        institutionalRepresentatives: Joi.array()
            .items(Joi.string()
                .required())
            .min(1)
            .unique()
            .required()
            .label("institutional Representatives")
    };

    state: State = {
        data: {
            id: "",
            name: "",
            description: "",
            descriptionFormatted: "",
            chair: "",
            secretary: "",
            directors: [],
            institutionalRepresentatives: []
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };


    mapToViewModel = (data: CordraObject): void => {
        const { content } = data;
        const chair = content.directors.filter((member: Director) => member.title === "Chair")[0].member;
        const secretary = content.directors.filter((member: Director) => member.title === "Secretary")[0].member;
        const directors = content.directors.filter((member: Director) => member.title === "Director")
            .map((item: Director) => item.member);

        const institutionalRepresentatives = content.institutionalRepresentatives.map((item: InstitutionalRepresentative) => item.member);

        this.setState({
            data: {
                id: content.id,
                name: content.name,
                description: content.description || "",
                descriptionFormatted: content.descriptionFormatted || "",
                chair,
                secretary,
                directors,
                institutionalRepresentatives
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        this.props.shouldFetch && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const dataModel: Board = { ...viewModel, directors: [], institutionalRepresentatives: [] };
        const directors: Array<Director> = [];
        viewModel.chair && directors.push({ member: viewModel.chair, title: "Chair" });
        viewModel.secretary && directors.push({ member: viewModel.secretary, title: "Secretary" });
        viewModel.directors.map(director => {
            return directors.push({ member: director, title: "Director" });
        });
        dataModel.directors = directors;

        const institutionalRepresentatives: Array<InstitutionalRepresentative> = [];
        viewModel.institutionalRepresentatives && viewModel.institutionalRepresentatives.map(institutionalRepresentative => {
            return institutionalRepresentatives.push({ member: institutionalRepresentative, title: "Institutional Representative" });
        });

        dataModel.institutionalRepresentatives = institutionalRepresentatives;

        delete dataModel.chair && delete dataModel.secretary;

        const data = {
            id: dataModel.id,
            type: "Board",
            content: dataModel
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data)
                .then(({ id }) => {
                    this.setState({
                        saved: true,
                        data: {
                            id
                        }
                    });
                })
                .catch(response => {
                    this.setState({
                        saveOngoing: false
                    }, () => {
                        handleError(response);
                        this.mapToViewModel(data);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;
        return saved ?
            (<Redirect to={`/boards`} />) :
            this.state.mapped || !waitUntilMapped ?
                (<div>
                    <h1>{
                        shouldFetch
                            ? "Edit"
                            : "New"
                    }
                        Board</h1>
                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput("name", "Name")}
                        {this.renderRichTextEditor("description", "Description")}
                        {this.renderAutoSuggest("chair", "Chair", ["Member"])}
                        {this.renderAutoSuggest("secretary", "Secretary", ["Member"])}
                        {this.renderAutoSuggest("directors", "Directors", ["Member"], true)}
                        {this.renderAutoSuggest("institutionalRepresentatives", "Institutional Representatives", ["Member"], true)}
                        <div className="p-3" />
                        {saveOngoing
                            ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                            : this.renderButton("Save", "fa fa-save")}
                        <span className="m-2" />
                        <ButtonWithBackContext label="Discard Changes" enable={true} />
                        <div className="m-2" />
                    </form>
                </div>) :
                <React.Fragment></React.Fragment>;
    };
}

export default NewOrEdit;
