import React from "react";

const QuickLinks: React.FC<{}> = ({ children }):JSX.Element => {
    return (
        <React.Fragment>
            <div className="quick-links-widget">
                <header>
                    <h5>Quick Links</h5>
                </header>
                <div className="quick-links">{children}</div>
            </div>
        </React.Fragment>
    );
};

export default QuickLinks;
