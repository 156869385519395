import React, { ComponentType } from "react";

const generateSuggestionList = <P extends object>() => (WrappedComponent: ComponentType<P>):ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const GenerateSuggestionList = (props: any): JSX.Element => {
        // eslint-disable-next-line react/prop-types
        const { content: tagSet } = props.data;
        return (<WrappedComponent tagSet={tagSet.elements} {...props} />);
    };
    return GenerateSuggestionList;
};
export default generateSuggestionList;
