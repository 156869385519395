/* eslint-disable react/prop-types */
import React, { ComponentType } from "react";
import queryString from "query-string";

export interface ActivationKeyParams {
    activationKey: string;
    id: string;
    emailAddress: string;
}

const extractActivationKey = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractActivationKey = (props: any): JSX.Element => {
        const queryValues = props.location && props.location.search && queryString.parse(props.location.search);
        const params: ActivationKeyParams = {
            activationKey: queryValues && queryValues.activationKey ?
                queryValues.activationKey :
                "",
            id: `${props.match.params.prefix}/${props.match.params.id}`,
            emailAddress: queryValues && queryValues.emailAddress ?
                queryValues.emailAddress :
                ""
        };
        return <WrappedComponent activationProps={params} fetchProps={params} {...props} />;
    };
    return ExtractActivationKey;
};
export default extractActivationKey;
