import { compose } from "redux";

import ConfigurableMenu from "./ConfigurableMenu";
import Default from "./Default";
import extractMenuQueryParams from "./ExtractMenuQueryParams";
import extractMenuQueryResults from "./ExtractMenuQueryResults";

import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";

import { search } from "../../services/MODSService";

export default compose(
    extractMenuQueryParams(),
    withContainer({
        dataParams: {
            fetchHandler: search
        },
        defaultLoadingMessage: "Loading Menu",
        Error,
        DefaultComponent: Default
    }),
    extractMenuQueryResults()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
)(ConfigurableMenu) as React.FunctionComponent<any>;
