import moment from "moment";
import { PrimaryInfo } from './../util';
import { ProviderPost } from "../../models/ProviderPost";

export const getSuggestion = (providerPost: ProviderPost): PrimaryInfo => {
    const creationDate =
        "Created On " +
        moment(new Date(providerPost.creationDate)).format("MMMM Do, YYYY");
    return {
        name: providerPost.bundle + providerPost.environment + creationDate,
        id: providerPost.id
    };
};
