import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import { CordraObject } from "@cnri/cordra-client";
import { handleError } from "../util";
import Form from "../common/components/Form";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

interface Props extends DataFetchingState<CordraObject> {
    injected?: boolean;
}

interface ViewModel {
    id: string;
    name: string;
    address: string;
    addressFormatted: string;
    websiteUrl: string;
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {

    schema = {
        id: Joi.string()
            .allow("")
            .allow(null),
        name: Joi.string()
            .required()
            .min(1)
            .label("Subject"),
        address: Joi.string()
            .required()
            .min(1)
            .label("rawText"),
        addressFormatted: Joi.string()
            .required()
            .min(1)
            .label("Address"),
        websiteUrl: Joi.string()
            .allow("")
            .allow(null)
    };

    state: State = {
        data: {
            id: "",
            name: "",
            address: "",
            addressFormatted: "",
            websiteUrl: ""
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };

    mapToViewModel = (data: CordraObject): void => {
        const { content } = data;
        this.setState({
            data: {
                id: content.id,
                name: content.name,
                address: content.address || "",
                addressFormatted: content.addressFormatted || "",
                websiteUrl: content.websiteUrl
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const data = {
            id: viewModel.id,
            type: "Organization",
            content: viewModel
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data)
                .then(({ id }) => {
                    this.setState({
                        saved: true,
                        data: {
                            id
                        }
                    });
                })
                .catch(response => {
                    this.setState({
                        saveOngoing: false
                    }, () => {
                        handleError(response);
                        this.mapToViewModel(data);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, data, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;
        return saved ?
            (<Redirect to={`/organizations/${data.id}`} />) :
            this.state.mapped || !waitUntilMapped ?
                (<div>
                    <h1>{
                        shouldFetch
                            ? "Edit"
                            : "New"
                    } Organization</h1>
                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput("name", "Name")}
                        {this.renderRichTextEditor("address", "Address")}
                        {this.renderInput("websiteUrl", "Website URL")}
                        <div className="p-3" />
                        {saveOngoing
                            ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                            : this.renderButton("Save", "fa fa-save")}
                        <span className="m-2" />
                        <ButtonWithBackContext label="Discard Changes" enable={true} />
                        <div className="m-2" />
                    </form>
                </div>) :
                <React.Fragment></React.Fragment>;
    };
}

export default NewOrEdit;
