import React, { KeyboardEventHandler, useEffect, useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import { createOption, OptionType } from './interfaces';

interface Props {
    label: string;
    name: string;
    options: Array<string>;
    selectedOptions: Array<string>;
    error: string | number | undefined;
    required: boolean;
    onChange: ({ currentTarget }: { currentTarget: { name: string; value: string[] } }) => void;
}

const CreatableMultiSelect = (props: Props): JSX.Element => {
    const {
        label,
        name,
        required,
        error,
        onChange,
        options: retrievedOptions,
        selectedOptions: previouslySelectedOptions
    } = props;

    const [options, setOptions ] = useState<Array<OptionType>>([]);
    const [ selectedOptions, setSelectedOptions ] = useState<Array<OptionType>>([]);

    useEffect(() => {
        const newOptions = new Array<OptionType>();
        const newSelectedOptions = new Array<OptionType>();
        retrievedOptions.forEach((retrievedOption: string) => {
            newOptions.push(createOption(retrievedOption));
        });
        previouslySelectedOptions.forEach((previouslySelectedOption: string) => {
            newSelectedOptions.push(createOption(previouslySelectedOption));
        });
        setSelectedOptions(newSelectedOptions);
        setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentTarget = {
            name,
            value: selectedOptions.map(option => option.label)
        };
        onChange && onChange({ currentTarget });
    }, [selectedOptions, name, onChange]);

    const handleChange = (newValues: OnChangeValue<OptionType, true>, actionMeta: ActionMeta<OptionType>): void => {
        let newSelectedOptions = [...selectedOptions];
        if (actionMeta.action === "create-option") {
            const newOptions = [...options];
            const newlyCreatedOption = createOption(actionMeta.option.value);
            newOptions.push(newlyCreatedOption);
            newSelectedOptions.push(newlyCreatedOption);
            setOptions(newOptions);
        } else if (actionMeta.action === "select-option" && newSelectedOptions.findIndex(selectedOption => selectedOption.label === actionMeta.option?.label) < 0) {
            actionMeta && actionMeta.option && newSelectedOptions.push(actionMeta.option);
        } else {
            newSelectedOptions = [...newValues];
        }
        setSelectedOptions(newSelectedOptions);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event: any) => {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                event.preventDefault();
        }
    };
    return (<React.Fragment>
        <div className="form-group">
            {
                label && (<label htmlFor={name} className="text-small no-padding">
                    {label}
                    {required && <span className="required">*</span>}
                </label>)
            }
            <CreatableSelect
                isMulti={true}
                onChange={handleChange}
                options={options}
                value={selectedOptions}
                onKeyDown={handleKeyDown}
            /> {error && <div className="alert alert-danger">{error}</div>}
        </div>
    </React.Fragment>
    );
};

export default CreatableMultiSelect;
