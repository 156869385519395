import React from "react";
import { Link } from "react-router-dom";
// TODO: Handle this differently, in particular, make use of an error component to handle different types of page-wide error messages

const NotFound: React.ComponentType = (): JSX.Element => {
    return (<div className="container">
        <div className="row justify-content-center">
            <div className="col-md-12 text-center">
                <span className="display-1 d-block">Not Found</span>
                <div className="mb-4 lead">
                    The page you are looking for was not found.
                </div>
                <Link to="/posts">Back to Posts</Link>
            </div>
        </div>
    </div>);
};

export default NotFound;
