import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import ShowIfAuthorized from "../ShowIfAuthorized";
import { AuthContext } from "../AuthStore";

const FixedMenu = (): JSX.Element => {
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    return (
        <React.Fragment>
            <li className="nav-item">
                <NavLink to="/posts" className="nav-link">
                    Posts
                </NavLink>
            </li>
            <li className="nav-item">
                {/* TODO: Figure out the CG id at runtime */}
                <NavLink to="/cg" className="nav-link">
                    Coordination Group
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/working-groups" className="nav-link">
                    Working Groups
                </NavLink>
            </li>
            { user && !user.isGuest &&
                <li className="nav-item">
                    <NavLink to="/ghr-providers" className="nav-link">
                        GHR Providers
                    </NavLink>
                </li> }
            <li className="nav-item">
                {/* TODO: Figure out the DONA Board id at runtime */}
                <NavLink to="/boards" className="nav-link">
                    Board
                </NavLink>
            </li>
            { user && !user.isGuest &&
            <li className="nav-item">
                <NavLink to="/participants" className="nav-link">
                    Participants
                </NavLink>
            </li>
            }
            <ShowIfAuthorized type="Organization" action="view">
                <li className="nav-item">
                    <NavLink to="/organizations" className="nav-link">
                        Organizations
                    </NavLink>
                </li>
            </ShowIfAuthorized>
            <ShowIfAuthorized type="Group" action="view">
                <li className="nav-item">
                    <NavLink to="/groups" className="nav-link">
                        Groups
                    </NavLink>
                </li>
            </ShowIfAuthorized>
        </React.Fragment>);
};

export default FixedMenu;
