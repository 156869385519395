import React, { ComponentType } from "react";
import { QueryParams } from "../interfaces";
import { getSortFields } from "../util";

const extractParams = <P extends object>(type: string) => (WrappedComponent: ComponentType<P>):ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractParams = (props: any): JSX.Element => {
        const wgId = props.wg && props.wg.id;
        const params: QueryParams = {
            query: `type: ${type} AND /context:"${wgId}"`,
            pageNum: -1,
            pageSize: -1,
            sortFields: getSortFields(type)
        };
        return <WrappedComponent fetchProps={params} {...props} />;
    };
    return ExtractParams;
};
export default extractParams;
