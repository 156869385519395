/* eslint-disable @typescript-eslint/no-floating-promises */
export function unregister(): void {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
        .then(registration => {
            registration.unregister();
        }).catch(error => {
            console.error(error.message);
        });
        navigator.serviceWorker.getRegistrations().then(registrations => {
            for (const registration of registrations) {
                registration.unregister();
            }
        }).catch(error => {
            console.error(error.message);
        });
    }
}
