import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import { CordraObject } from "@cnri/cordra-client";
import { handleError } from "../util";
import Form from "../common/components/Form";
import { CoordinationGroup, Delegates1 } from "../../models/CoordinationGroup";

import { DataFetchingState } from "../common/hoc/WithDataFetching";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

interface Props extends DataFetchingState<CordraObject> {
    injected?: boolean;
}

interface CustomDelegate {
    suggestValue: string;
    selectValue: string;
}

interface ViewModel {
    id: string;
    name: string;
    description: string;
    descriptionFormatted: string;
    chair: string;
    chairDelegateOf: string;
    viceChair: string;
    viceChairDelegateOf: string;
    delegates: CustomDelegate[];
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {

    schema = {
        id: Joi.string()
            .allow(""),
        name: Joi.string()
            .required()
            .min(1)
            .label("Subject"),
        description: Joi.string()
            .required()
            .min(1)
            .label("rawText"),
        descriptionFormatted: Joi.string()
            .required()
            .min(1)
            .label("Description"),
        chair: Joi.string()
            .required()
            .min(1)
            .label("Chair"),
        chairDelegateOf: Joi.string()
            .required()
            .min(1)
            .label("Delegate Of"),
        viceChair: Joi.string()
            .allow("")
            .label("Vice Chair"),
        viceChairDelegateOf: Joi.string()
            .allow("")
            .label("Delegate Of"),
        delegates: Joi.array()
            .items(Joi.object()
                .keys({ suggestValue: Joi.string(), selectValue: Joi.string() }))
            .label("Delegates")
    };

    state: State = {
        data: {
            id: "",
            name: "",
            description: "",
            descriptionFormatted: "",
            chair: "",
            chairDelegateOf: "",
            viceChair: "",
            viceChairDelegateOf: "",
            delegates: []
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };


    mapToViewModel = (data: CordraObject): void => {
        const { content } = data;
        const chair = content.delegates.filter((member: Delegates1) => member.title === "Chair")[0];
        const viceChair = content.delegates.filter((member: Delegates1) => member.title === "Vice Chair")[0];

        const delegates: CustomDelegate[] = content.delegates.filter((member: Delegates1) => member.title === "Delegate")
            .map((item: Delegates1) => {
                const memberId = item.member;
                const delegateOf = item.delegateOf;
                return { suggestValue: memberId, selectValue: delegateOf };
            });
        this.setState({
            data: {
                id: content.id,
                name: content.name,
                description: content.description || "",
                descriptionFormatted: content.descriptionFormatted || "",
                chair: chair.member,
                chairDelegateOf: chair.delegateOf,
                viceChair: (viceChair && viceChair.member) || "",
                viceChairDelegateOf: (viceChair && viceChair.delegateOf) || "",
                delegates: delegates || []
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const dataModel: CoordinationGroup = { ...viewModel, delegates: [] };
        const consolidatedDelegates: Delegates1[] = [];
        viewModel.chair && consolidatedDelegates.push({ member: viewModel.chair, title: "Chair", delegateOf: viewModel.chairDelegateOf });
        viewModel.viceChair && consolidatedDelegates.push({
            member: viewModel.viceChair && viewModel.viceChair,
            title: "Vice Chair",
            delegateOf: viewModel.viceChair && viewModel.viceChairDelegateOf
        });
        viewModel.delegates.map((member: CustomDelegate) => {
            return consolidatedDelegates.push({ member: member.suggestValue, title: "Delegate", delegateOf: member.selectValue });
        });
        dataModel.delegates = consolidatedDelegates;

        delete dataModel.chair && delete dataModel.viceChair && delete dataModel.chairDelegateOf && delete dataModel.viceChairDelegateOf;

        const data = {
            id: dataModel.id,
            type: "CoordinationGroup",
            content: dataModel
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data)
                .then(({ id }) => {
                    this.setState({
                        saved: true,
                        data: {
                            id
                        }
                    });
                })
                .catch(response => {
                    this.setState({
                        saveOngoing: false
                    }, () => {
                        handleError(response);
                        this.mapToViewModel(data);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;
        return saved ?
            (<Redirect to={`/cg`} />) :
            this.state.mapped || !waitUntilMapped ?
                (<div>
                    <h1>{
                        shouldFetch
                            ? "Edit"
                            : "New"
                    }
                        Coordinating Group</h1>
                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput("name", "Name")}
                        {this.renderRichTextEditor("description", "Description")}
                        <div className="row" key="chair">
                            <div className="col-6">
                                {this.renderAutoSuggest("chair", "Chair", ["Member"], false)}
                            </div>
                            <div className="col-6">
                                {this.renderAutoSuggest("chairDelegateOf", "Delegate Of", ["GHRProvider"], false)}
                            </div>
                        </div>
                        <div className="row" key="viceChair">
                            <div className="col-6">
                                {this.renderAutoSuggest("viceChair", "Vice Chair", ["Member"], false)}
                            </div>
                            <div className="col-6">
                                {this.renderAutoSuggest("viceChairDelegateOf", "Delegate Of", ["GHRProvider"], false)}
                            </div>
                        </div>
                        {this.renderAutoSuggestArray("delegates", "Delegates", "Delegate Of", ["Member"], ["GHRProvider"])}
                        <div className="p-3" />
                        {saveOngoing
                            ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                            : this.renderButton("Save", "fa fa-save")}
                        <span className="m-2" />
                        <ButtonWithBackContext label="Discard Changes" enable={true} />
                        <div className="m-2" />
                    </form>
                </div>) :
                <React.Fragment></React.Fragment>;
    };
}

export default NewOrEdit;
