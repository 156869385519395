import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../AuthStore";


const Profile = (): JSX.Element => {
    const authContext = useContext(AuthContext);
    const { loggedIn, user } = authContext;
    return (loggedIn ?
        <div className="row float-right">
            <div className="text-white col-md-12 text-end">
                <Link to={`/participants/${user && user.id}`} className="text-white">
                    Hi {user && user.firstName}{" "}{user && user.lastName}
                </Link>
                <Link to="/logout" className="text-white float-right ml-2">
                    <span className="text-right ml-1">
                        <i className="fa fa-sign-out" />
                    </span>
                    <span className="text-right">Logout</span>
                </Link>
            </div>
            <div className="text-white col-md-12">
                {user && user.donaEmail ? <React.Fragment><a href="https://imap.dona.net/" target="_blank" rel="noopener noreferrer" className="text-white small text-left">
                    <span className="btn-label ml-1">
                        <i className="fa fa-envelope" />
                    </span>
                    <span className="ml-1 font-bold">DONA Email</span>
                </a><Link to="/email" className="text-white small text-left">
                    <span className="ml-1 text-small">(Configure)</span>
                </Link></React.Fragment> : ("")}
                <Link to={user && user.isGuest ? "/get-started-guest" : "/get-started"} className="text-white float-right">
                    <span className="text-right ml-2">
                        <i className="fa fa-bell" />
                    </span>
                    <span className="ml-1">Get Started</span>
                </Link>
            </div>
        </div> : <React.Fragment></React.Fragment>);
};

export default Profile;
