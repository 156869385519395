/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import { CordraObject, Metadata } from "@cnri/cordra-client";
import ShowIfAuthorized from "../ShowIfAuthorized";
import TitleContainer from "../Title/Container";
import { constructName } from ".";
import { Member } from "../../models/Member";

interface Props {
    setActivationStatus: (data: CordraObject) => Promise<void>;
    data: {
        content: Member;
        metadata: Metadata;
    };
}

const Detailed = (props: Props): JSX.Element => {

    const { setActivationStatus, data: { metadata, content: member } } = props;
    return (<React.Fragment>
        <div className="detailed">
            <div className="details">
                <h1>{constructName(member)}</h1>
                <footer className="detailed-footer d-flex align-items-center">
                    <div className="title pr-2">
                        <i className="fa fa-envelope" />
                        <span className="text ml-2">{member.emailAddress}</span>
                    </div>
                    <div className="title pl-2 pr-2">
                        <i className="fa fa-building" />
                        <span className="text ml-2">
                            { member.organization && <TitleContainer fetchProps={{ id: member.organization }} /> }
                        </span>
                    </div>
                    <div className="title pl-2 pr-2">
                        <i className="fa fa-calendar" />
                        <span className="text ml-2">
                            {metadata && metadata.createdOn && moment(new Date(metadata.createdOn)).format("MMMM Do, YYYY")}
                        </span>
                    </div>
                    <ShowIfAuthorized type="Member" action="fullView" data={member}>
                        <div className="title title-last pl-2 pr-2">
                            <i className="fa fa-user" />
                            <span className="text pl-2 pr-2">
                                {
                                    member.admin
                                        ? "Administrator"
                                        : (member.guest && "Guest" || "Regular")
                                }
                            </span>
                        </div>
                    </ShowIfAuthorized>
                </footer>
                <div className="border-bottom border-grey pb-3" />
                <div className="body">
                    <ShowIfAuthorized type="Member" action="fullView" data={member}>
                        <span className="d-flex pl-2 pr-2 align-items-center">
                            <div className="title pr-2">
                                <span className="text mr-4">Currently {member.isActiveModsPerspective && member.isActiveModsPerspective ? "Active" : "Inactive"}</span>
                                {member.isActiveModsPerspective ?
                                    <span onClick={(event) => {
                                        updateMemberStatus(member, false, setActivationStatus);
                                    }} className="text text-underline text-dark cursor-pointer">
                                        Deactivate
                                    </span>
                                    : <span onClick={event => { updateMemberStatus(member, true, setActivationStatus); }} className="text text-underline text-dark cursor-pointer">
                                        Activate
                                    </span>
                                }
                            </div>
                        </span>
                    </ShowIfAuthorized>
                </div>
            </div>
        </div>
    </React.Fragment>);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateMemberStatus = (member: Member, isActive: boolean, setActivationStatus: (data: CordraObject)=>Promise<void>): any => {
    member.isActive = isActive;
    member.isActiveModsPerspective = isActive;
    const data = {
        id: member.id,
        type: "Member",
        content: member
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return setActivationStatus(data).then((response: any) => {
        return response;
    });
};

export default Detailed;
