import React from "react";
import { Link } from "react-router-dom";

const PrivacyStatement: React.ComponentType = (): JSX.Element => {
    return (<div className="container">
        <div className="row justify-content-center">
            <div className="col-md-12">
                <header className="header mb-4">
                    <h4>Privacy Statement</h4>
                </header>
                <div className="mb-4 lead">
                    The DONA Foundation (DONA) is committed to protecting the privacy of visitors to the{" "}
                    <Link to="/">mods.dona.net</Link>{" "}
                    website. Information at the mods.dona.net website is made available only to authorized users who request access to such information.
                </div>

                <div className="mb-4 lead">
                    Our system logs collect and store IP addresses, dates and times of visits, the pages visited, and types of browsers used, but we retain this information only temporarily, and we do not sell or market it, or disclose it publicly. Data from the logs may be used to quantify the number of visitors to the site. Cookies are used by the website for purposes of enabling login sessions to avoid authentication for every page request.
                </div>

                <div className="mb-4 lead">
                    DONA does not control the privacy policies of any other sites to which we provide hyperlinks. Disclosing personal information on sites hyperlinked to or from the{" "}
                    <Link to="/">mods.dona.net</Link>{" "}
                    website would be done at your own risk.
                </div>

                <div className="mb-4 lead">
                    Any information that you voluntarily provide to DONA in an email addressed to a contact person listed at the website may be retained by DONA in order to best answer your query.
                </div>
            </div>
        </div>
    </div>);
};

export default PrivacyStatement;
