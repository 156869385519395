/* eslint-disable @typescript-eslint/no-shadow */
import React, { Component } from "react";
import _ from "lodash";
import Select, { OnChangeValue } from "react-select";
import { OptionType } from "./interfaces";

interface Props {
    name: string;
    selectedOption: OptionType;
    options: Array<OptionType>;
    onChange: ({ currentTarget }: { currentTarget: { name: string; value: string } }) => void;
    label?: string;
    required?: boolean;
    isDisabled?: boolean;
    error?: string;
    className?: string;
    multiple?: boolean;
}

interface State {
    selectedOption: OptionType;
    options: Array<OptionType>;
}

class EnrichedSelect extends Component<Props, State> {
    state: State = {
        selectedOption: { value: "", label: "" },
        options: []
    };

    componentDidMount = (): void => {
        const {
            selectedOption, options
        } = this.props;
        this.setState({ selectedOption, options });
    };

    componentDidUpdate = (prevProps: Props): void => {
        const {
            selectedOption,
            options
        } = this.props;
        if ((prevProps.selectedOption.value !== selectedOption.value) || (!_.isEqual(prevProps.options, options))) {
            this.setState({ selectedOption, options });
        }
    };

    handleChange = (selectedOption: OptionType): void => {
        const {
            name,
            onChange
        } = this.props;

        this.setState({ selectedOption }, () => {
            const currentTarget = {
                name,
                value: selectedOption.value
            };
            onChange && onChange({ currentTarget });
        });
    };

    render = (): JSX.Element => {
        const {
            label,
            name,
            required,
            error,
            className,
            isDisabled
        } = this.props;

        const {
            selectedOption,
            options
        } = this.state;

        return (<React.Fragment>
            <div className="form-group">
                {
                    label && (<label htmlFor={name} className="text-small no-padding">
                        {label}
                        {required && <span className="required">*</span>}
                    </label>)
                }
                <Select name={name} value={selectedOption} onChange={(selectedOption: OnChangeValue<OptionType, false>) => this.handleChange((selectedOption as OptionType))}
                    options={options} isDisabled={isDisabled} className={className} classNamePrefix="react-select" theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#dbdbdb',
                            primary: '#888888'
                        }
                    })
                    } /> {error && <div className="alert alert-danger">{error}</div>}
            </div>
        </React.Fragment>);
    };
}

export default EnrichedSelect;
