import React from "react";
import { Redirect, Link } from "react-router-dom";
import Joi from "@hapi/joi";
import { CordraObject, Payload } from "@cnri/cordra-client";
import ShowIfAuthorized from "../ShowIfAuthorized";
import { handleError } from "../util";
import Form from "../common/components/Form";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import { Menu, Post } from "../../models/Post";
import { AuthContext } from "../AuthStore";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

const ACCEPTED_FORMATS = [
    "text/plain",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/zip",
    "application/gzip"
];
const ACCEPTED_PAYLOAD_NAMES: string[] = [];
const ACCEPTED_PAYLOAD_TYPES: string[] = [];
const MAX_PAYLOAD_SIZE = 250 * 1024 * 1024;

interface Props extends DataFetchingState<CordraObject> {
    injected: boolean;
}

interface ViewModel {
    id: string;
    subject: string;
    bodyFormatted: string;
    body: string;
    from: string;
    to: string[];
    menuName: string;
    subMenuName: string;
    payloads: { acceptedPayloads?: Payload[]; deletedPayloads?: string[]; previouslyAcceptedPayloads: Payload[] };
    editors: string[];
    checkACLOfLinkedDOs: boolean;
    creationDate?: string;
    modificationDate?: string;
    tags: string[];
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {
    schema = {
        id: Joi.string()
            .allow("")
            .allow(null),
        subject: Joi.string()
            .required()
            .min(1)
            .label("Subject"),
        body: Joi.string()
            .required()
            .min(1)
            .label("rawText"),
        bodyFormatted: Joi.string()
            .required()
            .min(1)
            .label("Message"),
        from: Joi.string()
            .allow("")
            .allow(null),
        to: Joi.array()
            .items(Joi.string()
                .required())
            .min(1)
            .unique()
            .required()
            .label("To"),
        menuName: Joi.string()
            .allow("")
            .allow(null)
            .label("Menu Name"),
        subMenuName: Joi.string()
            .allow("")
            .allow(null)
            .label("Sub Menu Name"),
        editors: Joi.array()
            .items(Joi.string()
                .allow("")
                .allow(null))
            .label("Authorized Editors"),
        payloads: Joi.object()
            .allow(null)
            .label("Payloads"),
        checkACLOfLinkedDOs: Joi.boolean()
            .label("Check ACL Linked Posts"),
        tags: Joi.array()
            .items(Joi.string()
                .allow("")
                .allow(null))
            .min(0)
            .label("Tags")
    };

    state: State = {
        data: {
            id: "",
            subject: "",
            bodyFormatted: "",
            body: "",
            from: "",
            to: [],
            menuName: "",
            subMenuName: "",
            payloads: { previouslyAcceptedPayloads: [] },
            editors: [],
            checkACLOfLinkedDOs: true,
            tags: []
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };


    mapToViewModel = (data: CordraObject): void => {
        const { content, payloads } = data;

        this.setState({
            data: {
                id: content.id || "",
                subject: content.subject || "",
                body: content.body || "",
                bodyFormatted: content.bodyFormatted || "",
                from: content.from,
                to: content.to,
                editors: content.editors || [],
                menuName: content.menu && content.menu.menuName && content.menu.menuName,
                subMenuName: content.menu && content.menu.subMenuName && content.menu.subMenuName,
                payloads: { previouslyAcceptedPayloads: payloads },
                checkACLOfLinkedDOs: content.checkACLOfLinkedDOs || true,
                tags: content.tags
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const dataModel: Post = { ...viewModel, creationDate: "", modificationDate: "" };
        if (viewModel.menuName) {
            const menu: Menu = {
                menuName: viewModel.menuName,
                subMenuName: viewModel.subMenuName && viewModel.subMenuName
            };
            dataModel.menu = menu;

            delete dataModel.menuName && delete dataModel.subMenuName;
        }

        const data: CordraObject = {
            id: dataModel.id,
            type: "Post",
            content: dataModel,
            payloads: viewModel.payloads &&
                viewModel.payloads.acceptedPayloads &&
                viewModel.payloads.acceptedPayloads,
            payloadsToDelete: viewModel.payloads &&
                viewModel.payloads.deletedPayloads &&
                viewModel.payloads.deletedPayloads
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({ saveOngoing: true }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data, this.props.data)
                .then((response) => {
                    this.setState({ saved: true, data: { id: response.id } });
                })
                .catch(response => {
                    this.setState({ saveOngoing: false }, () => {
                        handleError(response);
                        this.mapToViewModel(data);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, data, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;

        return saved ? (
            <Redirect to={`/posts/${data.id}`} />
        ) : this.state.mapped || !waitUntilMapped ? (
            <AuthContext.Consumer>
                { authStore => (
                    <div>
                        <h1>{shouldFetch ? "Edit" : "New"} Post</h1>

                        <form onSubmit={this.handleSubmit}>
                            { authStore.user && authStore.user.isGuest ?
                                this.renderAutoSuggest("to", "Recipients",
                                    [ "WorkingGroup" ],
                                    true)
                                :
                                this.renderAutoSuggest("to", "Recipients",
                                    [ "GHRProvider",
                                        "ARSProvider",
                                        "CoordinationGroup",
                                        "Board",
                                        "WorkingGroup",
                                        "Group" ],
                                    true)

                            }

                            {this.renderInput("subject", "Subject")}
                            {this.renderRichTextEditor("body", "Message")}
                            {this.renderCheckBox("checkACLOfLinkedDOs", "Check if recipients can read linked posts and activities")}
                            <div className="m-2" />

                            {this.renderPayloadZone("payloads", "Payloads", ACCEPTED_FORMATS, 3, ACCEPTED_PAYLOAD_NAMES, ACCEPTED_PAYLOAD_TYPES, MAX_PAYLOAD_SIZE)}
                            {this.renderTagSet("tags", "Tags", true)}
                            <div className="m-4" />
                            <div className="border-top">

                                <ShowIfAuthorized type="Post" action="fullEdit">
                                    <h5 className="title mb-2 mt-2 ">Admin Section</h5>
                                    <div className="bg-light p-3">
                                        {this.renderSelectWithCustomInput("menuName", "Menu Name", [
                                            "Announcements",
                                            "Board-Activities",
                                            "Board-Documents",
                                            "DONA-CG",
                                            "DONA-Internal",
                                            "Documents",
                                            "Software" ])}
                                        {this.renderSelectWithCustomInput("subMenuName", "Sub Menu Name", [
                                            "Policies",
                                            "Board Minutes",
                                            "Specifications",
                                            "License and Agreements",
                                            "GHR Software",
                                            "DFHS Software",
                                            "Trademark",
                                            "Foundation",
                                            "Resolution"]
                                        )}
                                    </div>
                                    {this.renderAutoSuggest("editors", "Authorized Editors", [ "Member" ], true)}
                                </ShowIfAuthorized>
                            </div>
                            <div className="m-3" />
                            {saveOngoing
                                ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                                : this.renderButton("Save", "fa fa-save")}
                            <span className="m-2" />
                            <ButtonWithBackContext label="Discard Changes" enable={true} />
                            <div className="m-2" />
                            <span className="text">
                                By voluntarily posting or otherwise sharing information with visitors to this MODS site, you agree with the{" "}
                                <Link to="/terms-and-conditions">Terms and Conditions</Link> laid out by the DONA Foundation.
                            </span>

                            <div className="m-2" />

                        </form>
                    </div>
                )}
            </AuthContext.Consumer>) : <React.Fragment></React.Fragment>;
    };

}

export default NewOrEdit;
