import React from "react";
import { Link } from "react-router-dom";
import { getPathFromType } from "../util";

export interface MenuItem {
    menuName: string;
    subMenuList: Array<SubMenuItem>;
}
export interface SubMenuItem {
    subMenuName: string;
    entryList: Array<PostMenu>;
}

export interface PostMenu {
    id: string;
    title: string;
    type: string;
    menuName: string;
    subMenuName: string;
}

export interface ConfigurableMenuProps {
    data: Array<MenuItem>;
}

const ConfigurableMenu = (props: ConfigurableMenuProps): JSX.Element => {
    const { data: menuList } = props;
    return (
        <React.Fragment>
            {menuList && menuList.map((menuEntry, idx) => (
                <li
                    className="nav-item dropdown"
                    key={`${menuEntry.menuName}${idx}`}
                    id={`${menuEntry.menuName}${idx}`}
                >
                    <a
                        className="nav-link dropdown-toggle"
                        id={`${menuEntry.menuName}${idx}`}
                        key={`${menuEntry.menuName}${idx}`}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        href="#top"
                    >
                        {menuEntry.menuName}
                    </a>
                    <ul
                        className="dropdown-menu"
                        aria-labelledby="adminDropDown"
                        id={`${menuEntry.menuName}${idx}`}
                    >
                        {menuEntry.subMenuList.map((subMenuEntry, jdx) => (
                            <li
                                className="dropdown-submenu"
                                key={`${subMenuEntry.subMenuName}${jdx}`}
                                id={`${subMenuEntry.subMenuName}${jdx}`}
                            >

                                <Link
                                    className="dropdown-item"
                                    to={
                                        subMenuEntry.entryList.length === 1 ?
                                            "/" + getPathFromType(subMenuEntry.entryList[0].type) + "/" + subMenuEntry.entryList[0].id :
                                            `/posts?query=/menu/subMenuName:"${subMenuEntry.subMenuName}" AND /menu/menuName:"${menuEntry.menuName}"`
                                    }
                                    id={`${subMenuEntry.subMenuName}${jdx}`}
                                    key={`${subMenuEntry.subMenuName}${jdx}`}
                                >
                                    {subMenuEntry.subMenuName}
                                </Link>

                                {
                                    subMenuEntry.entryList.length === 1 ?
                                        "" :
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="adminSubDropDown"
                                        >
                                            {subMenuEntry.entryList.map((entry, kdx) => (
                                                <Link
                                                    className="dropdown-item"
                                                    to={
                                                        "/" + getPathFromType(entry.type) + "/" + entry.id
                                                    }
                                                    key={kdx}
                                                >
                                                    {entry.title}
                                                </Link>
                                            ))}
                                        </ul>
                                }
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
        </React.Fragment>
    );
};
export default ConfigurableMenu;
