import React from "react";
import { Link } from "react-router-dom";
import RenderContent from "../InlineIconText";
import { Group as GroupType } from "../../models/Group";


interface Props {
    data: {
        content: GroupType;
    };
}

const Group = (props: Props): JSX.Element => {
    const { data: { content: group } } = props;
    return (<div className="search-result row d-flex align-items-stretch border border-1">
        <div className="text col-lg-12">
            <div className="text-inner">
                <div>
                    <header className="header">
                        <div className="search-result-title">
                            <h5>
                                <Link to={`/groups/${group.id}`} className="active">
                                    {group.groupName}
                                </Link>
                            </h5>
                        </div>
                    </header>
                    <p>{group.description || ""}</p>
                    <footer className="search-result-footer d-flex justify-content-start flex-wrap">
                        <div className="title title-last">
                            {
                                RenderContent("fa fa-user", `${group.members.length} members`)
                            }
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>);
};

export default Group;
