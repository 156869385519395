/* eslint-disable react/prop-types */
import React, { ComponentType } from "react";
import { useParams } from "react-router-dom";

// TODO: catch error if id is not in the router params
const extractId = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractId = (props: any): JSX.Element => {
        const params: { prefix: string; id: string } = useParams();
        const id = `${params.prefix}/${params.id}`;
        return <WrappedComponent {...props} fetchProps={{
            id
        }} />;
    };
    return ExtractId;
};
export default extractId;
