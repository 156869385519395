import { compose } from "redux";

import NewActivity from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import { create } from "../../services/MODSService";
import extractContext from "../ExtractContext";

export default compose(
    extractContext(),
    withContainer({
        dataParams: {
            saveHandler: create
        },
        Error,
        DefaultComponent: Default
    })
)(NewActivity);
