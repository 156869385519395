import { compose } from "redux";

import { CordraObject } from "@cnri/cordra-client";
import NewWorkingGroup from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";

import { createWG } from "../../services/MODSService";
import { DataFetchingState } from "../common/hoc/WithDataFetching";

export default compose(
    withContainer<DataFetchingState<CordraObject>, CordraObject>({
        dataParams: {
            saveHandler: createWG
        },
        Error,
        DefaultComponent: Default
    })
)(NewWorkingGroup);
