import React from "react";
import moment from "moment";
import ShowIfAuthorized from "../ShowIfAuthorized";
import TitleContainer from "../Title/Container";
import ProviderPostContainer from "../ProviderPost/QueryContainer";
import { ARSProvider, Member } from "../../models/ARSProvider";

interface Props {
    data: {
        content: ARSProvider;
    };
}

const Detailed = (props: Props): JSX.Element => {
    const { data: { content: ars } } = props;
    const isDeactivated = ars?.isDeactivated || false;
    return (<div className={`detailed ${isDeactivated ? "detailed-deactivated" : ""}`}>
        <div className="details">
            <h1>{ars.name}</h1>
            <footer className="detailed-footer d-flex align-items-center">
                <div className="title pl-2 pr-2">
                    <i className="fa fa-gears" />
                    <span className="text ml-2">
                        <TitleContainer key={ars.ghrProviderId} fetchProps={{
                            id: ars.ghrProviderId
                        }} />
                    </span>
                </div>
                <div className="title pl-2 pr-2">
                    <React.Fragment>
                        { ars.organizations.map((organization: string, index: number) => (<div className="title-last pr-2" key={index}>
                            <i className="fa fa-building" />
                            <span key={organization} className="text ml-2">
                                <TitleContainer key={organization} fetchProps={{
                                    id: organization
                                }} />
                            </span>
                        </div>))
                        }
                    </React.Fragment>
                </div>
                <div className={`title pl-2 pr-2 ${!isDeactivated ? "title-last" : ""}`}>
                    <i className="fa fa-calendar" />
                    <span className="text ml-2">
                        {moment(new Date(ars.memberSince)).format("MMMM Do, YYYY")}
                    </span>
                </div>
                {isDeactivated && <div className="title title-last pl-2 pr-2">
                    <i className="fa fa-ban" />
                    <span className="text ml-2">
                        Inactive
                    </span>
                </div>}
            </footer>
            <div className="border-bottom border-grey pb-3" />
            <div className="body mt-4 mb-4 pb-4">
                <h4 className="header mb-3">Contacts</h4>
                { ars.members?.filter((member: Member) => member.title === "System Admin").map(sysAdmin => (<div className="media mt-1" key={sysAdmin.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title">
                                <TitleContainer key={sysAdmin.member} fetchProps={{
                                    id: sysAdmin.member
                                }} />
                                <span className="title ml-2">({sysAdmin.title})</span>
                            </span>
                        </div>
                    </div>
                </div>))
                }
                { ars.members?.filter((member: Member) => member.title !== "System Admin").map(staff => (<div className="media mt-1" key={staff.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title">
                                <TitleContainer key={staff.member} fetchProps={{
                                    id: staff.member
                                }} />
                                <span className="title ml-2">({staff.title})</span>
                            </span>
                        </div>
                    </div>
                </div>))
                }
            </div>
            <ShowIfAuthorized type="ProviderPost" action="view" data={ars}>
                <ProviderPostContainer arsProviderId={{ id: ars.id }} />
            </ShowIfAuthorized>
        </div>
    </div>);
};

export default Detailed;
