import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Error = (props: any): JSX.Element => {
    return (
        <React.Fragment>
            <span className="mb-auto">Not available</span>
        </React.Fragment>);
};

export default Error;
