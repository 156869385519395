import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Payload } from "@cnri/cordra-client";
import RenderContent from "../InlineIconText";
import TitleContainer from "../Title/Container";
import { Post as PostType } from "../../models/Post";

interface Props {
    data: {
        content: PostType;
        payloads: Array<Payload>;
    };
}

const Post = (props: Props): JSX.Element => {
    const { data: { content: post, payloads } } = props;
    return (<div className="search-result row d-flex align-items-stretch border border-1">
        <div className="text col-lg-12">
            <div className="text-inner">
                <div>
                    <header className="header">
                        <div className="search-result-title">
                            <h5>
                                <Link to={`/posts/${post.id}`} className="active">
                                    {post.subject}
                                </Link>
                            </h5>
                        </div>
                    </header>
                    <p>{post.body}</p>
                    <footer className="search-result-footer d-flex justify-content-start flex-wrap">
                        <div className="title">
                            {
                                RenderContent("fa fa-user", <TitleContainer fetchProps={{ id: post.from }} />)
                            }
                        </div>
                        <div className={payloads
                            ? "title"
                            : "title title-last"}>
                            {RenderContent("fa fa-calendar", moment(new Date(post.modificationDate)).format("MMMM Do, YYYY - h:mm:ss A"))}
                        </div>
                        {
                            payloads && (<div className="title title-last">
                                {
                                    RenderContent(
                                        "fa fa-file-o", `${payloads.length} ${payloads.length > 1
                                            ? "Payloads"
                                            : "Payload"}`)
                                }
                            </div>)
                        }
                    </footer>
                    {post.tags && post.tags.length > 0 && <footer className="search-result-footer d-flex justify-content-start flex-wrap">
                        <div className="title title-last">
                            <span className="d-inline-block float-left"><i className="fa fa-tag" /></span>
                            {post.tags.map((tag, idx) => {
                                const searchTagTarget = `/posts/?query=/tags/_:"${tag}"`;
                                return (
                                    <div className="d-inline-block mr-2" key={idx}><Link to={searchTagTarget}>
                                        <span className="pl-2 text">{tag}</span>
                                    </Link></div>);
                            })}
                        </div>
                    </footer>}
                </div>
            </div>
        </div>
    </div>);
};

export default Post;
