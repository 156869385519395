import React, { ComponentType } from "react";
import { QueryParams } from "../interfaces";

const extractQueryParams = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractQueryParams = (props: any): JSX.Element => {
        // eslint-disable-next-line react/prop-types
        const { types } = props;

        // TODO: use reduce function instead to build the query?
        const typeQueryFragment = types.map((type: string, index: number) => {
            // eslint-disable-next-line react/prop-types
            return index === types.length - 1 ?
                `type: ${type}` :
                `type: ${type} OR`;
        });
        const params: QueryParams = {
            query: typeQueryFragment.join(" "),
            pageNum: 0,
            pageSize: -1
        };

        // TODO: address sort fields

        return <WrappedComponent fetchProps={params} {...props} />;
    };
    return ExtractQueryParams;
};
export default extractQueryParams;
