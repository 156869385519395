/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import TitleContainer from "../Title/Container";
import ActivitiesContainer from "../Activity/QueryContainer";
import RichTextEditor from "../RichTextEditor/";
import { WorkingGroup } from "../../models/WorkingGroup";

interface Props {
    data: {
        content: WorkingGroup;
    };
}

const Detailed = (props: Props): JSX.Element => {
    const { data: { content: wg } } = props;
    const chairs = wg.members.filter(member => member.title === "Chair");
    return (<div className="detailed">
        <div className="details">
            <h1>{wg.name}</h1>
            <footer className="detailed-footer d-flex align-items-center">
                <div className="title pl-2 pr-2">
                    {
                        chairs && chairs.length > 0
                            ? (<React.Fragment>
                                {
                                    chairs.map((chair, index) => (<div className="title-last pr-2" key={index}>
                                        <i className="fa fa-address-book" />
                                        <span key={chair.member} className="text ml-2">
                                            <TitleContainer key={chair.member} fetchProps={{
                                                id: chair.member
                                            }} />
                                        </span>
                                    </div>))
                                }
                            </React.Fragment>)
                            : ("")
                    }
                </div>
                <div className={`${wg.members
                    ? "title"
                    : "title title-last"} pl-2 pr-2`}>
                    <i className="fa fa-calendar" />
                    <span className="text ml-2">
                        {moment(new Date(wg.timeline.startDate)).format("MMMM Do, YYYY")}
                    </span>
                </div>
                {
                    wg.members && (<div className={`${
                        wg.status
                            ? "title"
                            : "title title-last"} pl-2 pr-2`}>
                        <i className="fa fa-user" />
                        <span className="text ml-2">
                            {
                                `${wg.members.length} ${
                                    wg.members.length > 1
                                        ? "Participants"
                                        : "Participant"}`
                            }
                        </span>
                    </div>)
                }
                {
                    wg.status && (<div className="title title-last pl-2 pr-2">
                        <i className="fa fa-comment" />
                        <span className="text ml-2">
                            {wg.status}
                        </span>
                    </div>)
                }
            </footer>
            <div className="border-bottom border-grey pb-3" />
            <div className="body mt-4 mb-4 pb-4">
                {wg.objectiveFormatted && <RichTextEditor value={wg.objectiveFormatted} readMode={true} key={wg.id} />}
                {!wg.objectiveFormatted && <p>{wg.objective}</p>}
            </div>
            {
                wg.members && (<div className="mt-4 mb-4 pb-4">
                    <h4 className="header mb-3">Participants</h4>
                    {
                        wg.members.filter(member => member.title === "Chair").map(chair => (<div className="media mt-1" key={chair.member}>
                            <div className="media-body">
                                <div className="main pull-left">
                                    <span className="title">
                                        <TitleContainer key={chair.member} fetchProps={{
                                            id: chair.member
                                        }} />
                                        <span className="title ml-2">(Lead)</span>
                                    </span>
                                </div>
                            </div>
                        </div>))
                    }
                    {
                        wg.members.filter(member => member.title !== "Chair").map(participant => (<div className="media mt-1" key={participant.member}>
                            <div className="media-body">
                                <div className="main pull-left">
                                    <span className="title">
                                        <TitleContainer key={participant.member} fetchProps={{
                                            id: participant.member
                                        }} />
                                    </span>
                                </div>
                            </div>
                        </div>))
                    }
                </div>)
            }
            <ActivitiesContainer wg={{ id: wg.id }} />
        </div>
    </div>);
};

export default Detailed;
