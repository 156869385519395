export interface EventHandler<T> {
    (event: { currentTarget: { name: string; value: T; error?: string } }): void;
}

export interface OptionType {
    value: string;
    label: string;
}

export interface Address {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    countryName?: string;
    country?: string;
}

export interface AddressLabels {
    line1?: string;
    line2?: string;
    city?: string;
    state?: { label: string; values?: Array<string> };
    postalCode?: string;
    countryName?: string;
    country?: string;
}

export interface Country {
    countryName: string;
    country: string;
}

export const createOption = (label: string): { label: string; value: string } => ({
    label,
    value: label.toLowerCase().replace(/\W/g, '')
});
