import { compose } from "redux";

import EditWorkingGroup from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import extractId from "../ExtractId";

import { getObjectById, updateWG } from "../../services/MODSService";

export default compose(
    extractId(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById,
            saveHandler: updateWG
        },
        defaultLoadingMessage: "Retrieving Working Group",
        Error,
        DefaultComponent: Default
    })
)(EditWorkingGroup);
