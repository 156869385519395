/* eslint-disable react/prop-types */
import React from "react";
import Payload from "./Payload";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Payloads = (props: any): JSX.Element => {
    const { id, payloads, isViewMode, deleteHandler, isMinimal } = props;
    return (<React.Fragment>
        {
            payloads && payloads.length > 0
                ? (<div>
                    {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        payloads.map((payload: any) => {
                            return (<Payload key={payload.name} id={id} payload={payload} isViewMode={isViewMode} deleteHandler={deleteHandler} isMinimal={isMinimal} />);
                        })
                    }
                </div>)
                : ("")
        }
    </React.Fragment>);
};

export default Payloads;
