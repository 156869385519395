/* eslint-disable react/prop-types */
import React from "react";
import TitleContainer from "../Title/Container";
import RichTextEditor from "../RichTextEditor/";
import { Group } from "../../models/Group";

interface Props {
    data: {
        content: Group;
    };
}

const Detailed = (props: Props): JSX.Element => {
    const { data: { content: group } } = props;
    return (<div className="detailed">
        <div className="details">
            <h1>{group.groupName}</h1>
            <footer className="detailed-footer d-flex align-items-center">
                <div className="title pr-2">
                    <i className="fa fa-envelope" />
                    <span className="text ml-2">
                        {group.members.length} Members
                    </span>
                </div>
            </footer>
            <div className="border-bottom border-grey pb-3" />
            <div className="body mt-4 mb-4 pb-4">
                {group.descriptionFormatted && <RichTextEditor value={group.descriptionFormatted || ""} readMode={true} key={group.id} />}
                {!group.descriptionFormatted && <p>{group.description}</p>}
            </div>
            { group.members && (<div className="mt-4 mb-4 pb-4">
                <h4 className="header mb-3">Group Members</h4>
                { group.members.map(member => (<div className="media mt-1" key={member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title">
                                <TitleContainer key={member} fetchProps={{
                                    id: member
                                }} />
                            </span>
                        </div>
                    </div>
                </div>))
                }
            </div>)
            }
        </div>
    </div>);
};

export default Detailed;
