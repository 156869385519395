import React, { useContext, ComponentType } from "react";
import { AuthContext } from "./AuthStore";

const injectAuthStoreAsProps = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const InjectAuthStoreAsProps = (props: any): JSX.Element => {
        const authStore = useContext(AuthContext);
        return (<React.Fragment>
            <WrappedComponent {...props} authStore={authStore} />
        </React.Fragment>);
    };
    return InjectAuthStoreAsProps;
};
export default injectAuthStoreAsProps;
