import { CordraObject } from "@cnri/cordra-client";
import React from "react";
import { Link } from "react-router-dom";
import { isSupportedType, getPathFromType, getTitleFromType } from "../util";

interface Props {
    data: CordraObject;
}

const Title = ({ data }: Props): JSX.Element => {
    const { content, type } = data;
    const title = getTitleFromType(type!)(content);
    const id = type === "Board" || type === "CoordinationGroup" ?
        "" :
        content.id;
    if (isSupportedType(type!)) {
        const linkTarget = `/${getPathFromType(type!)}/${id}`;
        return (<React.Fragment><Link to={linkTarget}>
            <span className="mb-auto">{title && title.name}</span>
        </Link></React.Fragment>);
    } else {
        return (<React.Fragment><span className="mb-auto">{title && title.name}</span></React.Fragment>);
    }
};
export default Title;
