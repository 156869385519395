import React from "react";
import { Link } from "react-router-dom";

const Footer: React.ComponentType = (): JSX.Element => {
    return (<footer className="footer text-white bg-dark w-100">
        <div className="container d-flex h-100 pt-5">
            <div className="row w-100 h-100">
                <p className="text-center w-100 font-weight-light">
                    MODS is a service of the DONA Foundation. &nbsp;For any comments, questions, and suggestions related to MODS, send an email to contact@dona.net.
                </p>

                <p className="text-center w-100 font-weight-light">
                    Copyright © 2020 The DONA Foundation. &nbsp;DONA® is a trademark of the DONA Foundation.
                </p>
                <p className="text-center w-100">
                    <Link to={"/privacy-statement"}>Privacy Statement</Link>
                    <Link to={"/terms-and-conditions"} className="pl-4">
                        Terms and Conditions
                    </Link>
                </p>
            </div>
        </div>
    </footer>);
};

export default Footer;
