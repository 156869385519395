import React from "react";
import "bootstrap";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AuthStore } from "./components/AuthStore";
import App from "./App";
import * as serviceWorker from './serviceWorker';
import "typeface-source-sans-pro";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./css/bootstrap4-executive-suite.min.css";
import "./css/custom.css";

ReactDOM.render(
    <AuthStore>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AuthStore>,
    document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
