/* eslint-disable react/prop-types */
import React from "react";
import { saveAs } from "file-saver";
import { Payload as PayloadType } from "@cnri/cordra-client";
import { readableBytes } from "../util";
import { getPayloadDownloadLink } from "../../services/MODSService";

interface PayloadProps {
    id: string;
    payload: PayloadType;
    isViewMode: boolean;
    deleteHandler: (payloadName: string) => {};
    isMinimal: boolean;
}

const Payload = (props: PayloadProps): JSX.Element => {

    const { id, payload, isViewMode, deleteHandler, isMinimal } = props;
    const minView = isMinimal ?
        true :
        false;
    const downloadPayloadLink = getPayloadDownloadLink(id, payload.name);
    const linkForCopyAndShare = `/payloads?id=${id}&payload=${payload.name}`;

    return (<div className="media mt-1 mb-1">
        <div className="media-body">
            <div className="main pull-left">
                <h6 className="title title-role">
                    <i className="fa fa-file-text-o ml-2 mr-2" /> {payload.name}
                </h6>
                { minView ? ("") : (<span className="role ml-4">({payload.size && readableBytes(payload.size)})</span>) }
            </div>
            <div className="controls ml-4 pull-right">
                { isViewMode ? (
                    <a href={linkForCopyAndShare} onClick={e => { e.preventDefault(); download(downloadPayloadLink, payload.name); }} className="btn btn-sm btn-outline-primary ml-4 mr-4" role="button">
                        <i className="fa fa-download small" aria-hidden="true" />{" "}
                        {
                            minView
                                ? ""
                                : "Download"
                        }
                    </a>
                )
                    : (<button type="button" className="btn btn-outline-primary btn-sm ml-4 mr-4" onClick={e => deleteHandler(payload.name)}>
                        <i className="fa fa-trash-o small" aria-hidden="true" />{" "}
                        {
                            minView
                                ? ""
                                : "Delete"
                        }
                    </button>)
                }
            </div>
        </div>
    </div>);
};

const download = (downloadPayloadLink: string, payloadName: string): void => {
    saveAs(downloadPayloadLink, payloadName);
};

export default Payload;
