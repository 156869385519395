import React from "react";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import { CordraObject } from "@cnri/cordra-client";
import { handleError } from "../util";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import Form from "../common/components/Form";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

interface Props extends DataFetchingState<CordraObject> {
    injected?: boolean;
}

interface ViewModel {
    id: string;
    lastName: string;
    firstName: string;
    middleName: string;
    suffix: string;
    emailAddress: string;
    donaEmailLogin: string;
    organization: string;
    admin: boolean;
    guest: boolean;
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {
    schema = {
        id: Joi.string()
            .allow("")
            .allow(null),
        lastName: Joi.string()
            .required()
            .min(1)
            .label("Last Name"),
        firstName: Joi.string()
            .required()
            .min(1)
            .label("First Name"),
        middleName: Joi.string()
            .allow("")
            .allow(null)
            .label("Middle Name"),
        suffix: Joi.string()
            .required()
            .min(1)
            .label("Suffix"),
        emailAddress: Joi.string()
            .required()
            .label("Email Address"),
        donaEmailLogin: Joi.string()
            .allow("")
            .allow(null)
            .label("DONA Email Login"),
        organization: Joi.string()
            .required()
            .label("Organization"),
        admin: Joi.boolean()
            .label("Administrator"),
        guest: Joi.boolean()
            .label("Administrator")
    };

    state: State = {
        data: {
            id: "",
            lastName: "",
            firstName: "",
            middleName: "",
            suffix: "",
            emailAddress: "",
            donaEmailLogin: "",
            organization: "",
            admin: false,
            guest: false
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };

    mapToViewModel = (data: CordraObject): void => {
        const { content } = data;
        this.setState({
            data: {
                id: content.id,
                lastName: content.lastName || "",
                firstName: content.firstName || "",
                middleName: content.middleName || "",
                suffix: content.suffix || "",
                emailAddress: content.emailAddress || "",
                donaEmailLogin: content.donaEmailLogin || "",
                organization: content.organization || "",
                admin: content.admin || false,
                guest: content.guest || false
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const data = {
            id: viewModel.id,
            type: "Member",
            content: viewModel
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler, shouldFetch } = this.props;
            saveHandler && saveHandler(data)
                .then(({ id }) => {
                    if (!shouldFetch) {
                        toast.success(
                            "An email is sent to the participant with instructions to activate his/her account."
                        );
                    }
                    this.setState({ saved: true, data: { id } });
                })
                .catch(response => {
                    this.setState({ saveOngoing: false }, () => {
                        handleError(response);
                        this.mapToData(this.state.data);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, data, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;
        return saved ? (
            <Redirect to={`/participants/${data.id}`} />
        ) : this.state.mapped || !waitUntilMapped ? (
            <div>
                <h1>{shouldFetch ? "Edit" : "New"} Participant</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("lastName", "Last Name")}
                    {this.renderInput("firstName", "First Name")}
                    {this.renderInput("middleName", "Middle Name")}
                    {this.renderSelect("suffix", "Suffix", ["Mr", "Mrs", "Ms", "Dr"])}
                    {this.renderInput("emailAddress", "Email Address")}
                    {this.renderInput("donaEmailLogin", "DONA Email Login")}
                    {this.renderAutoSuggest("organization", "Organization", ["Organization"])}
                    {this.renderCheckBox("admin", "Add as an Administrator")}
                    {this.renderCheckBox("guest", "Add as a Guest")}
                    <div className="m-2" />
                    {saveOngoing
                        ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                        : this.renderButton("Save", "fa fa-save")}
                    <span className="m-2" />
                    <ButtonWithBackContext label="Discard Changes" enable={true} />
                    <div className="m-2" />
                </form>
            </div>) :
            <React.Fragment></React.Fragment>;
    };
}

export default NewOrEdit;
