import React, { useContext } from "react";
import { AuthContext } from "./AuthStore";
import { User } from "./interfaces";
import rules from "./RBACRules";

interface Props {
    type: string;
    action: string;
    data?: {};
    children: JSX.Element | JSX.Element[];
}

const ShowIfAuthorized = (props: Props): JSX.Element => {
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    return (
        user && check(user, props.type, props.action, props.data)
            ? (<React.Fragment>{props.children}</React.Fragment>)
            : <React.Fragment></React.Fragment>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const check = (user: User, type: string, action: string, data: any) => {
    const permissions = user.admin ?
        rules.admin :
        rules.nonAdmin;

    const staticPermissions = permissions.static.filter(permission => permission.type === type && permission.actions.includes(action));

    let authorized = staticPermissions.length > 0;

    if (authorized) return true;

    const dynamicPermissions = permissions.dynamic.filter(permission => permission.type === type && permission.actions.includes(action));
    authorized = dynamicPermissions.length > 0 && dynamicPermissions[0].condition && dynamicPermissions[0].condition({ user, data });

    return authorized;
};

export default ShowIfAuthorized;
