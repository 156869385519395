import React, { ComponentType } from "react";
import { PaginationProps } from "../common/components/Pagination";

const injectTable = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P & PaginationProps> => {
    const InjectTable = (props: P & PaginationProps) => {
        const totalCount = props?.totalCount || 0;
        if (totalCount === 0) {
            return <React.Fragment />;
        } else {
            return (<div className="mt-4 mb-4 pb-4">
                <h4 className="header mb-3">ARS Providers</h4>
                <div className="media border">
                    <div className="media-body row pl-3">
                        <div className="col-4 border-right">
                            <h5 className="header">Name</h5>
                        </div>
                        <div className="col-4 border-right">
                            <h5 className="header">Member Since</h5>
                        </div>
                        <div className="col-4">
                            <h5 className="header">System Admin</h5>
                        </div>
                    </div>
                </div>
                <WrappedComponent {...props} />
            </div>);
        }
    };
    return InjectTable;
};
export default injectTable;
