import React from "react";
import CreatableMultiSelect from "../common/components/CreatableMultiSelect";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TagsInput = (props: any): JSX.Element => {
    const { tagSet, ...rest } = props;
    return (<CreatableMultiSelect options={tagSet} {...rest} />);
};

export default TagsInput;
