import React, { useEffect, useMemo, useState } from 'react';
import { AnyObject, createAlignPlugin, createBlockquotePlugin, createBoldPlugin, createCodeBlockPlugin, createCodePlugin, createExitBreakPlugin, createFontBackgroundColorPlugin, createFontColorPlugin, createFontFamilyPlugin, createFontSizePlugin, createFontWeightPlugin, createHeadingPlugin, createHighlightPlugin, createHistoryPlugin, createHorizontalRulePlugin, createImagePlugin, createIndentPlugin, createItalicPlugin, createLinkPlugin, createListPlugin, createParagraphPlugin, createPlateComponents, createPlateOptions, createReactPlugin, createResetNodePlugin, createSelectOnBackspacePlugin, createSoftBreakPlugin, createStrikethroughPlugin, createSubscriptPlugin, createSuperscriptPlugin, createTablePlugin, createTrailingBlockPlugin, createUnderlinePlugin, EditorAboveOptions, ELEMENT_BLOCKQUOTE, ELEMENT_CODE_BLOCK, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6, ELEMENT_HR, ELEMENT_IMAGE, ELEMENT_LI, ELEMENT_LINK, ELEMENT_PARAGRAPH, ELEMENT_TD, ELEMENT_TODO_LI, ELEMENT_UL, getBlockAbove, HeadingToolbar, ImageToolbarButton, isAncestorEmpty, isStart, KEYS_HEADING, LinkToolbarButton, Plate, TAncestor, TEditor } from '@udecode/plate';
import { Node, Descendant } from 'slate';
import { AlignToolbarButtons, BasicElementToolbarButtons, BasicMarkToolbarButtons, IndentToolbarButtons, ListToolbarButtons, TableToolbarButtons } from './Toolbars';
import { initialValue } from './value';


interface Props {
    name?: string;
    value: string;
    readMode: boolean;
    handleChange?: ({ currentTarget }: { currentTarget: { name: string; value: string; formatted: string } }) => void;
}

const RichTextEditor = (props: Props): JSX.Element => {

    const [value, setValue] = useState<Descendant[]>(deserialize(props.value));

    //side effect
    useEffect(() => {
        const { name, handleChange } = props;
        const rawText = (value && serialize(value)) || "";
        const editorDataModelFormattedText = JSON.stringify(value);
        if (name && handleChange) {
            const currentTarget = {
                name,
                value: rawText,
                formatted: editorDataModelFormattedText
            };
            handleChange({ currentTarget });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const components = createPlateComponents();
    const options = createPlateOptions({
        [ELEMENT_LINK]: {
            getNodeProps: () => { return ({ target: "_blank", rel: "noreferrer" }); }
        }
    });
    const pluginsMemo = useMemo(() => {
        const plugins = [
            createReactPlugin(),
            createHistoryPlugin(),
            createParagraphPlugin(),
            createBlockquotePlugin(),
            createHeadingPlugin(),
            createImagePlugin(),
            createHorizontalRulePlugin(),
            createLinkPlugin(),
            createListPlugin(),
            createTablePlugin(),
            createCodeBlockPlugin(),
            createBoldPlugin(),
            createCodePlugin(),
            createItalicPlugin(),
            createHighlightPlugin(),
            createUnderlinePlugin(),
            createStrikethroughPlugin(),
            createSubscriptPlugin(),
            createSuperscriptPlugin(),
            createFontBackgroundColorPlugin(),
            createFontFamilyPlugin(),
            createFontColorPlugin(),
            createFontSizePlugin(),
            createFontWeightPlugin(),
            createAlignPlugin({ validTypes: [
                ELEMENT_PARAGRAPH,
                ELEMENT_H1,
                ELEMENT_H2,
                ELEMENT_H3,
                ELEMENT_H4,
                ELEMENT_H5,
                ELEMENT_H6,
                ELEMENT_BLOCKQUOTE,
                ELEMENT_CODE_BLOCK,
                ELEMENT_LINK,
                ELEMENT_LI,
                ELEMENT_UL
            ] }),
            createIndentPlugin({ validTypes: [
                ELEMENT_PARAGRAPH,
                ELEMENT_H1,
                ELEMENT_H2,
                ELEMENT_H3,
                ELEMENT_H4,
                ELEMENT_H5,
                ELEMENT_H6,
                ELEMENT_BLOCKQUOTE,
                ELEMENT_CODE_BLOCK,
                ELEMENT_LINK,
                ELEMENT_LI,
                ELEMENT_UL
            ] }),
            createResetNodePlugin({ rules:
            [
                {
                    types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
                    defaultType: ELEMENT_PARAGRAPH,
                    hotkey: 'Enter',
                    predicate: (editor: TEditor) => {
                        const block = getBlockAbove(editor)?.[0];
                        if (!block) return false;
                        return isAncestorEmpty(editor, block);
                    }
                },
                {
                    types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
                    defaultType: ELEMENT_PARAGRAPH,
                    hotkey: 'Backspace',
                    predicate: (editor: TEditor, localOptions?: EditorAboveOptions<TAncestor<AnyObject>>) => {
                        const path = getBlockAbove(editor, localOptions)?.[1];
                        return !!path && isStart(editor, editor.selection?.focus, path);
                    }
                }] }),
            createSoftBreakPlugin({ rules: [
                { hotkey: 'shift+enter' },
                {
                    hotkey: 'enter',
                    query: {
                        allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD]
                    }
                }
            ] }),
            createExitBreakPlugin({ rules: [
                {
                    hotkey: 'mod+enter'
                },
                {
                    hotkey: 'mod+shift+enter',
                    before: true
                },
                {
                    hotkey: 'enter',
                    query: {
                        start: true,
                        end: true,
                        allow: KEYS_HEADING
                    }
                }
            ] }),
            createTrailingBlockPlugin({ type: ELEMENT_PARAGRAPH }),
            createSelectOnBackspacePlugin({ allow: [ELEMENT_IMAGE, ELEMENT_HR] })
        ];

        return plugins;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);
    const { readMode } = props;
    return (
        <div className={`rich-text-editor readmode-${readMode}`}>
            <Plate
                plugins={pluginsMemo}
                components={components}
                options={options}
                enabled={true}
                editableProps={
                    {
                        autoFocus: false,
                        spellCheck: false,
                        placeholder: 'Type…',
                        readOnly: readMode
                    }
                }
                initialValue={value}
                onChange={updatedValue => {
                    setValue(updatedValue as Descendant[]);
                }}
            >
                {!readMode && <HeadingToolbar>
                    <BasicElementToolbarButtons />
                    <ListToolbarButtons />
                    <IndentToolbarButtons />
                    <AlignToolbarButtons />
                    <BasicMarkToolbarButtons />
                    <LinkToolbarButton icon={<i className="fa fa-link" />} />
                    <ImageToolbarButton icon={<i className="fa fa-image" />} />
                    <TableToolbarButtons />
                </HeadingToolbar>}

            </Plate>
        </div>
    );
};

// Define a serializing function that takes a value and returns a string.
const serialize = (value: Descendant[]): string => {
    return (
        value.map(n => Node.string(n)).join('\n')
    );
};

// Define a deserializing function that takes a JSON string and converts into Descendant[]
const deserialize = (value: string | undefined): Descendant[] => {
    return (value && JSON.parse(value)) || initialValue;
};

export default RichTextEditor;
