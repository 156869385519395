import React from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends RouteComponentProps<{}, any, any> {
    label: string;
    enable: boolean;
    icon?: string;
    className?: string;
}

const ButtonWithBackContext = (props: Props): JSX.Element => {
    const {
        label,
        enable,
        icon,
        className
    } = props;

    const classNameString = className || "margin-5px-all d-table d-block";
    const history = useHistory();

    return (
        <React.Fragment>
            <button className="btn btn-secondary" type="button">
                { enable && (
                    <span className={classNameString} onClick={history.goBack}>
                        {icon && <i className={`"${icon} margin-5px-right no-margin-left"`} aria-hidden="true"></i>}
                        {label}
                    </span>)
                }
                { !enable && (
                    <span className={`${classNameString} disabled`}>
                        {icon && <i className={`"${icon} margin-5px-right no-margin-left"`} aria-hidden="true"></i>}
                        {label}
                    </span>)
                }
            </button>
        </React.Fragment>
    );
};

export default withRouter(ButtonWithBackContext);
