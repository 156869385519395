import React from "react";
import moment from "moment";
import TitleContainer from "../Title/Container";
import RichTextEditor from "../RichTextEditor/";
import { Activity } from "../../models/Activity";

interface Props {
    data: {
        content: Activity;
    };
}

const Detailed = (props: Props): JSX.Element => {
    const { data: { content: activity } } = props;
    const organizers = activity.participants.filter(participant => participant.title === "Organizer");
    const participants = activity.participants.filter(participant => participant.title !== "Organizer");
    return (<div className="detailed">
        <div className="details">
            <h1>{activity.name}</h1>
            <footer className="detailed-footer d-flex align-items-center">
                <div className="title pl-2 pr-2">
                    { organizers && organizers.length > 0
                        ? (<React.Fragment>
                            {
                                organizers.map((organizer, index) => (<div className="title-last pr-2" key={index}>
                                    <i className="fa fa-address-book" />
                                    <span key={organizer.member} className="text ml-2">
                                        <TitleContainer key={organizer.member} fetchProps={{
                                            id: organizer.member
                                        }} />
                                    </span>
                                </div>))
                            }
                        </React.Fragment>)
                        : ("")
                    }
                </div>
                <div className="title pl-2 pr-2">
                    <i className="fa fa-calendar" />
                    <span className="text ml-2">
                        {moment(new Date(activity.heldDate)).format("MMMM Do, YYYY")}
                    </span>
                </div>
                { activity.participants && (<div className="title pl-2 pr-2">
                    <i className="fa fa-user" />
                    <span className="text ml-2">
                        {
                            `${participants.length} ${
                                participants.length > 1
                                    ? "Participants"
                                    : "Participant"}`
                        }
                    </span>
                </div>)
                }
                { activity.location && (<div className="title title-last pl-2 pr-2">
                    <i className="fa fa-comment" />
                    <span className="text ml-2">{activity.location}</span>
                </div>)
                }
            </footer>
            <div className="border-bottom border-grey pb-3" />
            <div className="body mt-4 mb-4 pb-4">
                {activity.purposeFormatted && <RichTextEditor value={activity.purposeFormatted} readMode={true} key={activity.id} />}
                {!activity.purposeFormatted && <p>{activity.purpose}</p>}
            </div>
            { activity.participants && (<div className="mt-4 mb-4 pb-4">
                <h4 className="header mb-3">Participants</h4>
                {
                    organizers.map(organizer => (<div className="media mt-1" key={organizer.member}>
                        <div className="media-body">
                            <div className="main pull-left">
                                <span className="title">
                                    <TitleContainer key={organizer.member} fetchProps={{
                                        id: organizer.member
                                    }} />
                                    <span className="title ml-2">({organizer.title})</span>
                                </span>
                            </div>
                        </div>
                    </div>))
                }
                { participants.map(participant => (<div className="media mt-1" key={participant.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title">
                                <TitleContainer key={participant.member} fetchProps={{
                                    id: participant.member
                                }} />
                            </span>
                        </div>
                    </div>
                </div>))
                }
            </div>)
            }
            { activity.contributions && (<div className="mt-4 mb-4 pb-4">
                <h4 className="header mb-3">Contributions</h4>
                {
                    activity.contributions.map(contributionPost => (<div className="media mt-1" key={contributionPost}>
                        <div className="media-body">
                            <div className="main pull-left">
                                <span className="title">
                                    <TitleContainer key={contributionPost} fetchProps={{
                                        id: contributionPost
                                    }} />
                                </span>
                            </div>
                        </div>
                    </div>))
                }
            </div>)
            }{" "}
            { activity.outcomes && (<div className="mt-4 mb-4 pb-4">
                <h4 className="header mb-3">Outcomes</h4>
                {
                    activity.outcomes.map(outcomePost => (<div className="media mt-1" key={outcomePost}>
                        <div className="media-body">
                            <div className="main pull-left">
                                <span className="title">
                                    <TitleContainer key={outcomePost} fetchProps={{
                                        id: outcomePost
                                    }} />
                                </span>
                            </div>
                        </div>
                    </div>))
                }
            </div>)
            }
        </div>
    </div>);
};

export default Detailed;
