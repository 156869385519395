import { compose } from "redux";

import DetailedMember from "./Detailed";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import injectLayoutWithQuickLinks from "../InjectLayoutWithQuickLinks";
import extractId from "../ExtractId";
import withInjectedProps from "../common/hoc/WithInjectedProps";

import { getObjectById, update } from "../../services/MODSService";

const type = "Member";
export default compose(
    extractId(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById
        },
        defaultLoadingMessage: "Loading Participant",
        Error,
        DefaultComponent: Default
    }),
    injectLayoutWithQuickLinks(type),
    withInjectedProps({
        setActivationStatus: update
    })
)(DetailedMember);
