/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import { CordraObject } from '@cnri/cordra-client';
import { getPayloadDownloadLink } from "../../services/MODSService";
import { WrappedComponentDataFetchingProps } from '../common/hoc/interfaces';
import { PayloadParams } from './ExtractQueryParams';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends RouteComponentProps<{}, any, any>, WrappedComponentDataFetchingProps<CordraObject> {
    fetchProps: PayloadParams;
}

interface State {
    redirect: boolean;
    pathname: string;
}

class Download extends Component<Props, State> {
    state = {
        redirect: false,
        pathname: ""
    };

    componentDidMount() {
        const { id, payload } = this.props.fetchProps;

        // const cordraOptions = retrieveCordraOptions();

        const downloadPayloadLink = getPayloadDownloadLink(id, payload);
        saveAs(downloadPayloadLink, payload);

        this.setState({
            redirect: true,
            pathname: `/posts/${id}`
        });
    }

    render() {
        return this.state.redirect ?
            (<Redirect to={{
                pathname: this.state.pathname
            }} />) :
            (<React.Fragment></React.Fragment>);
    }

}

export default withRouter(Download);
