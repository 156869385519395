import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import TitleContainer from "../Title/Container";
import { Activity as ActivityType } from "../../models/Activity";

interface Props {
    data: {
        content: ActivityType;
    };
}

const Activity = (props: Props): JSX.Element => {
    const { data: { content: activity } } = props;
    const organizers = activity.participants.filter(participant => participant.title === "Organizer");
    return (<div className="media border-bottom border-left border-right" key={activity.id}>
        <div className="media-body row pl-3">
            <div className="col-4 border-right">
                {moment(new Date(activity.heldDate)).format("MMMM Do, YYYY")}
            </div>
            <div className="main col-4 border-right">
                <span className="title">
                    <Link to={`/activities/${activity.id}`}>
                        <p className="mb-auto">{activity.name}</p>
                    </Link>
                </span>
            </div>
            <div className="main col-4">
                { organizers && organizers.length > 0 ? (
                    <div className="title">
                        {
                            organizers.map((organizer, index) => (
                                <span key={organizer.member}>
                                    <span className={`override-link text ${
                                        index === organizers.length - 1
                                            ? "mr-2"
                                            : ""}`}>
                                        <TitleContainer key={organizer.member} fetchProps={{
                                            id: organizer.member
                                        }} /> {
                                            index < organizers.length - 1
                                                ? ", "
                                                : ""
                                        }
                                    </span>
                                </span>))
                        }
                    </div>)
                    : ("")
                }
            </div>
        </div>
    </div>);
};

export default Activity;
