import { compose } from "redux";
import { CordraObject } from '@cnri/cordra-client';

import NewBoard from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import { create } from "../../services/MODSService";

export default compose(
    withContainer<DataFetchingState<CordraObject>, CordraObject>({
        dataParams: {
            saveHandler: create
        },
        defaultLoadingMessage: "Loading...",
        Error,
        DefaultComponent: Default
    })
)(NewBoard);
