import React from "react";
import { Redirect, Link } from "react-router-dom";
import Joi from "@hapi/joi";
import { CordraObject, Payload } from "@cnri/cordra-client";
import { handleError } from "../util";
import TitleContainer from "../Title/Container";
import Form from "../common/components/Form";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

const ACCEPTED_FORMATS = [ "*/*" ];
const ACCEPTED_PAYLOAD_NAMES: string[] = [];
const ACCEPTED_PAYLOAD_TYPES : string[] = [ "bin", "zip", "jws", "crt", "pub" ];
const MAX_PAYLOAD_SIZE = 250 * 1024 * 1024;

interface Props extends DataFetchingState<CordraObject> {
    injected: boolean;
}

interface ViewModel {
    id: string;
    from: string;
    lastModifiedBy: string;
    to: string;
    context: string;
    environment: string;
    payloads: { acceptedPayloads?: Payload[]; deletedPayloads?: string[]; previouslyAcceptedPayloads: Payload[] };
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {
    schema = {
        id: Joi.string()
            .allow("")
            .allow(null),
        from: Joi.string()
            .allow("")
            .allow(null),
        lastModifiedBy: Joi.string()
            .allow("")
            .allow(null),
        to: Joi.string()
            .allow("")
            .allow(null),
        context: Joi.string()
            .required()
            .label("Provider Name"),
        environment: Joi.string()
            .required()
            .label("Environment"),
        description: Joi.string()
            .allow("")
            .allow(null)
            .label("rawText"),
        descriptionFormatted: Joi.string()
            .allow("")
            .allow(null)
            .label("Description"),
        payloads: Joi.object()
            .required()
            .label("Payloads")
    };

    state: State = {
        data: {
            id: "",
            from: "",
            lastModifiedBy: "",
            to: "",
            context: "",
            environment: "",
            payloads: { previouslyAcceptedPayloads: [] }
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };

    mapToViewModel = (data: CordraObject): void => {
        const { content, payloads } = data;

        this.setState({
            data: {
                id: content.id || "",
                from: content.from,
                lastModifiedBy: content.lastModifiedBy,
                to: content.to || "",
                context: content.context || "",
                environment: content.environment || "",
                description: content.description || "",
                descriptionFormatted: content.descriptionFormatted || "",
                payloads: { previouslyAcceptedPayloads: payloads }
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const dataModel = viewModel;
        const data = {
            id: dataModel.id,
            type: "ProviderPost",
            content: dataModel,
            payloads: viewModel.payloads &&
                viewModel.payloads.acceptedPayloads &&
                viewModel.payloads.acceptedPayloads,
            payloadsToDelete: viewModel.payloads &&
                viewModel.payloads.deletedPayloads &&
                viewModel.payloads.deletedPayloads
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({ saveOngoing: true }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data, this.props.data)
                .then(({ id }) => {
                    this.setState({
                        saved: true,
                        data: { id }
                    });
                })
                .catch(response => {
                    this.setState({ saveOngoing: false }, () => {
                        handleError(response);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, data, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const context = data.context;
        const waitUntilMapped = shouldFetch || injected;
        return saved ? (
            <Redirect to={`/provider-bundles/${data.id}`} />
        ) : this.state.mapped || !waitUntilMapped ? (
            <div>
                <h1>
                    {shouldFetch ? "Edit" : "New"} Provider Bundle for{" "}
                    <TitleContainer fetchProps={{ id: context }} />
                </h1>
                <form onSubmit={this.handleSubmit}>
                    <div className="m-2" />
                    {this.renderSelectWithCustomInput("environment", "Environment", [
                        "Production",
                        "Test"
                    ])}
                    {this.renderRichTextEditor("description", "Description")}
                    {this.renderPayloadZone(
                        "payloads",
                        "Payloads",
                        ACCEPTED_FORMATS,
                        1,
                        ACCEPTED_PAYLOAD_NAMES,
                        ACCEPTED_PAYLOAD_TYPES,
                        MAX_PAYLOAD_SIZE
                    )}
                    <div className="m-3" />
                    { saveOngoing
                        ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                        : this.renderButton("Save", "fa fa-save")}
                    <span className="m-2" />
                    <ButtonWithBackContext label="Discard Changes" enable={true} />
                    <div className="m-2" />
                    <span className="text">
                        By voluntarily posting or otherwise sharing information with
                        visitors to this MODS site, you agree with the{" "}
                        <Link to="/terms-and-conditions">Terms and Conditions</Link> laid
                        out by the DONA Foundation.
                    </span>
                    <div className="m-2" />
                </form>
            </div>
        ) : <React.Fragment></React.Fragment>;
    };
}

export default NewOrEdit;
