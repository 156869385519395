import { compose } from "redux";

import DetailedBoard from ".";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import injectLayoutWithQuickLinks from "../InjectLayoutWithQuickLinks";
import extractTypeParams from "../ExtractTypeParams";
import extractQueryResults from "../ExtractQueryResults";
import extractBoardResult from "./ExtractBoardResult";

import { search } from "../../services/MODSService";

const type = "Board";
export default compose(
    // TODO: find a way to NOT hard code object types
    extractTypeParams(type),
    withContainer({
        dataParams: {
            fetchHandler: search
        },
        defaultLoadingMessage: "Loading Board",
        Error,
        DefaultComponent: Default
    }),
    extractQueryResults(),
    injectLayoutWithQuickLinks(type),
    extractBoardResult()
)(DetailedBoard);
