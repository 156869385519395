import React, { Component, ChangeEvent } from "react";

interface Props {
    name: string;
    isDisabled?: boolean;
    checked: boolean;
    label?: string;
    error?: string;
    className?: string;
    required?: boolean;
    onChecked: ({ currentTarget }: { currentTarget: { name: string; value: boolean } }) => void;

}

interface State {
    checked: boolean;
}

class Checkbox extends Component<Props, State> {

    state: State = {
        checked: false
    };

    componentDidMount = (): void => {
        const { checked } = this.props;
        this.setState({ checked });
    };

    componentDidUpdate = (prevProps: Props): void => {
        const { checked } = this.props;
        if (prevProps.checked !== checked) {
            this.setState({ checked });
        }
    };

    handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const {
            name,
            onChecked
        } = this.props;
        const { checked } = event.target;
        this.setState({ checked }, () => {
            const currentTarget = {
                name,
                value: checked
            };
            onChecked && onChecked({ currentTarget });
        });
    };

    render = (): JSX.Element => {
        const {
            name,
            label,
            required,
            error,
            children,
            className
        } = this.props;

        const { checked } = this.state;

        return (
            <div className="d-flex">
                <input type="checkbox" id={name} name={name} checked={checked} className={`display-inline-block ${className ? className : "checkbox"}`} onChange={this.handleChange} />
                {label && (
                    <React.Fragment>
                        <label className="no-padding no-indent" htmlFor={name}>
                            {label ? label : children}
                        </label>
                        {required && <span className="required">*</span>}
                    </React.Fragment>
                )}
                {error && <div className="alert alert-danger">{error}</div>}
            </div>
        );
    };
}

export default Checkbox;
