import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import TitleContainer from "../Title/Container";
import { ARSProvider as ARSProviderType, Member } from "../../models/ARSProvider";

interface Props {
    data: {
        content: ARSProviderType;
    };
}

const ARSProvider = (props: Props): JSX.Element => {
    const { data: { content: ars } } = props;
    const isDeactivated = ars?.isDeactivated || false;
    return (
        <React.Fragment>
            <div className="media border-bottom border-left border-right" key={ars.id}>
                <div className="media-body row pl-3">
                    <div className="col-4 border-right">
                        <Link to={`/ars-providers/${ars.id}`}>{ars.name}{isDeactivated ? " - Inactive" : ""}</Link>
                    </div>
                    <div className="col-4 border-right">
                        {moment(new Date(ars.memberSince)).format("MMMM Do, YYYY")}
                    </div>
                    <div className="main col-4">
                        { ars.members?.filter((member: Member) => member.title === "System Admin").map(sysMember => (<span key={sysMember.member}>
                            <i className="fa fa-address-book" key={sysMember.member} />
                            <span className="override-link text ml-2 mr-2">
                                <TitleContainer key={sysMember.member} fetchProps={{
                                    id: sysMember.member
                                }} />
                            </span>
                        </span>))
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>);
};

export default ARSProvider;
