import { compose } from "redux";

import NewARSProvider from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import injectGHRProvider from "./InjectGHRProvider";

import { createProvider } from "../../services/MODSService";

export default compose(
    injectGHRProvider(),
    withContainer({
        dataParams: {
            saveHandler: createProvider
        },
        Error,
        DefaultComponent: Default
    })
)(NewARSProvider);
