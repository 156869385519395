import { compose } from "redux";

import SummaryARSProvider from ".";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import asList from "../common/hoc/AsList";
import extractGHRProvider from "./ExtractGHRProvider";
import extractQueryResults from "../ExtractQueryResults";
import injectTable from "./InjectTable";

import { search } from "../../services/MODSService";

const type = "ARSProvider";
export default compose(
    // TODO: find a way to NOT hard code object types
    extractGHRProvider(type),
    withContainer({
        dataParams: { fetchHandler: search },
        defaultLoadingMessage: "Loading ARS Providers",
        Error,
        DefaultComponent: Default
    }),
    extractQueryResults(),
    injectTable(),
    asList()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
)(SummaryARSProvider) as React.FunctionComponent<any>;
