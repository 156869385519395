import { compose } from "redux";

import DetailedWorkingGroup from "./Detailed";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import injectLayoutWithQuickLinks from "../InjectLayoutWithQuickLinks";
import extractId from "../ExtractId";

import { getObjectById } from "../../services/MODSService";

const type = "WorkingGroup";
export default compose(
    extractId(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById
        },
        defaultLoadingMessage: "Loading Working Group",
        Error,
        DefaultComponent: Default
    }),
    injectLayoutWithQuickLinks(type)
)(DetailedWorkingGroup);
