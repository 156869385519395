import React from "react";
import moment from "moment";
import { Payload } from "@cnri/cordra-client";
import TitleContainer from "../Title/Container";
import PayloadsList from "../Payloads";
import RichTextEditor from "../RichTextEditor/";
import { ProviderPost } from "../../models/ProviderPost";

interface Props {
    data: {
        content: ProviderPost;
        payloads: Array<Payload>;
    };
}

const Detailed = (props: Props): JSX.Element => {
    const { data: { content: providerPost, payloads } } = props;
    return (
        <div className="detailed">
            <div className="details">
                <h1>
                    {providerPost.environment} Upload on{" "}
                    {moment(new Date(providerPost.modificationDate)).format(
                        "MMMM Do, YYYY"
                    )}
                </h1>
                <footer className="detailed-footer d-flex align-items-center">
                    <div className="title pl-2 pr-2">
                        <i className="fa fa-envelope" />
                        <span className="text ml-2">
                            Last Modified By <TitleContainer fetchProps={{ id: providerPost.lastModifiedBy }} />
                        </span>
                    </div>
                    <div className={`title ${payloads ? "" : "title-last"} pl-2 pr-2`}>
                        <i className="fa fa-calendar" />
                        <span className="text ml-2">
                            Edited on{" "}
                            {moment(new Date(providerPost.modificationDate)).format(
                                "MMMM Do, YYYY"
                            )}
                        </span>
                    </div>
                    {payloads && (
                        <div className="title title-last pl-2 pr-2">
                            <i className="fa fa-paperclip" />
                            <span className="text ml-2">
                                {`${payloads.length} ${
                                    payloads.length > 1 ? "Payloads" : "Payload"
                                }`}
                            </span>
                        </div>
                    )}
                </footer>
                <div className="border-bottom border-grey pb-3" />
                <div className="body mt-4 mb-4 pb-4">
                    {providerPost.descriptionFormatted && <RichTextEditor
                        value={providerPost.descriptionFormatted}
                        readMode={true}
                        key={providerPost.id}
                    />}
                    {!providerPost.descriptionFormatted && <p>{providerPost.description}</p>}
                </div>
                <div className="mt-4 mb-4 pb-4">
                    {payloads && (
                        <React.Fragment>
                            <h4 className="header mb-3">Payloads</h4>
                            <PayloadsList
                                isViewMode={true}
                                payloads={payloads}
                                id={providerPost.id}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Detailed;
