import React, { ComponentType } from "react";
import _ from "lodash";
import { getTitleFromType } from "../util";

const generateSuggestionList = <P extends object>() => (WrappedComponent: ComponentType<P>):ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const GenerateSuggestionList = (props: any): JSX.Element => {
        // eslint-disable-next-line react/prop-types
        const { results } = props.data;

        const types = _.chain(results)
            .groupBy("type")
            .map((elements, type) => {
                return type;
            })
            .value();

        const suggestionResults = _.chain(results)
            .groupBy("type")
            .map((elements, type) => {
                return elements.map(element => {
                    return {
                        type,
                        entry: getTitleFromType(type)(element.content)
                    };
                });
            })
            .flatten()
            .value();

        return (<WrappedComponent suggestions={suggestionResults} types={types} {...props} />);
    };
    return GenerateSuggestionList;
};
export default generateSuggestionList;
