import React, { ComponentType } from "react";
import { FetchProps } from "../common/hoc/interfaces";
import { QueryParams } from "../interfaces";
import { getSortFields } from "../util";

const extractGHRProvider = <P extends object>(type: string) => (WrappedComponent: ComponentType<P & FetchProps>): ComponentType<P & FetchProps> => {
    const ExtractGHRProvider = (props: P & FetchProps) => {
        const ghrId = props.ghrProviderId && props.ghrProviderId.id;
        const params: QueryParams = {
            query: `type: ${type} AND /ghrProviderId:"${ghrId}"`,
            pageNum: -1,
            pageSize: -1,
            sortFields: getSortFields(type)
        };
        return (
            <React.Fragment>
                <WrappedComponent fetchProps={params} {...props} />
            </React.Fragment>
        );
    };
    return ExtractGHRProvider;
};
export default extractGHRProvider;
