import React from "react";
import { toast } from "react-toastify";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Joi from "@hapi/joi";
import { handleError } from "./util";
import Form from "./common/components/Form";
import { AuthContextInfo } from "./interfaces";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ResetPasswordFormProps extends RouteComponentProps<{}, any, any> {
    authStore: AuthContextInfo;
}

interface State {
    data: {
        username: string;
    };
    errors: {};
    saveOngoing: boolean;
    redirect: boolean;
    touched: boolean;
    pathName?: string;
    search?: string;
}

class ResetPasswordForm<T extends {}> extends Form<T & ResetPasswordFormProps> {

    schema = {
        username: Joi.string()
            .required()
            .min(2)
            .label("Username")
    };

    state: State = {
        data: {
            username: ""
        },
        errors: {},
        redirect: false,
        saveOngoing: false,
        touched: false
    };

    setRedirection = (): void => {
        const { location } = this.props;
        if (location) {
            this.setState({
                redirect: true,
                pathName: location.state && location.state.from && location.state.from.pathname ? location.state.from.pathname : "/",
                search: location.state && location.state.from && location.state.from.search ? location.state.from.search : ""
            });
        }
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const { username } = this.state.data;
            const { reset } = this.props.authStore;
            reset && reset(username)
                .then(() => {
                    this.setState({ saved: true });
                    toast.success("An email is sent to " + username + " with instructions for resetting password.");
                    this.setRedirection();
                })
                .catch(response => {
                    this.setState({
                        saveOngoing: false
                    }, () => {
                        handleError(response);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { redirect, saveOngoing, pathName, search } = this.state;
        if (redirect) {
            return <Redirect to={{ pathname: pathName, search }} />;
        } else {
            return (
                <div className="container p-5">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card border border-primary">
                            <div className="card-header bg-primary text-white text-center">
                                <h2 className="bg-primary text-white text-center">
                                    <i className="fa fa-user-circle mt-1" />
                                </h2>
                                <h3 className="bg-primary text-white text-center py-4 mt-n3">
                                    Reset Password
                                </h3>
                            </div>
                            <div className="card-body p-3">
                                {this.renderInput("username", "Email Address")}
                                <div className="pt-2">
                                    {
                                        saveOngoing
                                            ? this.renderButton("Reset Password", "fa fa-spinner fa-spin")
                                            : this.renderButton("Reset Password", "fa fa-refresh")
                                    }
                                    <span className="m-2" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>);
        }
    };
}

export default ResetPasswordForm;
