import React, { ComponentType } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { getSortFields } from "./util";
import { QueryParams } from "./interfaces";

const extractQueryParams = <P extends object>(type: string) => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractQueryParams = (props: any): JSX.Element => {
        const location = useLocation();
        const queryValues = location && location.search && queryString.parse(location.search);
        const typeQueryFragment = `type: ${type}`;
        const params: QueryParams = {
            query: queryValues && queryValues.query ?
                `+(${queryValues.query.toString()}) +${typeQueryFragment}` :
                typeQueryFragment,
            pageNum: queryValues && queryValues.pageNum ?
                parseInt(queryValues.pageNum.toString()) - 1 :
                0,
            pageSize: queryValues && queryValues.pageSize ?
                parseInt(queryValues.pageSize.toString()) :
                10,
            sortFields: getSortFields(type)
        };
        return <WrappedComponent fetchProps={params} {...props} />;
    };
    return ExtractQueryParams;
};
export default extractQueryParams;
