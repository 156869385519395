import { compose } from "redux";

import TagsInput from ".";
import Default from "./Default";
import Error from "../Error";
import extractQueryParams from "./ExtractQueryParams";
import generateSuggestionList from "./GenerateSuggestionList";
import withContainer from "../common/hoc/WithContainer";

import { retrieveTagsObject } from "../../services/MODSService";

export default compose(
    extractQueryParams(),
    withContainer({
        dataParams: {
            fetchHandler: retrieveTagsObject
        },
        defaultLoadingMessage: "Loading tags",
        Error,
        DefaultComponent: Default
    }),
    generateSuggestionList()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
)(TagsInput) as React.FunctionComponent<any>;
