import React, { ComponentType } from "react";
import queryString from "query-string";

// TODO: catch error if id is not in the router params
const injectGHRProvider = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const InjectGHRProvider = (props: any) => {
        const queryValues = props.location && props.location.search && queryString.parse(props.location.search);
        const ghrProviderId = queryValues && queryValues.ghrProviderId;
        let data = props && props.data;
        if (data && data.content) {
            data.content.ghrProviderId = ghrProviderId;

        } else {
            data = {
                content: {
                    ghrProviderId
                }
            };
        }
        return (<React.Fragment>
            <WrappedComponent data={data} injected={true} {...props} />
        </React.Fragment>);
    };
    return InjectGHRProvider;
};
export default injectGHRProvider;
