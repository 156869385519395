import { compose } from "redux";

import EditActivity from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import extractId from "../ExtractId";

import { getObjectById, update } from "../../services/MODSService";

export default compose(
    extractId(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById,
            saveHandler: update
        },
        defaultLoadingMessage: "Retrieving Activity",
        Error,
        DefaultComponent: Default
    })
)(EditActivity);
