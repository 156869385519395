import React, { ComponentType } from "react";

const extractQueryParams = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractQueryParams = (props: any): JSX.Element => {
        return <WrappedComponent {...props} fetchProps={{}} />;
    };
    return ExtractQueryParams;
};
export default extractQueryParams;
