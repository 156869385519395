import React, { ComponentType } from "react";
import { QueryParams } from "../interfaces";
import { getSortFields } from "../util";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractMenuQueryParams = <P extends object>() => (WrappedComponent: ComponentType<P>):ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractMenuQueryParams = (props: any): JSX.Element => {
        const params: QueryParams = {
            query: "/pinToMenu:true",
            pageNum: -1,
            pageSize: -1,
            sortFields: getSortFields("menu")
        };
        return (<React.Fragment>
            <WrappedComponent fetchProps={params} {...props} />
        </React.Fragment>);
    };
    return ExtractMenuQueryParams;
};
export default extractMenuQueryParams;
