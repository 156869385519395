import React from "react";

import PaginationFilters from "./common/components/PaginationFilters";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const injectPaginationFilters = () => (WrappedComponent: any): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const InjectPaginationFilters = (props: any): JSX.Element => {
        return (<React.Fragment>
            <PaginationFilters {...props} />
            <WrappedComponent {...props} />
        </React.Fragment>);
    };
    return InjectPaginationFilters;
};
export default injectPaginationFilters;
