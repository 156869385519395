import React, { ComponentType } from "react";

const injectWrapper = <P extends object>() => (WrappedComponent: ComponentType<P>):ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const InjectWrapper = (props: any): JSX.Element => {
        return props && props.data && props.data.size > 0 ?
            (<React.Fragment>
                <h4 className="header mb-3">Activities</h4>
                <div className="media border">
                    <div className="media-body row pl-3">
                        <div className="col-4 border-right">
                            <h5 className="header">Held Date</h5>
                        </div>
                        <div className="col-4 border-right">
                            <h5 className="header">Activity Name</h5>
                        </div>
                        <div className="col-4">
                            <h5 className="header">Organizers</h5>
                        </div>
                    </div>
                </div>
                <WrappedComponent {...props} />
            </React.Fragment>) :
            (<React.Fragment>
                <h4 className="header mb-3">Activities</h4>
                <div className="media">
                    <div className="media-body row pl-3">
                        <h5 className="header">None</h5>
                    </div>
                </div>
            </React.Fragment>);
    };
    return InjectWrapper;
};
export default injectWrapper;
