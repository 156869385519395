import React, { ComponentType } from "react";
import { QueryParams } from "./interfaces";
import { getSortFields } from "./util";

const extractTypeParams = <P extends object>(type: string) => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractTypeParams = (props: any): JSX.Element => {
        const params: QueryParams = {
            query: `type: ${type}`,
            pageNum: 0,
            pageSize: 10,
            sortFields: getSortFields(type)
        };
        return <WrappedComponent fetchProps={params} {...props} />;
    };
    return ExtractTypeParams;
};
export default extractTypeParams;
