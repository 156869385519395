import React from "react";

const InlineIconText = (icon: string, text: string | JSX.Element): JSX.Element => {
    return (<React.Fragment>
        <span className="d-inline-block float-left">
            <i className={icon} />
        </span>
        <span className="d-inline-block ml-2 mr-2">
            {
                text ? text : "Not available"
            }
        </span>
    </React.Fragment>);
};

export default InlineIconText;
