import React, { useContext } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { AuthContext } from "../../AuthStore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProtectedRoute = (props: any) : JSX.Element => {
    const authStore = useContext(AuthContext);
    const { component: Component,
        render: SuppliedRender,
        ...rest } = props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (<Route {...rest} render={(routeProps: RouteComponentProps<{}, any, any>): JSX.Element => {
        if (!authStore.loggedIn) {
            return (<Redirect to={{
                pathname: "/login",
                state: {
                    from: routeProps.location
                }
            }} />);
        }
        return Component ? (<Component {...props} />) : (SuppliedRender(props));
    }}
    />);
};

export default ProtectedRoute;
