/* eslint-disable react/prop-types */
import React, { ComponentType } from "react";
import queryString from "query-string";
import { handlePrefix } from "../../services/MODSService";

// TODO: to field should not be hardcoded
const extractMandatoryFields = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractMandatoryFields = (props: any): JSX.Element => {
        const queryValues = props.location && props.location.search && queryString.parse(props.location.search);
        const context = queryValues && queryValues.context;
        let data = props && props.data;
        // eslint-disable-next-line no-empty
        if (data && data.content) {
        } else {
            data = {
                content: {
                    to: ""
                }
            };
        }
        data.content.to = `${handlePrefix}/e7b476fb72e4d4e883db`;
        if (context) data.content.context = context;
        data.providers = props.authStore.user.providers;
        return <WrappedComponent data={data} injected={true} {...props} />;
    };
    return ExtractMandatoryFields;
};
export default extractMandatoryFields;
