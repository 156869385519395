import { ErrorResponse } from "@cnri/cordra-client";
import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { ErrorProps } from "./common/hoc/WithErrorHandling";
import { handleError } from "./util";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Error = (props: RouteComponentProps & ErrorProps & ErrorResponse): any => {
    const { location, status } = props;
    switch (status) {
        case 401: {
            return (<React.Fragment>
                <Redirect to={{
                    pathname: "/login",
                    state: {
                        from: location
                    }
                }} /></React.Fragment>);
        }
        case 403: {
            return (<React.Fragment>
                <Redirect to={{
                    pathname: "/not-authorized",
                    state: {
                        from: location
                    }
                }} /></React.Fragment>);
        }
        case 404: {
            return (<React.Fragment>
                <Redirect to={{
                    pathname: "/not-found",
                    state: {
                        from: location
                    }
                }} /></React.Fragment>);
        }
        default: {
            return handleError(props);
        }
    }
};

export default withRouter(Error);
