/* eslint-disable no-prototype-builtins */
import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "@hapi/joi";
import moment from "moment";
import { CordraObject } from "@cnri/cordra-client";
import { handleError } from "../util";
import { DataFetchingState } from "../common/hoc/WithDataFetching";
import Form from "../common/components/Form";
import { WorkingGroup, Member, Status } from "../../models/WorkingGroup";
import ButtonWithBackContext from "../common/components/ButtonWithBackContext";

interface Props extends DataFetchingState<CordraObject> {
    injected?: boolean;
}

interface ViewModel {
    id: string;
    name: string;
    objective: string;
    objectiveFormatted: string;
    status: string;
    startDate: string;
    endDate: string;
    chairs: string[];
    participants: string[];
}

interface State {
    data: ViewModel;
    mapped: boolean;
    touched: boolean;
    errors: {};
    saved: boolean;
    saveOngoing: boolean;

}

class NewOrEdit extends Form<Props> {
    schema = {
        id: Joi.string()
            .allow("")
            .allow(null),
        name: Joi.string()
            .required()
            .min(1)
            .label("Name"),
        objective: Joi.string()
            .required()
            .min(1)
            .label("rawText"),
        objectiveFormatted: Joi.string()
            .required()
            .min(1)
            .label("Objective"),
        status: Joi.string()
            .required()
            .min(1)
            .label("Status"),
        startDate: Joi.date()
            .required()
            .allow(null)
            .label("Start Date"),
        endDate: Joi.date()
            .allow("")
            .allow(null)
            .label("End Date"),
        chairs: Joi.array()
            .items(Joi.string()
                .required())
            .min(1)
            .unique()
            .required()
            .label("Leads"),
        participants: Joi.array()
            .items(Joi.string()
                .required())
            .min(1)
            .unique()
            .required()
            .label("Participants")
    };

    state: State = {
        data: {
            id: "",
            name: "",
            objective: "",
            objectiveFormatted: "",
            status: "",
            startDate: "",
            endDate: "",
            chairs: [],
            participants: []
        },
        mapped: false,
        errors: {},
        saved: false,
        saveOngoing: false,
        touched: false
    };

    mapToViewModel = (data: CordraObject): void => {
        const { content } = data;
        const chairs = content.members.filter((member: Member) => member.title === "Chair")
            .map((item: Member) => item.member);
        const participants = content.members.filter((member: Member) => member.title !== "Chair")
            .map((item: Member) => item.member);
        this.setState({
            data: {
                id: content.id,
                name: content.name,
                objective: content.objective || "",
                objectiveFormatted: content.objectiveFormatted || "",
                status: content.status,
                startDate: content.timeline.startDate && new Date(content.timeline.startDate),
                endDate: content.timeline.hasOwnProperty("endDate") && content.timeline.endDate && content.timeline.endDate.length > 1 &&
                    new Date(content.timeline.endDate) || "",
                chairs,
                participants
            },
            mapped: true
        });
    };

    componentDidMount = (): void => {
        const shouldMap = this.props.shouldFetch || this.props.injected;
        shouldMap && this.mapToViewModel(this.props.data);
    };

    mapToData = (viewModel: ViewModel): CordraObject => {
        const dataModel: WorkingGroup = { ...viewModel, members: [], timeline: { startDate: "", endDate: "" }, status: "Active" };
        const members: Member[] = [];
        viewModel.chairs.map(chair => {
            return members.push({ member: chair, title: "Chair" });
        });
        viewModel.participants.map(participant => {
            return members.push({ member: participant, title: "Member" });
        });
        dataModel.members = members;
        dataModel.status = viewModel.status as Status;
        dataModel.timeline = {
            startDate: moment(new Date(viewModel.startDate))
            .format("YYYY-MM-DDTHH:mm:ssZ").toString(),
            endDate: viewModel.endDate && viewModel.endDate.toString().length > 0 ? moment(new Date(viewModel.endDate))
                .format("YYYY-MM-DDTHH:mm:ssZ").toString() : undefined
        };

        delete dataModel.chairs && delete dataModel.participants && delete dataModel.startDate && delete dataModel.endDate;

        const data = {
            id: dataModel.id,
            type: "WorkingGroup",
            content: dataModel
        };
        return data;
    };

    doSubmit = (): void => {
        this.setState({
            saveOngoing: true
        }, () => {
            const data = this.mapToData(this.state.data);
            const { saveHandler } = this.props;
            saveHandler && saveHandler(data)
                .then(({ id }) => {
                    this.setState({
                        saved: true,
                        data: {
                            id
                        }
                    });
                })
                .catch(response => {
                    this.setState({
                        saveOngoing: false
                    }, () => {
                        handleError(response);
                        this.mapToViewModel(data);
                    });
                });
        });
    };

    render = (): JSX.Element => {
        const { saved, data, saveOngoing } = this.state;
        const { shouldFetch, injected } = this.props;
        const waitUntilMapped = shouldFetch || injected;
        return saved ?
            (<Redirect to={`/working-groups/${data.id}`} />) :
            this.state.mapped || !waitUntilMapped ?
                (<div>
                    <h1>{
                        shouldFetch
                            ? "Edit"
                            : "New"
                    } Working Group</h1>
                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput("name", "Name")}
                        {this.renderRichTextEditor("objective", "Objective")}
                        {this.renderSelect("status", "Status", ["Active", "Inactive", "Proposed", "Rejected", "Completed"])}
                        {this.renderDatePicker("startDate", "Start Date")}
                        {this.renderDatePicker("endDate", "End Date")}
                        {this.renderAutoSuggest("chairs", "Leads", ["Member"], true)}
                        {this.renderAutoSuggest("participants", "Participants", ["Member"], true)}
                        <div className="p-3" />
                        { saveOngoing
                            ? this.renderButton("Saving", "fa fa-spinner fa-spin")
                            : this.renderButton("Save", "fa fa-save")}
                        <span className="m-2" />
                        <ButtonWithBackContext label="Discard Changes" enable={true} />
                        <div className="m-2" />
                    </form>
                </div>) :
                <React.Fragment></React.Fragment>;
    };
}

export default NewOrEdit;
