import { toast } from "react-toastify";
import _ from "lodash";
import { SortField, ErrorResponse } from '@cnri/cordra-client';
import { getSuggestion as getBoardTitle } from "./Board/getTitle";
import { getSuggestion as getCoordinationGroupTitle } from "./CoordinationGroup/getTitle";
import { getSuggestion as getGHRProviderTitle } from "./GHRProvider/getTitle";
import { getSuggestion as getARSProviderTitle } from "./ARSProvider/getTitle";
import { getSuggestion as getMemberTitle } from "./Member/getTitle";
import { getSuggestion as getOrganizationTitle } from "./Organization/getTitle";
import { getSuggestion as getWorkingGroupTitle } from "./WorkingGroup/getTitle";
import { getSuggestion as getActivityTitle } from "./Activity/getTitle";
import { getSuggestion as getProviderPostTitle } from "./ProviderPost/getTitle";
import { getSuggestion as getGroupTitle } from "./Group/getTitle";
import { getSuggestion as getPostTitle } from "./Post/getTitle";
import { Activity } from './../models/Activity';
import { ARSProvider } from './../models/ARSProvider';
import { Board } from './../models/Board';
import { CoordinationGroup } from './../models/CoordinationGroup';
import { GHRProvider } from './../models/GHRProvider';
import { Group } from './../models/Group';
import { Member } from './../models/Member';
import { Organization } from './../models/Organization';
import { Post } from './../models/Post';
import { ProviderPost } from './../models/ProviderPost';
import { WorkingGroup } from './../models/WorkingGroup';

export const readableBytes = (bytes: number): string => {
    const normalized = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const normalizedBytes = Math.pow(1024, normalized);
    const roundedBytes = (bytes / normalizedBytes);
    return `${roundedBytes.toFixed(0)} ${sizes[normalized]}`;
};

export const isSupportedType = (type: string): boolean => {
    const supportedTypes = [
        "Activity",
        "ARSProvider",
        "Board",
        "CoordinationGroup",
        "GHRProvider",
        "Group",
        "Member",
        "Organization",
        "Post",
        "ProviderPost",
        "WorkingGroup"
    ];
    return supportedTypes.includes(type);
};

export const isSearchUnsupported = (path: string): boolean => {
    const unsupportedPaths = ["/boards", "/cg"];
    return unsupportedPaths.includes(path);
};

export const getPathFromType = (type: string): string => {
    switch (type) {
        case "Activity": {
            return "activities";
        }
        case "ARSProvider": {
            return "ars-providers";
        }
        case "Board": {
            return "boards";
        }
        case "CoordinationGroup": {
            return "cg";
        }
        case "GHRProvider": {
            return "ghr-providers";
        }
        case "Group": {
            return "groups";
        }
        case "Member": {
            return "participants";
        }
        case "Organization": {
            return "organizations";
        }
        case "Post": {
            return "posts";
        }
        case "ProviderPost": {
            return "provider-bundles";
        }
        case "WorkingGroup": {
            return "working-groups";
        }
        default: {
            return type;
        }
    }
};

export const getReadableNameFromType = (type: string): string => {
    switch (type) {
        case "Activity": {
            return "Activity";
        }
        case "ARSProvider": {
            return "ARS Provider";
        }
        case "Board": {
            return "Board";
        }
        case "CoordinationGroup": {
            return "Coordination Group";
        }
        case "GHRProvider": {
            return "GHR Provider";
        }
        case "Group": {
            return "Group";
        }
        case "Member": {
            return "Participant";
        }
        case "Organization": {
            return "Organization";
        }
        case "Post": {
            return "Post";
        }
        case "ProviderPost": {
            return "Provider Post";
        }
        case "WorkingGroup": {
            return "Working Group";
        }
        default: {
            return "Post";
        }
    }
};

export interface PrimaryInfo {
    name: string;
    id?: string;
}

export type AcceptableTypesForTitleFromType = ((content: Activity)=>PrimaryInfo) | ((content: ARSProvider) =>PrimaryInfo)
| ((content: Board)=>PrimaryInfo) | ((content: CoordinationGroup) =>PrimaryInfo)
| ((content: GHRProvider)=>PrimaryInfo) | ((content: Group) =>PrimaryInfo)
| ((content: Member)=>PrimaryInfo) | ((content: Organization) =>PrimaryInfo)
| ((content: ProviderPost)=>PrimaryInfo) | ((content: WorkingGroup) =>PrimaryInfo)
| ((content: Post)=>PrimaryInfo);

export const getTitleFromType = (type: string): AcceptableTypesForTitleFromType => {
    switch (type) {
        case "Activity":
            return (content: Activity): PrimaryInfo => {
                return getActivityTitle(content);
            };
        case "ARSProvider":
            return (content: ARSProvider): PrimaryInfo => {
                return getARSProviderTitle(content);
            };
        case "Board":
            return (content: Board): PrimaryInfo => {
                return getBoardTitle(content);
            };
        case "CoordinationGroup":
            return (content: CoordinationGroup): PrimaryInfo => {
                return getCoordinationGroupTitle(content);
            };
        case "GHRProvider":
            return (content: GHRProvider): PrimaryInfo => {
                return getGHRProviderTitle(content);
            };
        case "Group":
            return (content: Group): PrimaryInfo => {
                return getGroupTitle(content);
            };
        case "Member":
            return (content: Member): PrimaryInfo => {
                return getMemberTitle(content);
            };
        case "Organization":
            return (content: Organization): PrimaryInfo => {
                return getOrganizationTitle(content);
            };
        case "ProviderPost":
            return (content: ProviderPost): PrimaryInfo => {
                return getProviderPostTitle(content);
            };
        case "WorkingGroup":
            return (content: WorkingGroup): PrimaryInfo => {
                return getWorkingGroupTitle(content);
            };
        default:
            return (content: Post): PrimaryInfo => {
                return getPostTitle(content);
            };
    }
};

export const getSortFields = (type: string): SortField[] => {
    const sortFields = [];
    switch (type) {
        case "Activity": {
            sortFields.push({
                name: "/heldDate",
                reverse: true
            });
            return sortFields;
        }
        case "ARSProvider": {
            sortFields.push({
                name: "/isDeactivated",
                reverse: false
            }, {
                name: "/memberSince",
                reverse: false
            });
            return sortFields;
        }
        case "Board": {
            sortFields.push({
                name: "/name",
                reverse: false
            });
            return sortFields;
        }
        case "CoordinationGroup": {
            sortFields.push({
                name: "/name",
                reverse: false
            });
            return sortFields;
        }
        case "GHRProvider": {
            sortFields.push({
                name: "/isDeactivated",
                reverse: false
            }, {
                name: "/memberSince",
                reverse: false
            });
            return sortFields;
        }
        case "Member": {
            sortFields.push({
                name: "/lastName",
                reverse: false
            });
            sortFields.push({
                name: "/firstName",
                reverse: false
            });
            return sortFields;
        }
        case "Organization": {
            sortFields.push({
                name: "/name",
                reverse: false
            });
            return sortFields;
        }
        case "menu": {
            sortFields.push({
                name: "/menu/menuName",
                reverse: false
            });
            sortFields.push({
                name: "/menu/subMenuName",
                reverse: false
            });
            sortFields.push({
                name: "/subject",
                reverse: false
            });
            return sortFields;
        }
        case "ProviderPost": {
            sortFields.push({
                name: "/modificationDate",
                reverse: true
            });
            return sortFields;
        }
        case "WorkingGroup": {
            sortFields.push({
                name: "/timeline/startDate",
                reverse: true
            });
            sortFields.push({
                name: "/status",
                reverse: false
            });
            return sortFields;
        }
        default: {
            sortFields.push({
                name: "/modificationDate",
                reverse: true
            });
            return sortFields;
        }
    }
};

export const handleError = (response: ErrorResponse): void => {
    if (response.status === 400) {
        let errorMessage = "You made an invalid request. ";
        errorMessage += response.message ? response.message : "Please check your request and provide all the necessary information.";
        toast.error(
            errorMessage
        );
    } else if (response.status === 401) {
        toast.error(
            "You provided invalid credentials. Please check your username and password."
        );
    } else {
        toast.error(
            "Unfortunately, something went wrong. If you need assistance, please send an email to contact@dona.net."
        );
    }
};


export const getReadableTypes = (types: Array<string>): Array<string> => {
    const readableTypes: Array<string> = [];
    types.map(type => {
        readableTypes.push(getReadableNameFromType(type));
        return getReadableNameFromType(type);
    });
    return readableTypes;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nest = (seq: any, keys: any[]): { [x: string]: any } => {
    if (!keys.length) return seq;
    const first = keys[0];
    const rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => {
        return nest(value, rest);
    });
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepFind = (obj: any, key: string): any[] => {
    if (_.has(obj, key)) {
        return [obj[key]];
    }
    return _.flatten(_.map(obj, (v) => {
        return typeof v == "object" ? deepFind(v, key) : [];
    }));
};
