import React, { Component, ChangeEvent, KeyboardEvent } from "react";

interface Props {
    name: string;
    value: string;
    label?: string;
    error?: string;
    required?: boolean;
    onBlur?: (currentTarget: { currentTarget: { name: string; value: string } }) => void;
    onChange?: ({ currentTarget }: { currentTarget: { name: string; value: string } }) => void;
    onKeyDown?: () => void;
    type?: string;
    placeholder?: string;
    enable?: boolean;
}

interface State {
    value: string;
}

class Input extends Component<Props, State> {

    state: State = {
        value: ""
    };

    componentDidMount = (): void => {
        const { value } = this.props;
        this.setState({ value });
    };

    componentDidUpdate = (prevProps: Props): void => {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.setState({ value });
        }
    };

    handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, onChange } = this.props;
        const { value } = event.target;
        this.setState({ value }, () => {
            const currentTarget = {
                name,
                value
            };
            onChange && onChange({ currentTarget });
        });
    };

    handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, onBlur } = this.props;
        if (onBlur) {
            const { value } = event.target;
            const currentTarget = {
                name,
                value
            };
            onBlur({ currentTarget });
        }
    };

    handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
        const { onKeyDown } = this.props;
        if (event.key === "Enter") {
            if (onKeyDown) {
                onKeyDown();
            }
        }
    };

    render = (): JSX.Element => {
        const {
            name,
            label,
            error,
            required,
            enable,
            onBlur,
            value,
            ...rest
        } = this.props;

        let disabled = false;
        if (enable === undefined) {
            disabled = false;
        } else if (enable === null) {
            disabled = false;
        } else if (enable === true) {
            disabled = false;
        } else {
            disabled = true;
        }

        const { value: valueFromState } = this.state;

        return (
            <React.Fragment>
                <div className="form-group">
                    {
                        label && (<label htmlFor={name} className="text-small no-padding">
                            {label}
                            {required && <span className="required">*</span>}
                        </label>)
                    }
                    {disabled && (
                        <input {...rest} name={name} value={valueFromState} className="form-control input-border-bottom" onBlur={this.handleBlur} onChange={this.handleChange} onKeyDown={this.handleKeyDown} autoComplete="on" disabled />
                    )}
                    {!disabled && (
                        <input {...rest} name={name} value={valueFromState} className="form-control input-border-bottom" onBlur={this.handleBlur} onChange={this.handleChange} onKeyDown={this.handleKeyDown} autoComplete="on" />
                    )}
                    {error && <div className="alert alert-danger">{error}</div>}
                </div>
            </React.Fragment>);
    };
}

export default Input;
