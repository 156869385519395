/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Dropzone from "react-dropzone";

import PayloadsList from "./";

const MAX_PAYLOAD_SIZE = 50 * 1024 * 1024;
const MAX_PAYLOADS_ALLOWED = 1;
const ACCEPTED_FORMATS = "application/octet-stream";

class Zone extends Component {
    state = {
        previouslyAcceptedPayloads: [],
        acceptedPayloads: [],
        deletedPayloads: []
    };

    componentDidMount() {
        const { value } = this.props;
        value && this.setState({ previouslyAcceptedPayloads: value });
    }

    readPayload = payload => {
        const { acceptedPayloads } = this.state;
        const reader = new FileReader();
        //TODO: Add mimetype
        reader.onload = () => {
            acceptedPayloads.push({
                name: payload.name,
                filename: payload.name,
                size: payload.size,
                lastModified: payload.lastModified,
                body: new Blob([ reader.result ])
            });
            this.setState({
                acceptedPayloads
            }, () => this.callSuppliedHandler());
        };
        reader.readAsArrayBuffer(payload);
    };

    onPayloadAddition = accepted => {
        const { name, maxPayloadsAllowed, payloadNamesAllowed, payloadTypesAllowed, handleChange } = this.props;
        const { acceptedPayloads } = this.state;
        const finalMaxPayloadsAllowed = maxPayloadsAllowed ?
            maxPayloadsAllowed :
            MAX_PAYLOADS_ALLOWED;
        let error = false;
        let errorMsg = "";
        if (!accepted || !accepted[0]) {
            error = true;
            errorMsg = "Payload exceeded the size limit.";
        } else {
            const payload = accepted[0];
            const payloadName = payload.name;
            const payloadNamePrefix = payloadName && payloadName.indexOf(".") > 0 ?
                payloadName.substring(0, payloadName.lastIndexOf(".")) :
                payloadName;
            const extension = payloadName && payloadName.indexOf(".") > 0 ?
                payloadName.substring(payloadName.lastIndexOf(".") + 1) :
                payloadName;
            //Check if payload limit has reached
            if (acceptedPayloads.length > finalMaxPayloadsAllowed) {
                error = true;
                errorMsg = "Payload names should be one of the acceptable names.";
            }
            if (!error && payloadTypesAllowed && payloadTypesAllowed.length > 0 && payloadTypesAllowed.indexOf(extension) < 0) {
                error = true;
                errorMsg = "Payload cannot be accepted.";
            }
            if (!error && payloadNamesAllowed && payloadNamesAllowed.length > 0 && payloadNamesAllowed.indexOf(payloadNamePrefix) < 0) {
                error = true;
                errorMsg = "Payload cannot be accepted.";
            }

            if (!error) {
                this.readPayload(payload);
            } else {
                handleChange({
                    currentTarget: {
                        name,
                        error: errorMsg
                    }
                });
            }
        }

    };

    onPayloadDeletion = name => {
        const { previouslyAcceptedPayloads, acceptedPayloads, deletedPayloads } = this.state;
        let filteredPreviouslyAcceptedPayloads = [ ...previouslyAcceptedPayloads ];
        let filteredAcceptedPayloads = [ ...acceptedPayloads ];

        let deleted;
        previouslyAcceptedPayloads.some(payload => {
            // eslint-disable-next-line no-return-assign
            return payload.name === name ?
                // eslint-disable-next-line no-sequences
                ((deleted = payload.name), true) :
                false;
        });

        if (deleted) {
            deletedPayloads.push(deleted);
            filteredPreviouslyAcceptedPayloads = previouslyAcceptedPayloads.filter(payload => payload.name !== name);
        } else {
            filteredAcceptedPayloads = acceptedPayloads.filter(payload => payload.name !== name);
        }
        this.setState({
            previouslyAcceptedPayloads: filteredPreviouslyAcceptedPayloads,
            acceptedPayloads: filteredAcceptedPayloads,
            deletedPayloads
        }, () => this.callSuppliedHandler());
    };

    callSuppliedHandler = () => {
        const { name, handleChange } = this.props;
        const { previouslyAcceptedPayloads, acceptedPayloads, deletedPayloads } = this.state;

        handleChange({
            currentTarget: {
                name,
                value: {
                    previouslyAcceptedPayloads,
                    acceptedPayloads,
                    deletedPayloads
                }
            }
        });
    };

    render() {
        const { acceptedFormats, maxPayloadsAllowed, maxPayloadSize } = this.props;
        const { acceptedPayloads, previouslyAcceptedPayloads } = this.state;

        const finalAcceptedFormats = acceptedFormats ?
            acceptedFormats :
            ACCEPTED_FORMATS;
        const finalMaxPayloadSize = maxPayloadSize ?
            maxPayloadSize :
            MAX_PAYLOAD_SIZE;
        const finalMaxPayloadsAllowed = maxPayloadsAllowed ?
            maxPayloadsAllowed :
            MAX_PAYLOADS_ALLOWED;

        const acceptedFormatAttribute = finalAcceptedFormats.length > 0 ?
            `accept=${finalAcceptedFormats}` :
            "";

        return (<div className="dropzone-container">
            <div className="dropzone">
                <Dropzone onDrop={this.onPayloadAddition} {...acceptedFormatAttribute} minSize={0} maxSize={finalMaxPayloadSize}>
                    {
                        ({ getRootProps, getInputProps, isDragActive, rejectedFiles }) => {
                            const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > finalMaxPayloadSize;
                            return (<div {...getRootProps()} className="dropzone base">
                                <input {...getInputProps()} /> {
                                    isDragActive
                                        ? (<p>Drop files here...</p>)
                                        : acceptedPayloads.length === finalMaxPayloadsAllowed
                                            ? (<p>Max payload limit reached.</p>)
                                            : (<p>
                                                Drag and drop files here, or click to select files to upload.
                                            </p>)}
                                {isFileTooLarge && (
                                    <p className="text-danger mt-2">
                                        File is too large.
                                    </p>
                                )}
                            </div>);
                        }
                    }
                </Dropzone>
            </div>
            <PayloadsList payloads={previouslyAcceptedPayloads.concat(acceptedPayloads)} isViewMode={false} deleteHandler={this.onPayloadDeletion} />
        </div>);
    }
}

export default Zone;
