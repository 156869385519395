import React, { ComponentType } from "react";
import queryString from "query-string";

// TODO: catch error if id is not in the router params
const extractTo = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractTo = (props: any): JSX.Element => {
        const queryValues = queryString.parse(props.location.search);
        const to = queryValues.to;
        const data = {
            content: {
                to: [ to ]
            }
        };
        return to && to.length > 0 ?
            (<WrappedComponent data={data} injected={true} {...props} />) :
            (<WrappedComponent {...props} />);
    };
    return ExtractTo;
};
export default extractTo;
