/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import TitleContainer from "../Title/Container";
import RenderContent from "../InlineIconText";
import ShowIfAuthorized from "../ShowIfAuthorized";
import { Member as MemberType } from "../../models/Member";

interface Props {
    data: {
        content: MemberType;
    };
}

const Member = (props: Props): JSX.Element => {
    const { data: { content: member } } = props;
    return (<div className="search-result row d-flex align-items-stretch border border-1">
        <div className="text col-lg-12">
            <div className="text-inner">
                <div>
                    <header className="header">
                        <div className="search-result-title">
                            <Link to={`/participants/${member.id}`} className="active">
                                <span className="h5">{constructName(member)}</span>
                            </Link>
                        </div>
                    </header>
                    <footer className="search-result-footer d-flex justify-content-start flex-wrap">
                        <div className="title">
                            {RenderContent("fa fa-envelope", member.emailAddress)}
                        </div>
                        <div className="title">
                            {
                                RenderContent("fa fa-building", <TitleContainer fetchProps={{
                                    id: member.organization
                                }} />)
                            }
                        </div>

                        <ShowIfAuthorized type="Member" action="fullView" data={member}>
                            <div className="title title-last">
                                <i className="fa fa-user" />
                                <span className="text pl-2 pr-2">
                                    {
                                        member.admin
                                            ? "Administrator"
                                            : (member.guest ? "Guest" : "Regular")
                                    }
                                </span>
                            </div>
                        </ShowIfAuthorized>
                    </footer>
                </div>
            </div>
        </div>
    </div>);
};

export const constructName = (member: MemberType): string => {
    return member && member ?
        `${member.suffix
            ? `${member.suffix}.`
            : ""} ${
            member.firstName
                ? member.firstName
                : ""} ${member.middleName
            ? member.middleName
            : ""} ${
            member.lastName
                ? member.lastName
                : ""}` :
        "Not available";
};

export default Member;
