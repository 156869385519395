/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import TitleContainer from "../Title/Container";
import ActivitiesContainer from "../Activity/QueryContainer";
import RichTextEditor from "../RichTextEditor/";
import { CoordinationGroup } from "../../models/CoordinationGroup";

interface Props {
    data: {
        content: CoordinationGroup;
    };
}

const CG = (props: Props): JSX.Element => {
    const { data: { content: cg } } = props;
    const chairs = cg.delegates && cg.delegates.filter(delegate => delegate.title === "Chair");
    const viceChairs = cg.delegates && cg.delegates.filter(delegate => delegate.title === "Vice Chair");
    const onlyDelegates = cg.delegates && cg.delegates.filter(delegate => delegate.title === "Delegate");
    return (<div className="detailed">
        <div className="details">
            <h1>{cg.name}</h1>
            <footer className="detailed-footer d-flex align-items-center">
                <div className="title pl-2 pr-2">
                    { chairs && chairs.length > 0
                        ? (<React.Fragment>
                            {
                                chairs.map((chair, index) => (<div className="title-last pr-2" key={index}>
                                    <i className="fa fa-address-book" />
                                    <span key={chair.member} className="text ml-2">
                                        <TitleContainer key={chair.member} fetchProps={{
                                            id: chair.member
                                        }} />
                                    </span>
                                </div>))
                            }
                        </React.Fragment>)
                        : ("")
                    }
                </div>
                { cg.delegates && (<div className={"title title-last pl-2 pr-2"}>
                    <i className="fa fa-user" />
                    <span className="text ml-2">
                        {
                            `${cg.delegates.length} ${
                                cg.delegates.length > 1
                                    ? "Delegates"
                                    : "Delegate"}`
                        }
                    </span>
                </div>)
                }
            </footer>
            <div className="border-bottom border-grey pb-3" />
            <div className="body mt-4 mb-4 pb-4">
                {cg.descriptionFormatted && <RichTextEditor value={cg.descriptionFormatted} readMode={true} key={cg.id} />}
                {!cg.descriptionFormatted && <p>{cg.descriptionFormatted}</p>}
            </div>
            <div className="mt-4 mb-4 pb-4">
                <h4 className="header mb-3">
                    Delegates<sup>*</sup>
                </h4>
                { chairs.map(chair => (<div className="media mt-2" key={chair.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title title-role">
                                <TitleContainer key={chair.member} fetchProps={{
                                    id: chair.member
                                }} />
                                <span className="title ml-2">({chair.title})</span>
                            </span>
                            <div className="role ml-2">
                                <TitleContainer key={chair.delegateOf} fetchProps={{
                                    id: chair.delegateOf
                                }} />
                            </div>
                        </div>
                    </div>
                </div>))
                }
                { viceChairs.map(viceChair => (<div className="media mt-2" key={viceChair.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title title-role">
                                <TitleContainer key={viceChair.member} fetchProps={{
                                    id: viceChair.member
                                }} />
                                <span className="title ml-2">({viceChair.title})</span>
                            </span>
                            <div className="role ml-2">
                                <TitleContainer key={viceChair.delegateOf} fetchProps={{
                                    id: viceChair.delegateOf
                                }} />
                            </div>
                        </div>
                    </div>
                </div>))
                }
                { onlyDelegates.map(delegate => (<div className="media mt-2" key={delegate.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title title-role">
                                <TitleContainer key={delegate.member} fetchProps={{
                                    id: delegate.member
                                }} />
                                <span className="title ml-2">({delegate.title})</span>
                            </span>
                            <div className="role ml-2">
                                <TitleContainer key={delegate.delegateOf} fetchProps={{
                                    id: delegate.delegateOf
                                }} />
                            </div>
                        </div>
                    </div>
                </div>))
                }
                <h6 className="header mb-3 mt-4 pt-4">
                    * Names of MPA Delegates received as of{" "}
                    {moment(new Date()).subtract(7, "days").format("MMMM Do, YYYY")}
                    {"."}
                </h6>
            </div>
            <ActivitiesContainer wg={{ id: cg.id }} />
        </div>
    </div>);
};

export default CG;
