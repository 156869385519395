/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import ShowIfAuthorized from "../ShowIfAuthorized";
import TitleContainer from "../Title/Container";
import { GHRProvider } from "../../models/GHRProvider";
import ProviderPostContainer from "../ProviderPost/QueryContainer";
import ARSProviderContainer from "../ARSProvider/QueryContainer";

interface Props {
    data: {
        content: GHRProvider;
    };
}

const Detailed = (props: Props): JSX.Element => {
    const { data: { content: ghr } } = props;
    const isDeactivated = ghr?.isDeactivated || false;
    return (<div className={`detailed ${isDeactivated ? "detailed-deactivated" : ""}`}>
        <div className="details">
            <h1>{ ghr.name } </h1>
            <footer className="detailed-footer d-flex align-items-center">
                <div className="title pl-2 pr-2">
                    <i className="fa fa-lock" />
                    <span className="text ml-2 lead font-weight-bold">
                        {ghr.credential}
                    </span>
                </div>
                <div className="title pl-2 pr-2">
                    <React.Fragment>
                        { ghr.organizations.map((organization, index) => (<div className="title-last pr-2" key={organization}>
                            <i className="fa fa-building" />
                            <span key={organization} className="text ml-2">
                                <TitleContainer key={organization} fetchProps={{
                                    id: organization
                                }} />
                            </span>
                        </div>))
                        }
                    </React.Fragment>
                </div>
                <div className={`title pl-2 pr-2 ${!isDeactivated ? "title-last" : ""}`}>
                    <i className="fa fa-calendar" />
                    <span className="text ml-2">
                        {moment(new Date(ghr.memberSince)).format("MMMM Do, YYYY")}
                    </span>
                </div>
                {isDeactivated && <div className="title title-last pl-2 pr-2">
                    <i className="fa fa-ban" />
                    <span className="text ml-2">
                        Inactive
                    </span>
                </div>}
            </footer>
            <div className="border-bottom border-grey pb-3" />
            <div className="body mt-4 mb-4 pb-4">
                <h4 className="header mb-3">Contacts</h4>
                {ghr.members && ghr.members.filter(member => member.title === "System Admin").map(sysAdmin => (<div className="media mt-1" key={sysAdmin.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title">
                                <TitleContainer key={sysAdmin.member} fetchProps={{
                                    id: sysAdmin.member
                                }} />
                                <span className="title ml-2">({sysAdmin.title})</span>
                            </span>
                        </div>
                    </div>
                </div>))
                }
                {ghr.members && ghr.members.filter(member => member.title !== "System Admin").map(staff => (<div className="media mt-1" key={staff.member}>
                    <div className="media-body">
                        <div className="main pull-left">
                            <span className="title">
                                <TitleContainer key={staff.member} fetchProps={{
                                    id: staff.member
                                }} />
                                <span className="title ml-2">({staff.title})</span>
                            </span>
                        </div>
                    </div>
                </div>))
                }
            </div>
            <ARSProviderContainer ghrProviderId={{ id: ghr.id }} />
            <ShowIfAuthorized type="ProviderPost" action="view" data={ghr}>
                <ProviderPostContainer ghrProviderId={{ id: ghr.id }} />
            </ShowIfAuthorized>
        </div>
    </div>);
};

export default Detailed;
