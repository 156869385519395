import { PrimaryInfo } from './../util';
import { Member } from "../../models/Member";

export const getSuggestion = (member: Member): PrimaryInfo => {
    return {
        name: constructName(member),
        id: member.id
    };
};

export const constructName = (member: Member): string => {
    return member && member ?
        `${member.firstName ? member.firstName : ""} ${
            member.lastName ? member.lastName : ""
        }` :
        "Not available";
};
