import { Activity } from './../models/Activity';
import { GHRProvider } from './../models/GHRProvider';
import { ProviderPost } from './../models/ProviderPost';
import { Post } from '../models/Post';
import { WorkingGroup } from '../models/WorkingGroup';
import { CoordinationGroup } from '../models/CoordinationGroup';
import { User } from './interfaces';

const rules = {
    admin: {
        static: [
            { type: "Post", actions: ["create", "fullEdit", "fullView"] },
            { type: "ProviderPost", actions: ["create", "view"] },
            { type: "WorkingGroup", actions: ["create", "edit"] },
            { type: "GHRProvider", actions: ["create", "edit"] },
            { type: "ARSProvider", actions: ["create", "edit", "view"] },
            { type: "Board", actions: ["create", "edit"] },
            { type: "CoordinationGroup", actions: ["create", "edit"] },
            { type: "Member", actions: ["create", "edit", "fullView"] },
            { type: "Organization", actions: ["create", "edit", "view"] },
            { type: "Group", actions: ["create", "edit", "view"] },
            { type: "Activity", actions: ["create", "edit", "view"] }
        ],
        dynamic: [
            {
                type: "Post",
                actions: ["edit"],
                condition: ({ user, data: post }: { user: User; data: Post }): boolean => {
                    if (post.from === user.id) {
                        return true;
                    } else if (post.editors && post.editors.length > 0 && post.editors.includes(user.id!)) {
                        return true;
                    }
                    return false;
                }
            },
            {
                type: "ProviderPost",
                actions: ["edit"],
                condition: ({ user, data: providerPost }: { user: User; data: ProviderPost }): boolean => {
                    if (providerPost.from === user.id) {
                        return true;
                    } else if (user.providers && user.providers.length > 0 && user.providers.includes(providerPost.context)) {
                        return true;
                    }
                    return false;
                }
            }
        ]
    },
    nonAdmin: {
        static: [{ type: "Post", actions: ["create"] }],
        dynamic: [
            {
                type: "Post",
                actions: ["edit"],
                condition: ({ user, data: post }: { user: User; data: Post }): boolean => {
                    if (post.from === user.id) {
                        return true;
                    } else if (post.editors && post.editors.length > 0 && post.editors.includes(user.id!)) {
                        return true;
                    }
                    return false;
                }
            },
            {
                type: "ProviderPost",
                actions: ["create", "view"],
                condition: ({ user, data: ghrProvider }: { user: User; data: GHRProvider }): boolean => {
                    return (
                        ghrProvider &&
                        ghrProvider.members &&
                        ghrProvider.members.map(member => member.member).includes(user.id!) || false
                    );
                }
            },
            {
                type: "ProviderPost",
                actions: ["edit"],
                condition: ({ user, data: providerPost }: { user: User; data: ProviderPost }): boolean => {
                    if (providerPost.from === user.id) {
                        return true;
                    } else if (user.providers && user.providers.length > 0 && user.providers.includes(providerPost.context)) {
                        return true;
                    }
                    return false;
                }
            },
            {
                type: "WorkingGroup",
                actions: ["edit"],
                condition: ({ user, data: wg }: { user: User; data: WorkingGroup }): boolean => {
                    return wg.members
                            .filter(member => member.title === "Chair")
                            .map(member => member.member)
                            .includes(user.id!);
                }
            },
            {
                type: "CoordinationGroup",
                actions: ["edit"],
                condition: ({ user, data: cg }: { user: User; data: CoordinationGroup }): boolean => {
                    return cg.delegates
                            .filter(delegate => delegate.title === "Chair")
                            .map(delegate => delegate.member)
                            .includes(user.id!);
                }
            },
            {
                type: "Activity",
                actions: ["create"],
                condition: ({ user, data: wg }: { user: User; data: WorkingGroup }): boolean => {
                    return (
                        wg &&
                        wg.members &&
                        wg.members
                        .filter(member => member.title === "Chair")
                        .map(member => member.member)
                        .includes(user.id!)
                    );
                }
            },
            {
                type: "Activity",
                actions: ["edit"],
                condition: ({ user, data: activity }: { user: User; data: Activity }): boolean => {
                    return activity.participants
                            .filter(member => member.title === "Organizer")
                            .map(member => member.member)
                            .includes(user.id!);
                }
            },
            {
                type: "Activity",
                actions: ["view"],
                condition: ({ user, data: wg }: { user: User; data: WorkingGroup }): boolean => {
                    return (
                        wg &&
                        wg.members &&
                        wg.members.map(member => member.member).includes(user.id!)
                    );
                }
            }
        ]
    }
};

export default rules;
