import { compose } from "redux";

import Title from ".";
import Default from "./Default";
import Error from "./Error";
import withContainer from "../common/hoc/WithContainer";

import { getObjectById } from "../../services/MODSService";

export default compose(
    withContainer({
        dataParams: {
            fetchHandler: getObjectById
        },
        defaultLoadingMessage: "Loading primary information",
        Error,
        DefaultComponent: Default
    })
)(Title);
