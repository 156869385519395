import React, { ComponentType } from "react";
import { FetchProps } from "../common/hoc/interfaces";
import { QueryParams } from "../interfaces";
import { getSortFields } from "../util";

const extractParams = <P extends object>(type: string) => (WrappedComponent: ComponentType<P & FetchProps>): ComponentType<P & FetchProps> => {
    const ExtractParams = (props: P & FetchProps) => {
        // eslint-disable-next-line react/prop-types
        const ghrId = (props.ghrProviderId && props.ghrProviderId.id) || (props.arsProviderId && props.arsProviderId.id);
        const params: QueryParams = {
            query: `type: ${type} AND /context:"${ghrId}"`,
            pageNum: -1,
            pageSize: -1,
            sortFields: getSortFields(type)
        };
        return (
            <React.Fragment>
                <WrappedComponent fetchProps={params} {...props} />
            </React.Fragment>
        );
    };
    return ExtractParams;
};
export default extractParams;
