import React, { Component } from "react";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "./components/NavBar";
import Routes from "./components/Routes";
import Footer from "./components/Footer";

class App extends Component {
    render() {
        return (
            <React.Fragment>
                <ToastContainer className="customToast" autoClose={5000} hideProgressBar={true} transition={Slide} />
                <NavBar />
                <div className="container" id="content">
                    <Routes />
                </div>
                <Footer />
            </React.Fragment>);
    }
}
export default App;
