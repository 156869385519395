/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import { WorkingGroup as WorkingGroupType } from "../../models/WorkingGroup";
import RenderContent from "../InlineIconText";
import TitleContainer from "../Title/Container";

interface Props {
    data: {
        content: WorkingGroupType;
    };
}

const WorkingGroup = (props: Props): JSX.Element => {
    const { data: { content: wg } } = props;
    return (<div className="search-result row d-flex align-items-stretch border border-1">
        <div className="text col-lg-12">
            <div className="text-inner">
                <div>
                    <header className="header">
                        <div className="search-result-title">
                            <h5>
                                <Link to={`/working-groups/${wg.id}`} className="active">
                                    {wg.name}
                                </Link>
                            </h5>
                        </div>
                    </header>
                    <p>{wg.objective}</p>
                    <footer className="search-result-footer d-flex justify-content-start flex-wrap">
                        <div className="title">
                            {
                                wg.members.filter(member => member.title === "Chair").map(chair => (<span key={chair.member}>
                                    <i className="fa fa-address-book" key={chair.member} />
                                    <span className="text ml-2 mr-2">
                                        <TitleContainer key={chair.member} fetchProps={{
                                            id: chair.member
                                        }} />
                                    </span>
                                </span>))
                            }
                        </div>
                        {
                            wg.members && (<div className="title">
                                {
                                    RenderContent(
                                        "fa fa-user", `${wg.members.length} ${wg.members.length > 1
                                            ? "Participants"
                                            : "Participant"}`)
                                }
                            </div>)
                        }
                        {
                            wg.status && (<div className="title title-last">
                                {
                                    RenderContent(
                                        "fa fa-comment", wg.status)
                                }
                            </div>)
                        }
                    </footer>
                </div>
            </div>
        </div>
    </div>);
};

export default WorkingGroup;
