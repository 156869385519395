/* eslint-disable react/prop-types */
import React, { ComponentType } from "react";
import queryString from "query-string";

// TODO: catch error if id is not in the router params
const extractContext = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractContext = (props: any): JSX.Element => {
        const queryValues = props.location && props.location.search && queryString.parse(props.location.search);
        const context = queryValues && queryValues.context;
        if (context) {
            let data = props && props.data;
        // eslint-disable-next-line no-empty
            if (data && data.content) {
                data.content.context = context;
            } else {
                data = {
                    content: {
                        context
                    }
                };
            }
            return <WrappedComponent data={data} injected={true} {...props} />;
        }
        return <WrappedComponent injected={false} {...props} />;
    };
    return ExtractContext;
};
export default extractContext;
