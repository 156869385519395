import React, { ComponentType } from "react";
import queryString from "query-string";


export interface PayloadParams {
    id: string;
    payload: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractParams = <P extends object>() => (WrappedComponent: ComponentType<P>):ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractParams = (props: any): JSX.Element => {

        // eslint-disable-next-line react/prop-types
        const { location: { search } } = props;
        const queryValues = search && queryString.parse(search);

        const id = queryValues && queryValues.id;
        const payload = queryValues && queryValues.payload;

        const params: PayloadParams = { id,
            payload };

        return <WrappedComponent fetchProps={params} {...props} />;
    };
    return ExtractParams;
};
export default extractParams;
