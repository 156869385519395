import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Joi from "@hapi/joi";
import Form from "./common/components/Form";
import { AuthContextInfo } from "./interfaces";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface LoginFormProps extends RouteComponentProps<{}, any, any> {
    authStore: AuthContextInfo;
}

interface State {
    loggedIn: boolean;
    verified: boolean;
    data: {
        username: string;
        password: string;
    };
    errors: {};
    touched: boolean;
    pathName?: string;
    search?: string;
}

class LoginForm<T extends {}> extends Form<T & LoginFormProps> {
    schema = {
        username: Joi.string()
            .required()
            .min(2)
            .label("Username"),
        password: Joi.string()
            .required()
            .min(2)
            .label("Password")
    };

    state: State = {
        loggedIn: false,
        verified: false,
        data: {
            username: "",
            password: ""
        },
        errors: {},
        touched: false
    };

    componentDidMount(): void {
        const { checkLogin } = this.props.authStore;
        if (checkLogin) {
            checkLogin().then((loggedIn: boolean) => {
                loggedIn ? this.setRedirection() : this.setState({ verified: true, loggedIn: false });
            }).catch(error => {
                this.setState({ verified: true, loggedIn: false });
            });
        }
    }

    setRedirection = (): void => {
        const { location } = this.props;
        if (location) {
            this.setState({
                loggedIn: true,
                verified: true,
                pathName: location.state && location.state.from && location.state.from.pathname ? location.state.from.pathname : "/",
                search: location.state && location.state.from && location.state.from.search ? location.state.from.search : ""
            });
        }
    };

    doSubmit = (): void => {
        const { username, password } = this.state.data;
        const { login } = this.props.authStore;
        if (login) {
            login(username, password)
            .then(() => {
                this.setRedirection();
            })
            .catch(() => {
                this.setState({ loggedIn: false });
            });
        }
    };

    resetHandler = (): void => {
        this.setState({ verified: true, loggedIn: true, pathName: "/reset-password", search: "" });
    };

    render = (): JSX.Element => {
        const { loggedIn, verified, pathName, search } = this.state;
        if (loggedIn) {
            return <Redirect to={{ pathname: pathName, search }} />;
        } else if (verified) {
            return (
                <div className="container p-5">
                    <form onSubmit={this.handleSubmit}>
                        <div className="card border border-primary">
                            <div className="card-header bg-primary text-white text-center">
                                <h2 className="bg-primary text-white text-center">
                                    <i className="fa fa-user-circle mt-1" />
                                </h2>
                                <h3 className="bg-primary text-white text-center py-4 mt-n3">
                                    Login
                                </h3>
                            </div>
                            <div className="card-body p-3">
                                {this.renderInput("username", "Email Address")}
                                {this.renderInput("password", "Password", "password")}
                                <div className="pt-2">
                                    {this.renderButton("Login", "fa fa-user")}
                                    <div className="pl-2 inline-block">
                                        <div className="small text-primary">Forgot Password?</div>
                                        {this.renderButtonWithClickHandler("Reset Password", "fa fa-refresh", this.resetHandler)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            );
        } else { return <React.Fragment></React.Fragment>; }
    };
}

export default LoginForm;
