import React, { ComponentType } from "react";

const extractQueryResults = <P extends object>() => (WrappedComponent: ComponentType<P>): ComponentType<P> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ExtractQueryResults = (props: any): JSX.Element => {
        return (<WrappedComponent listData={props.data.results} totalCount={props.data.size} pageNum={props.data.pageNum + 1} pageSize={props.data.pageSize} {...props} />);
    };
    return ExtractQueryResults;
};
export default extractQueryResults;
