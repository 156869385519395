import { compose } from "redux";

import Download from "./Download";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import extractQueryParams from "./ExtractQueryParams";

import { getObjectById } from "../../services/MODSService";

export default compose(
    extractQueryParams(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById
        },
        defaultLoadingMessage: "Downloading Payload",
        Error,
        DefaultComponent: Default
    })
)(Download);
