import React, { Component, ChangeEvent, KeyboardEvent } from "react";

interface Props {
    name: string;
    value: string;
    placeholder?: string;
    label?: string;
    error?: string;
    required?: boolean;
    onBlur?: (currentTarget: { currentTarget: { name: string; value: string } }) => void;
    onChange?: ({ currentTarget }: { currentTarget: { name: string; value: string } }) => void;
    onKeyDown?: () => void;
}

interface State {
    value: string;
}

class TextArea extends Component<Props, State> {

    state: State = {
        value: ""
    };

    componentDidMount = (): void => {
        const { value } = this.props;
        this.setState({ value });
    };

    componentDidUpdate = (prevProps: Props): void => {
        const { value } = this.props;
        if (prevProps.value !== value) {
            this.setState({ value });
        }
    };

    handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        const { name, onChange } = this.props;
        const { value } = event.target;
        this.setState({ value }, () => {
            const currentTarget = {
                name,
                value
            };
            onChange && onChange({ currentTarget });
        });
    };

    handleBlur = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        const { name, onBlur } = this.props;
        if (onBlur) {
            const { value } = event.target;
            const currentTarget = {
                name,
                value
            };
            onBlur({ currentTarget });
        }
    };

    keyPress = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
        const { onKeyDown } = this.props;
        if (event.keyCode === 13) {
            if (onKeyDown) {
                onKeyDown();
            }
        }
    };


    render = (): JSX.Element => {
        const {
            name,
            label,
            placeholder,
            error,
            required,
            onBlur,
            ...rest
        } = this.props;

        const { value } = this.state;

        return (<React.Fragment>
            <div className="form-group">
                {
                    label && (<label htmlFor={name}>
                        {label}
                        {required && <span className="required">*</span>}
                    </label>)
                }
                <textarea {...rest} name={name} value={value} placeholder={placeholder} className="form-control input-border-bottom" onBlur={this.handleBlur} onChange={this.handleChange} onKeyDown={this.keyPress} /> {error && <div className="alert alert-danger">{error}</div>}
            </div>
        </React.Fragment>);
    };
}

export default TextArea;
