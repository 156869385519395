import { compose } from "redux";

import NewPost from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";

import extractTo from "./ExtractTo";

import { createPost } from "../../services/MODSService";

export default compose(
    extractTo(),
    withContainer({
        dataParams: {
            saveHandler: createPost
        },
        Error,
        DefaultComponent: Default
    })
)(NewPost);
