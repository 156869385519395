import { compose } from "redux";

import { CordraObject } from "@cnri/cordra-client";
import NewMember from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import { createMember } from "../../services/MODSService";
import { DataFetchingState } from "../common/hoc/WithDataFetching";

export default compose(
    withContainer<DataFetchingState<CordraObject>, CordraObject>({
        dataParams: {
            saveHandler: createMember
        },
        Error,
        DefaultComponent: Default
    })
)(NewMember);
