import { ComponentType } from "react";
import Error from "../components/Error";
import branch from "./Branch";

export interface ErrorProps {
    hasError: boolean;
    error?: { title: string; message: string };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withErrorHandling = <P extends object>(ErrorComponent: ComponentType<ErrorProps> = Error) => (WrappedComponent: ComponentType<P & any>): ComponentType<P & ErrorProps> => {
    const WithErrorHandling = (props: P & ErrorProps) => {
        const injectedProps = {
            ...props
        };
        !injectedProps.error && props.hasError &&
            (injectedProps.error = {
                title: "Could not retrieve",
                message: "Could not retrieve."
            });
        return branch(injectedProps.hasError, ErrorComponent, WrappedComponent)(
            injectedProps
        );
    };

    return WithErrorHandling;
};

export default withErrorHandling;
