import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Payload } from "@cnri/cordra-client";
import TitleContainer from "../Title/Container";
import PayloadsList from "../Payloads";
import ShowIfAuthorized from "../ShowIfAuthorized";
import { ProviderPost as ProviderPostType } from "../../models/ProviderPost";

interface Props {
    data: {
        content: ProviderPostType;
        payloads: Array<Payload>;
    };
}

const ProviderPost = (props: Props): JSX.Element => {
    const { data: { content: providerPost, payloads } } = props;
    return (
        <div
            className="media border-bottom border-left border-right"
            key={providerPost.id}
        >
            <div className="media-body row pl-3">
                <div className="col-3 border-right">
                    <Link to={`/provider-bundles/${providerPost.id}`}>
                        {moment(new Date(providerPost.modificationDate)).format(
                            "MMMM Do, YYYY"
                        )}
                    </Link>
                </div>
                <div className="main col-3 border-right">
                    <span className="title override-link">
                        <TitleContainer
                            key={providerPost.lastModifiedBy}
                            fetchProps={{ id: providerPost.lastModifiedBy }}
                        />
                    </span>
                </div>
                <div className="main col-4 border-right">
                    {payloads && payloads.length > 0 ? (
                        <PayloadsList
                            isViewMode={true}
                            payloads={payloads}
                            id={providerPost.id}
                            isMinimal={true}
                        />
                    ) : (
                        <h6 className="title title-role ml-2">No payloads</h6>
                    )}
                </div>
                <div className="main col-2 mt-1 mb-1 text-center">
                    <ShowIfAuthorized
                        type="ProviderPost"
                        action="edit"
                        data={providerPost}
                    >
                        <Link
                            to={`/provider-bundles/edit/${providerPost.id}`}
                            className="btn btn-outline-primary btn-sm"
                        >
                            <i className="fa fa-pencil" />
                        </Link>
                    </ShowIfAuthorized>
                </div>
            </div>
        </div>
    );
};

export default ProviderPost;
